/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "../styles.scss";
import { ReactComponent as DVLAInfo } from "../../../assets/icons/dvla-info.svg";
import { Tooltip } from "antd";

const InvoiceCard = ({ labels, amount, toolTip }) => {
  return (
    <div className="w-full info-card bg-white px-5 py-6 flex flex-col gap-y-2">
      <p className="font-medium text-x-small flex items-center gap-x-2">
        {labels}{" "}
        <Tooltip title={toolTip} color={"#D7A81E"}>
          <DVLAInfo className="h-3 w-3 cursor-pointer" />
        </Tooltip>
      </p>
      <h3 className="font-bold text-md-headline">{amount}</h3>
    </div>
  );
};

export default InvoiceCard;
