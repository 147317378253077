/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../styles.scss";
import { Field, Formik } from "formik";
import FloatLabel from "../../../components/Inputs/FloatLabel";
import { DatePicker, Form, Input, Select, notification } from "antd";
import Button from "../../../components/Buttons/Button";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import FieldContainer from "../../../components/Inputs/FIeldContainer";
import MainLayout from "../../../layouts/MainLayout";
import { FetchBusinesses } from "../../../redux/actions/businesses.action";
import { localISOTime } from "../../../utils/constants";
import { CreateInvoice } from "../../../redux/actions/invoices.action";

const values = {
  name: "",
  invoiceNumber: "",
  status: "",
  dueByDate: "",
  business: "",
  amount: "",
  totalRides: "",
  currency: "",
  downloadUrl: "",
};

const NewInvoice = () => {
  const [form] = Form.useForm();
  const loader = useSelector((state) => state.users.loading);
  const businesses = useSelector((state) => state?.businesses?.businesses);
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();

  const createInvoice = (values, { resetForm }) => {
    const data = { ...values, createDate: localISOTime(new Date(Date.now())), dueByDate: localISOTime(new Date(values?.dueByDate)) }
    dispatch(CreateInvoice(data))
      .then(async (res) => {
        notification.success({
          message: "Success",
          description: "Invoice Created Successfully!",
        });
        resetForm();
        form.resetFields();
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: err.response.data.errorMsg
            ? err.response.data.errorMsg
            : "An error has ocurred, please try again later",
        });
      });
  };

  useEffect(() => {
    dispatch(FetchBusinesses({}));
  }, [dispatch]);

  useEffect(() => {
    if (businesses && businesses?.content && businesses?.content?.length) {
      const content = businesses?.content?.map((c) => {
        return { value: c?.id, label: c?.name };
      });
      setOptions(content);
    }
  }, [businesses]);

  return (
    <MainLayout title={"Invoices"}>
      <div>
        <div className="w-full info-card bg-white px-8 py-6 flex flex-col gap-y-2">
          <h3 className="text-secondary-300 font-semibold text-[18px] border-b pb-4 border-b-neutral-100 border-b-[0.5px]">
            Create Invoice
          </h3>
          <div className="w-full flex items-center justify-center">
            <div className="w-1/2 my-10">
              <Formik
                className="w-full"
                initialValues={values}
                onSubmit={createInvoice}
                enableReinitialize={true}
                validationSchema={validator}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form onFinish={handleSubmit} form={form}>
                    <div className={"mb-4"}>
                      <FieldContainer iconPlacementCss={"iconCss"}>
                        <FloatLabel
                          label="Invoice Name"
                          value={values?.name || ""}
                        >
                          <Form.Item
                            name={"name"}
                            help={
                              touched.name && errors.name
                                ? errors.name
                                : ""
                            }
                            validateStatus={
                              touched.name && errors.name
                                ? "error"
                                : "success"
                            }
                          >
                            <Input
                              type={"text"}
                              className={"form-field_input_2"}
                              value={values?.name || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </FieldContainer>
                    </div>
                    <div className={"mb-4"}>
                      <FieldContainer iconPlacementCss={"iconCss"}>
                        <FloatLabel
                          label="Invoice Number"
                          value={values?.invoiceNumber || ""}
                        >
                          <Form.Item
                            name={"invoiceNumber"}
                            help={
                              touched.invoiceNumber && errors.invoiceNumber
                                ? errors.invoiceNumber
                                : ""
                            }
                            validateStatus={
                              touched.invoiceNumber && errors.invoiceNumber
                                ? "error"
                                : "success"
                            }
                          >
                            <Input
                              type={"text"}
                              className={"form-field_input_2"}
                              value={values?.invoiceNumber || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </FieldContainer>
                    </div>
                    <div className={"mb-4"}>
                      <FieldContainer iconPlacementCss={"iconCss"}>
                        <FloatLabel
                          label="Status"
                          value={values?.status || ""}
                        >
                          <Form.Item
                            name={"status"}
                            help={
                              touched.status && errors.status
                                ? errors.status
                                : ""
                            }
                            validateStatus={
                              touched.status && errors.status
                                ? "error"
                                : "success"
                            }
                          >
                            <Field>
                              {({ form: { setFieldValue } }) => (
                                <Select
                                  value={values?.status || ""}
                                  onBlur={handleBlur}
                                  className={"form-field_input_2"}
                                  onChange={(e) => {
                                    setFieldValue("status", e);
                                  }}
                                  options={[
                                    { value: "PAID", label: "Paid" },
                                    { value: "PENDING", label: "Pending" },
                                    { value: "OVERDUE", label: "Overdue" }
                                  ]}
                                />
                              )}
                            </Field>
                          </Form.Item>
                        </FloatLabel>
                      </FieldContainer>
                    </div>
                    <div className={"mb-4"}>
                      <FieldContainer iconPlacementCss={"iconCss"}>
                        <FloatLabel
                          label="Due Date"
                          value={values?.dueByDate || ""}
                        >
                          <Form.Item
                            name={"dueByDate"}
                            help={
                              touched.dueByDate && errors.dueByDate
                                ? errors.dueByDate
                                : ""
                            }
                            validateStatus={
                              touched.dueByDate && errors.dueByDate
                                ? "error"
                                : "success"
                            }
                          >
                            <Field>
                              {({ form: { setFieldValue } }) => (
                                <DatePicker
                                  showTime
                                  className={"form-field_input_2"}
                                  placeholder={""}
                                  value={values?.dueByDate || ""}
                                  onChange={(e) => {
                                    setFieldValue("dueByDate", e);
                                  }}
                                  onOk={(e) => {
                                    setFieldValue("dueByDate", e)
                                  }}
                                  disabledDate={(d) =>
                                    !d ||
                                    d.isBefore(
                                      new Date(Date.now()).toISOString().split("T")[0]
                                    )
                                  }
                                />
                              )}
                            </Field>
                          </Form.Item>
                        </FloatLabel>
                      </FieldContainer>
                    </div>
                    <div className={"mb-4"}>
                      <FieldContainer iconPlacementCss={"iconCss"}>
                        <FloatLabel
                          label="Partner"
                          value={values?.business || ""}
                        >
                          <Form.Item
                            name={"business"}
                            help={
                              touched.business && errors.business
                                ? errors.business
                                : ""
                            }
                            validateStatus={
                              touched.business && errors.business
                                ? "error"
                                : "success"
                            }
                          >
                            <Field>
                              {({ form: { setFieldValue } }) => (
                                <Select
                                  value={values?.business || ""}
                                  onBlur={handleBlur}
                                  className={"form-field_input_2"}
                                  onChange={(e) => {
                                    setFieldValue("business", e);
                                  }}
                                  options={options}
                                />
                              )}
                            </Field>
                          </Form.Item>
                        </FloatLabel>
                      </FieldContainer>
                    </div>
                    <div className={"mb-4"}>
                      <FieldContainer iconPlacementCss={"iconCss"}>
                        <FloatLabel
                          label="Amount"
                          value={values?.amount || ""}
                        >
                          <Form.Item
                            name={"amount"}
                            help={
                              touched.amount && errors.amount
                                ? errors.amount
                                : ""
                            }
                            validateStatus={
                              touched.amount && errors.amount
                                ? "error"
                                : "success"
                            }
                          >
                            <Input
                              type={"number"}
                              className={"form-field_input_2"}
                              value={values?.amount || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </FieldContainer>
                    </div>
                    <div className={"mb-4"}>
                      <FieldContainer iconPlacementCss={"iconCss"}>
                        <FloatLabel
                          label="Total Rides"
                          value={values?.totalRides || ""}
                        >
                          <Form.Item
                            name={"totalRides"}
                            help={
                              touched.totalRides && errors.totalRides
                                ? errors.totalRides
                                : ""
                            }
                            validateStatus={
                              touched.totalRides && errors.totalRides
                                ? "error"
                                : "success"
                            }
                          >
                            <Input
                              type={"number"}
                              className={"form-field_input_2"}
                              value={values?.totalRides || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </FieldContainer>
                    </div>
                    <div className={"mb-4"}>
                      <FieldContainer iconPlacementCss={"iconCss"}>
                        <FloatLabel
                          label="Currency"
                          value={values?.currency || ""}
                        >
                          <Form.Item
                            name={"currency"}
                            help={
                              touched.currency && errors.currency
                                ? errors.currency
                                : ""
                            }
                            validateStatus={
                              touched.currency && errors.currency
                                ? "error"
                                : "success"
                            }
                          >
                            <Field>
                              {({ form: { setFieldValue } }) => (
                                <Select
                                  value={values?.currency || ""}
                                  onBlur={handleBlur}
                                  className={"form-field_input_2"}
                                  onChange={(e) => {
                                    setFieldValue("currency", e);
                                  }}
                                  options={[
                                    { value: "GHANA_CEDIS", label: "Ghana Cedis" },
                                  ]}
                                />
                              )}
                            </Field>
                          </Form.Item>
                        </FloatLabel>
                      </FieldContainer>
                    </div>
                    <div className={"mb-4"}>
                      <FieldContainer iconPlacementCss={"iconCss"}>
                        <FloatLabel
                          label="Download URL"
                          value={values?.downloadUrl || ""}
                        >
                          <Form.Item
                            name={"downloadUrl"}
                            help={
                              touched.downloadUrl && errors.downloadUrl
                                ? errors.downloadUrl
                                : ""
                            }
                            validateStatus={
                              touched.downloadUrl && errors.downloadUrl
                                ? "error"
                                : "success"
                            }
                          >
                            <Input
                              type={"text"}
                              className={"form-field_input_2"}
                              value={values?.downloadUrl || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Form.Item>
                        </FloatLabel>
                      </FieldContainer>
                    </div>
                    <div className={"flex justify-center"}>
                      <Button
                        css={"w-full"}
                        state={"PRIMARY"}
                        text={"Create"}
                        type={"submit"}
                        loading={loader}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

const validator = yup.object().shape({
  name: yup
    .string()
    .min(3, "Invoice name should be minimum of 3 characters")
    .required("Invoice name is required"),
  invoiceNumber: yup
    .string()
    .min(3, "Invoice number should be minimum of 3 characters")
    .required("Invoice number is required"),
  status: yup
    .string()
    .required("Status is required"),
  dueByDate: yup
    .string()
    .required("Due date is required"),
  business: yup
    .string()
    .required("Partner is required"),
  amount: yup
    .number()
    .min(1, "Invalid amount")
    .required("Amount is required"),
  totalRides: yup
    .number()
    .min(1, "Invalid input")
    .required("Total rides is required"),
  currency: yup
    .string()
    .required("Currency is required"),
  downloadUrl: yup
    .string()
    .min(10, "Download URL should be minimum of 10 characters")
    .required("Download URL is required"),
});

export { NewInvoice };
