/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "../styles.scss";
import { Field, Formik } from "formik";
import FloatLabel from "../../../components/Inputs/FloatLabel";
import { Form, Input, notification, Upload, Button as Btn } from "antd";
import Button from "../../../components/Buttons/Button";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import FieldContainer from "../../../components/Inputs/FIeldContainer";
import { UploadOutlined } from "@ant-design/icons";
import Download from "../../../assets/imgs/downloads.png";
import Sample from "./rides-batches.csv";
import { UploadRideBatch } from "../../../redux/actions/rides.action";

const { TextArea } = Input;

const values = {
    file: null,
    note: "",
};

const UploadNewRideBatch = () => {
    const [form] = Form.useForm();
    const loader = useSelector((state) => state.rides.loading);
    const dispatch = useDispatch();


    const uploadRideBatch = (values, { resetForm }) => {
        const formData = new FormData();
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        formData.append("csvfile", values?.file);

        dispatch(UploadRideBatch(formData, config, values?.note))
            .then(async (res) => {
                notification.success({
                    message: "Success",
                    description: "Ride batch uploaded successfully!",
                });
                resetForm();
                form.resetFields();
            })
            .catch((err) => {
                notification.error({
                    message: "Error",
                    description: err.response.data.errorMsg
                        ? err.response.data.errorMsg
                        : "An error has ocurred, please try again later",
                });
            });
    };

    return (
        <div className="w-full info-card bg-white px-8 py-6 flex flex-col gap-y-2">
            <div className={"flex flex-wrap w-full justify-between items-center border-b pb-4 border-b-neutral-100 border-b-[0.5px]"}>
                <h3 className="text-secondary-300 font-semibold text-[18px]">
                    Upload Batch
                </h3>
                <a
                    href={Sample}
                    download={"sample.csv"}
                    className={
                        "button_light gap-x-2 lg:gap-x-4 text-labels lg:text-sm-regular py-2 lg:text-labels xl:px-4 px-1 shadow-md md:mt-0 mt-4 md:w-auto w-full"
                    }
                >
                    <img src={Download} alt={"icon"} className={"h-2"} />
                    Download Sample
                </a>
            </div>
            <div className="w-full flex items-center justify-center">
                <div className="w-1/2 my-10">
                    <Formik
                        className="w-full"
                        initialValues={values}
                        onSubmit={uploadRideBatch}
                        enableReinitialize={true}
                        validationSchema={validator}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => (
                            <Form onFinish={handleSubmit} form={form}>
                                <div className={"mb-4 text-left"}>
                                    <div
                                        className={
                                            "text-left font-normal text-md-regular mb-2"
                                        }
                                    >
                                        <p>CSV File</p>
                                    </div>
                                    <FieldContainer>
                                        <Form.Item
                                            name={"file"}
                                            help={touched.file && errors.file ? errors.file : ""}
                                            validateStatus={
                                                touched.file && errors.file ? "error" : "success"
                                            }
                                            valuePropName={"file"}
                                        >
                                            <Field>
                                                {({ form: { setFieldValue } }) => (
                                                    <Upload
                                                        beforeUpload={(file) => {
                                                            setFieldValue("file", file)
                                                            return false;
                                                        }}
                                                        onChange={(e) => {
                                                            if (e?.fileList?.length === 0) {
                                                                setFieldValue("file", null);
                                                            }
                                                        }}
                                                        multiple={false}
                                                        listType={"picture"}
                                                        className={"w-full upload"}
                                                        maxCount={1}
                                                        accept={".csv"}
                                                    >
                                                        <Btn
                                                            className={
                                                                "w-full h-[3rem] flex items-center justify-center"
                                                            }
                                                            icon={<UploadOutlined />}
                                                        >
                                                            Browse
                                                        </Btn>
                                                    </Upload>
                                                )}
                                            </Field>
                                        </Form.Item>
                                    </FieldContainer>
                                </div>
                                <div className={"mb-4"}>
                                    <FieldContainer iconPlacementCss={"iconCss"}>
                                        <FloatLabel
                                            label="Note"
                                            value={values?.note || ""}
                                        >
                                            <Form.Item
                                                name={"note"}
                                                help={
                                                    touched.note && errors.note
                                                        ? errors.note
                                                        : ""
                                                }
                                                validateStatus={
                                                    touched.note && errors.note
                                                        ? "error"
                                                        : "success"
                                                }
                                            >
                                                <Field>
                                                    {({ form: { setFieldValue } }) => (
                                                        <TextArea
                                                            type={"text"}
                                                            className={"form-field_input_2"}
                                                            value={values?.note || ""}
                                                            onChange={(e) => {
                                                                setFieldValue("note", e.target.value);
                                                            }}
                                                            onBlur={(e) => {
                                                                setFieldValue("note", e.target.value);
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                            </Form.Item>
                                        </FloatLabel>
                                    </FieldContainer>
                                </div>
                                <div className={"flex justify-center"}>
                                    <Button
                                        css={"w-full"}
                                        state={"PRIMARY"}
                                        text={"Submit"}
                                        type={"submit"}
                                        loading={loader}
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

const validator = yup.object().shape({
    file: yup.mixed().required("CSV file is required"),
    note: yup
        .string()
        .min(6, "Note should be minimum of 6 characters")
        .required("Note is required")
});

export default UploadNewRideBatch;
