/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../styles.scss";
import ReactStars from "react-rating-stars-component";
import { dateFormat } from "../../../utils/constants";

const Profile = ({ data }) => {
  const [rating, setRating] = useState(0);

  useEffect(() => {
    if (data) {
      setRating(data?.rating ?? 0)
    }
  }, [data])

  return (
    <div className="w-full">
      <div className="w-1/2">
        <div className="w-full bg-dark-500 px-6 py-3 rounded-md flex justify-between text-labels mt-3">
          <p className="text-dark-600">Status</p>
          <p className={`${data?.verificationStatus ? "text-[#23BE65]" : "text-[#E53838]"} font-semibold`}>{data?.verificationStatus ?? "No Status"}</p>
        </div>
        <div className="w-full bg-dark-500 px-6 py-3 rounded-md flex justify-between text-labels mt-3">
          <p className="text-dark-600">Gender</p>
          <p className="text-dark-700 font-semibold">{data?.gender ?? "---"}</p>
        </div>
        <div className="w-full bg-dark-500 px-6 py-3 rounded-md flex justify-between text-labels mt-3">
          <p className="text-dark-600">Date of Birth</p>
          <p className="text-dark-700 font-semibold">{data?.dateOfBirth ? dateFormat(data?.dateOfBirth) : "---"}</p>
        </div>
        <div className="w-full bg-dark-500 px-6 py-3 rounded-md flex justify-between text-labels mt-3">
          <p className="text-dark-600">Phone Number</p>
          <p className="text-dark-700 font-semibold">{data?.phoneNumber ?? "---"}</p>
        </div>
        <div className="w-full bg-dark-500 px-6 py-3 rounded-md flex justify-between text-labels mt-3">
          <p className="text-dark-600">Marital Status</p>
          <p className="text-dark-700 font-semibold">{data?.maritalStatus ?? "---"}</p>
        </div>
        <div className="w-full bg-dark-500 px-6 py-3 rounded-md flex justify-between text-labels mt-3">
          <p className="text-dark-600">License Number</p>
          <p className="text-dark-700 font-semibold">{data?.licenseNumber ?? "---"}</p>
        </div>
        <div className="w-full bg-dark-500 px-6 py-3 rounded-md flex justify-between text-labels mt-3">
          <p className="text-dark-600">Residential Address</p>
          <p className="text-dark-700 font-semibold">{data?.residentialAddress ?? "---"}</p>
        </div>
        <div className="w-full bg-dark-500 px-6 py-3 rounded-md flex justify-between text-labels mt-3">
          <p className="text-dark-600">Region of Residence</p>
          <p className="text-dark-700 font-semibold">{data?.region ?? "---"}</p>
        </div>
        <div className="w-full bg-dark-500 px-6 py-3 rounded-md flex justify-between text-labels mt-3">
          <p className="text-dark-600">Registration Date</p>
          <p className="text-dark-700 font-semibold">{data?.registrationDate ? dateFormat(data?.registrationDate) : "---"}</p>
        </div>
        <div className="w-full bg-dark-500 px-6 py-3 rounded-md flex justify-between text-labels mt-3">
          <p className="text-dark-600">Rating</p>
          <ReactStars
            key={`stars_${rating}`}
            count={5}
            value={rating}
            size={16}
            edit={false}
            activeColor="#ffd700"
          />
        </div>
      </div>
    </div>
  );
};

export default Profile;
