/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MainLayout from "../../../layouts/MainLayout";
import "../styles.scss";
import InvoiceCard from "./InvoiceCard";
import InvoiceFilters from "./InvoiceFilter";
import InvoicesTable from "./InvoicesTable";
import { useDispatch, useSelector } from "react-redux";
import { FetchInvoiceStats, FetchInvoices, UpdateInvoiceStatus } from "../../../redux/actions/invoices.action";
import { debounce } from "lodash";
import { FetchBusinesses } from "../../../redux/actions/businesses.action";
import { numberWithCommas } from "../../../utils/constants";
import { notification } from "antd";

const ViewInvoices = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  const { invoice_stats } = useSelector((state) => state?.invoices);
  const [query, setQuery] = useState({ searchQuery: "" });
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" })
  const [company, setCompany] = useState();
  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 7,
  });

  const handleSearch = (value) => {
    performSearch(value);
  };

  const performSearch = debounce((value) => {
    if (value.length > 0) {
      setQuery({ searchQuery: value });
    } else {
      setQuery({ searchQuery: "" });
    }
  }, 700);

  useEffect(() => {
    dispatch(FetchInvoices({ ...paginationData, ...query, ...dateRange, company: company ? company : "", status }))
    dispatch(FetchInvoiceStats({ ...dateRange, company: company ? company : "", status }))
  }, [dispatch, paginationData, query, dateRange, company, status]);

  useEffect(() => {
    dispatch(FetchBusinesses({}));
  }, [dispatch]);

  const getInvoiceStat = (status) => {
    if (invoice_stats && invoice_stats?.length) {
      if (status !== "TOTAL") {
        const inv = invoice_stats?.find(stat => stat.status === status);
        return inv ? { ...inv } : { status, totalAmount: 0 };
      } else {
        const totalAmount = invoice_stats?.reduce((a, b) => a + b?.totalAmount, 0)
        return { status, totalAmount }
      }
    }
    return { status, totalAmount: 0 }
  }

  const updateInvoive = (id, statusToUpdate) => {
    dispatch(UpdateInvoiceStatus(id, statusToUpdate)).then(res => {
      if (res?.status === 200) {
        notification.success({
          message: "Success",
          description: "Invoice updated successfully!",
        });
        dispatch(FetchInvoices({ ...paginationData, ...query, ...dateRange, company: company ? company : "", status }))
        dispatch(FetchInvoiceStats({ ...dateRange, company: company ? company : "", status }))
      } else {
        notification.error({
          message: "Error",
          description: "Unable to update invoice",
        });
      }
    }).catch((err) => {
      notification.error({
        message: "Error",
        description:
          err?.response?.status === 403
            ? "Unable to update invoice"
            : "An error has ocurred, please try again later",
      });
    });
  }

  return (
    <MainLayout title={"Invoices"}>
      <div className="w-full flex justify-between">
        <div className="w-[23.5%]">
          <InvoiceCard
            labels={"Total Invoiced"}
            amount={`₦${numberWithCommas(getInvoiceStat("TOTAL")?.totalAmount)}`}
            toolTip={
              "Total amount of all invoices generated during the selected time period"
            }
          />
        </div>
        <div className="w-[23.5%]">
          <InvoiceCard
            labels={"Total Outstanding"}
            amount={`₦${numberWithCommas(getInvoiceStat("PENDING")?.totalAmount)}`}
            toolTip={
              "Total amount of all invoices generated during the selected time period"
            }
          />
        </div>
        <div className="w-[23.5%]">
          <InvoiceCard
            labels={"Total Collected"}
            amount={`₦${numberWithCommas(getInvoiceStat("PAID")?.totalAmount)}`}
            toolTip={
              "Total amount of all invoices generated during the selected time period"
            }
          />
        </div>
        <div className="w-[23.5%]">
          <InvoiceCard
            labels={"Total Overdue"}
            amount={`₦${numberWithCommas(getInvoiceStat("OVERDUE")?.totalAmount)}`}
            toolTip={
              "Total amount of all invoices generated during the selected time period"
            }
          />
        </div>
      </div>
      <div className="w-full mt-4">
        <InvoiceFilters
          handleSearch={handleSearch}
          setDateRange={setDateRange}
          getCompanyId={setCompany}
          handleStatusChange={setStatus}
        />
      </div>
      <InvoicesTable
        updateInvoive={updateInvoive}
        paginationData={paginationData}
        setPaginationData={setPaginationData}
      />
    </MainLayout>
  );
};

export { ViewInvoices };
