/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MainLayout from "../../../layouts/MainLayout";
import "../styles.scss";
import InfoCard from "./InfoCard";
import { useDispatch, useSelector } from "react-redux";
import { FetchAdminAllTimeRides } from "../../../redux/actions/stats.action";
import { numberWithCommas, thisYear } from "../../../utils/constants";
import RideSummary from "./RideSummary";
import { FetchRideSummary } from "../../../redux/actions/rides.action";
import { FetchBusinesses } from "../../../redux/actions/businesses.action";
import RidesByCompany from "./RidesByCompany";

const Analytics = () => {
  const user = JSON.parse(localStorage.getItem("dvla_user"));
  const all_time_rides = useSelector((state) => state?.stats?.all_time_rides);
  const [totalRides, setTotalRides] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [dateRange, setDateRange] = useState(thisYear())
  const [partnerId, setPartnerId] = useState(0);
  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 10,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchAdminAllTimeRides({}))
  }, [dispatch])

  useEffect(() => {
    if (all_time_rides) {
      setTotalRides(all_time_rides?.totalRides ?? 0)
      setTotalRevenue(all_time_rides?.totalFare ?? 0)
      // setTotalRides(all_time_rides?.reduce((a, b) => a + b?.total, 0))
      // setTotalRevenue(all_time_rides?.reduce((a, b) => a + b?.totalFare, 0))
    }
  }, [all_time_rides]);

  useEffect(() => {
    dispatch(FetchRideSummary({ ...paginationData, ...dateRange, partnerId }));
  }, [dispatch, paginationData, dateRange, partnerId]);

  useEffect(() => {
    user?.isSiteAdmin && dispatch(FetchBusinesses({}));
  }, [dispatch]);

  return (
    <MainLayout title={"Analytics"}>
      <div className="w-full flex justify-between">
        <div className="w-[63%]">
          <div className="w-full flex justify-between">
            <div className="w-[34.5%]">
              <InfoCard labels={"Total rides (All Time)"} amount={numberWithCommas(totalRides)} />
            </div>
            <div className="w-[62.5%]">
              <InfoCard
                labels={"Total Fare (All Time)"}
                amount={"NGN " + numberWithCommas(totalRevenue)}
              />
            </div>
          </div>
          <div className="mt-6">
            <RideSummary
              setDateRange={setDateRange}
              paginationData={paginationData}
              setPaginationData={setPaginationData}
              setPartnerId={setPartnerId}
            />
          </div>
        </div>
        <div className="w-[35%]">
          <div>
            <RidesByCompany />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Analytics;