/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Popover, Table } from "antd";
import React, { useState } from "react";
import { ReactComponent as DVLAMore } from "../../../assets/icons/dvla-more.svg";
import DVLAView from "../../../assets/icons/dvla-view.svg";
import "../styles.scss";
import { DOCUMENTS_COLUMNS } from "../../../utils/misc/drivers";
import DocumentsModal from "./DocumentsModal";
import DocumentStatusUpdate from "./DocumentStatusUpdate";
import Button from "../../../components/Buttons/Button";
import DVLAUpload from "../../../assets/imgs/dvla-upload.png";
import UploadDocument from "./UploadDocument";
import { dateFormat } from "../../../utils/constants";

const STATUS_PENDING = "px-2 py-1 bg-[#fef6ee] text-[#FA8C2A] rounded-md";
const STATUS_FAILED = "px-2 py-1 bg-[#fcefee] text-[#E53838] rounded-md";
const STATUS_VERIFIED = "px-2 py-1 bg-[#edfaf3] text-[#23BE65] rounded-md";

const Documents = ({ data, refresh, loading }) => {
  const [open, setOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [openInfo, setOpenInfo] = useState(false);
  const [openStatusUpdate, setOpenStatusUpdate] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [updateType, setUpdateType] = useState(false);
  const user = JSON.parse(localStorage.getItem("dvla_user"));

  const columns = [
    ...DOCUMENTS_COLUMNS,
    {
      title: "M",
      key: "operation",
      render: (record) => (
        <Popover
          content={content}
          trigger="click"
          open={open && currentRow === record?.key}
          placement="bottomLeft"
          onOpenChange={(e) => {
            handleOpenChange(e, record?.key);
          }}
        >
          <div className="cursor-pointer">
            <DVLAMore />
          </div>
        </Popover>
      ),
    },
  ];

  const handleMore = () => {
    handleOpenInfoChange(true);
    hide();
  };

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen, key) => {
    setOpen(newOpen);
    setCurrentRow(key);
  };

  const handleOpenInfoChange = (newOpen) => {
    setOpenInfo(newOpen);
  };

  const handleOpenDocumentUploadStateChange = (newOpen) => {
    setOpenUpload(newOpen);
  };

  const handleOpenStatusUpdateChange = (newOpen) => {
    setOpenStatusUpdate(newOpen);
  };

  const handleUpdate = (update) => {
    handleOpenInfoChange(false);
    setUpdateType(update);
    handleOpenStatusUpdateChange(true);
  };

  const handleUpdateAndRefresh = () => {
    handleOpenDocumentUploadStateChange(false);
    refresh()
  };

  const handleConfirm = () => {
    handleOpenStatusUpdateChange(false);
  };

  const uploadDocument = () => {
    setOpenUpload(true);
  }

  const table = data?.documents?.map((doc, index) => {
    return {
      key: index,
      documentName: <div className="underline font-medium">{doc?.documentName}</div>,
      documentNumber: doc?.documentNumber ?? "---",
      expiryDate: doc?.expiryDate ? dateFormat(doc?.expiryDate) : "---",
      status: (
        <span
          className={doc?.status === "PENDING" ? STATUS_PENDING : doc?.status === "FAILED" ? STATUS_FAILED : STATUS_VERIFIED}
        >
          {doc?.status}
        </span>
      ),
    };
  });

  const content = (
    <div>
      <div
        className="flex gap-x-4 items-center cursor-pointer text-labels"
        onClick={handleMore}
      >
        <img src={DVLAView} alt={"dvla-view"} className={"h-4"} />
        <div>View details</div>
      </div>
    </div>
  );

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <a href={"#/"} className={"px-2 font-medium text-sm-regular"}>
          {"Prev"}
        </a>
      );
    }
    if (type === "next") {
      return (
        <a href={"#/"} className={"px-2 font-medium text-sm-regular"}>
          {"Next"}
        </a>
      );
    }
    return originalElement;
  };

  const infoContent = (
    <DocumentsModal
      handleClose={() => handleOpenInfoChange(false)}
      handleUpdate={handleUpdate}
    />
  );

  const statusContent = (
    <DocumentStatusUpdate
      handleClose={() => handleOpenStatusUpdateChange(false)}
      updateType={updateType}
      handleConfirm={handleConfirm}
    />
  );

  const uploadDocumentContent = (
    <UploadDocument
      handleClose={() => handleOpenDocumentUploadStateChange(false)}
      handleUpdateAndRefresh={handleUpdateAndRefresh}
      id={data?.id}
    />
  );

  return (
    <div className="w-full bg-white">
      {user?.isSiteAdmin &&
        <div className="mb-4 flex justify-end">
          <Button
            css={
              "py-[9px] rounded-md lg:text-[10px] text-[10px] xl:px-[16px] px-2"
            }
            state={"PRIMARY"}
            text={"Upload Document"}
            type={"button"}
            icon={DVLAUpload}
            onClick={uploadDocument}
          />
        </div>}
      <Table
        loading={loading}
        columns={columns}
        dataSource={table}
        size={"small"}
        className={"custom-table"}
        pagination={{
          pageSize: 5,
          pageSizeOptions: ["5", "10", "20", "30", "40"],
          showSizeChanger: true,
          total: data?.documents?.length,
          showTotal: (total) => `Total ${total} items`,
          itemRender,
        }}
      />
      <Modal
        open={openInfo}
        closable={false}
        footer={null}
        onCancel={() => handleOpenInfoChange(false)}
        className={"invoice-modal"}
        width={550}
      >
        {infoContent}
      </Modal>

      <Modal
        open={openStatusUpdate}
        closable={false}
        footer={null}
        onCancel={() => handleOpenStatusUpdateChange(false)}
        className={"invoice-modal"}
        width={450}
      >
        {statusContent}
      </Modal>

      <Modal
        open={openUpload}
        closable={false}
        footer={null}
        onCancel={() => handleOpenDocumentUploadStateChange(false)}
        className={"invoice-modal"}
        width={550}
      >
        {uploadDocumentContent}
      </Modal>
    </div>
  );
};

export default Documents;
