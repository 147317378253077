import { Types } from "../actions/rides.action";

const INITIAL_STATE = {
  loading: false,
  rides: {},
  ride: {},
  monthlyTrends: {},
  dailyTrends: {},
  rideBatches: {},
  rideSummary: [],
};

export const ridesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.ALL_ADMIN_RIDES_START:
    case Types.BUSINESS_RIDES_START:
    case Types.RIDE_START:
    case Types.EXPORT_RIDE_START:
    case Types.BUSINESS_RIDES_MONTHLY_TRENDS_START:
    case Types.BUSINESS_RIDES_DAILY_TRENDS_START:
    case Types.UPLOAD_RIDE_BATCH_START:
    case Types.RIDE_BATCHES_START:
    case Types.RIDE_SUMMARY_START:
      return { ...state, loading: true };
    case Types.ALL_ADMIN_RIDES_SUCCESS:
    case Types.BUSINESS_RIDES_SUCCESS:
      return {
        ...state,
        loading: false,
        rides: action.rides,
      };
    case Types.RIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        ride: action.ride,
      };
    case Types.EXPORT_RIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        ride: action.ride,
      };
    case Types.BUSINESS_RIDES_MONTHLY_TRENDS_SUCCESS:
      return {
        ...state,
        loading: false,
        monthlyTrends: action.monthlyTrends,
      };
    case Types.BUSINESS_RIDES_DAILY_TRENDS_SUCCESS:
      return {
        ...state,
        loading: false,
        dailyTrends: action.dailyTrends,
      };
    case Types.UPLOAD_RIDE_BATCH_SUCCESS:
      return { ...state, loading: false };
    case Types.RIDE_BATCHES_SUCCESS:
      return {
        ...state,
        loading: false,
        rideBatches: action.rideBatches,
      };
    case Types.RIDE_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        rideSummary: action.rideSummary,
      };
    case Types.ALL_ADMIN_RIDES_END:
    case Types.BUSINESS_RIDES_END:
    case Types.RIDE_END:
    case Types.EXPORT_RIDE_END:
    case Types.BUSINESS_RIDES_MONTHLY_TRENDS_END:
    case Types.BUSINESS_RIDES_DAILY_TRENDS_END:
    case Types.UPLOAD_RIDE_BATCH_END:
    case Types.RIDE_BATCHES_END:
    case Types.RIDE_SUMMARY_END:
      return { ...state, loading: false, message: action.message };
    default:
      return state;
  }
};
