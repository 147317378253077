import React from "react";
import "./styles.scss";
import EyeBlock from "../../assets/imgs/eye-block.png";
import EyeSee from "../../assets/imgs/eye-see.png";

const PasswordField = ({
  icon,
  children,
  iconPlacementCss,
  showPassword = false,
  handleShowPassword,
}) => {
  return (
    <div className={`w-full relative ${icon ? "auth-input" : ""}`}>
      {icon ? (
        <img src={icon} alt={"Pre"} className={iconPlacementCss ?? ""} />
      ) : (
        <></>
      )}
      {children}
      {icon ? (
        <img
          src={showPassword ? EyeSee : EyeBlock}
          alt={"Post"}
          className={iconPlacementCss ?? ""}
          onClick={() => handleShowPassword(!showPassword)}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default PasswordField;
