import React, { useEffect, useState } from "react";
import { DATE_FILTERS } from "../../utils/misc/filters";
import { DateRange } from "react-date-range";
import "./styles.scss";
import { lastMonth, lastWeek, lastYear, localISOTime, thisMonth, thisWeek, thisYear, today, yesterday } from "../../utils/constants";

const DateFilter = ({
  activeDateFilter,
  setActiveDateFilter,
  setActiveDateFilterName,
  setDateRange,
}) => {
  const [dateFilter, setDateFilter] = useState(activeDateFilter);

  const handleDateFilter = (id) => {
    setDateFilter(id);
    if (id !== 10) {
      setActiveDateFilter(id);
      getDateRange(id);
    }
  };

  useEffect(() => {
    if (DATE_FILTERS && DATE_FILTERS?.length) {
      setActiveDateFilterName(
        DATE_FILTERS?.find((d) => d?.id === dateFilter)?.name
      );
    }
  }, [dateFilter, setActiveDateFilterName]);

  const [state, setState] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
      color: "#D7A81E",
    },
  ]);

  useEffect(() => {
    if (state[0].startDate !== null && state[0].endDate !== null)
      setDateRange({ startDate: localISOTime(state[0].startDate), endDate: localISOTime(state[0].endDate) })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const getDateRange = (id) => {
    switch (id) {
      case 1:
        setDateRange({ stateDate: "", endDate: "" })
        break;
      case 2:
        setDateRange(today())
        break;
      case 3:
        setDateRange(yesterday())
        break;
      case 4:
        setDateRange(thisWeek())
        break;
      case 5:
        setDateRange(lastWeek())
        break;
      case 6:
        setDateRange(thisMonth())
        break;
      case 7:
        setDateRange(lastMonth())
        break;
      case 8:
        setDateRange(thisYear())
        break;
      case 9:
        setDateRange(lastYear())
        break;
      default:
        return;
    }
  }

  return (
    <div className="flex">
      <div>
        {DATE_FILTERS?.map((d) => (
          <div
            key={d?.name}
            className="flex gap-x-4 items-center cursor-pointer py-2 text-labels"
            onClick={() => handleDateFilter(d?.id)}
          >
            <div
              className={
                d?.id === dateFilter
                  ? "bg-warning-500 px-3 py-1 rounded-md text-link-active font-semibold"
                  : "px-3 py-1"
              }
            >
              <div>{d?.name}</div>
            </div>
          </div>
        ))}
      </div>
      {dateFilter === 10 && (
        <div>
          <DateRange
            onChange={(item) => setState([item.selection])}
            showSelectionPreview={false}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            direction="horizontal"
          />
        </div>
      )}
    </div>
  );
};

export default DateFilter;
