export const CHART_MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const CHART_MONTHS_INITIAL_VALUES = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

export const CHART_DAYS = [
    "2024-01-01",
    "2024-01-01",
    "2024-01-01",
    "2024-01-01",
    "2024-01-01",
    "2024-01-01",
    "2024-01-01",
    "2024-01-01",
    "2024-01-01",
    "2024-01-01",
    "2024-01-01",
    "2024-01-01",
];

export const CHART_DAYS_INITIAL_VALUES = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];