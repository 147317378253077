/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, Modal, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { ReactComponent as DVLAChevronDown } from "../../../assets/icons/dvla-chevron-down.svg";
import ReactECharts from "echarts-for-react";
import "../styles.scss";
import { CHART_TYPES, RIDE_COUNTS, TREND_TYPES } from "../../../utils/misc/filters";
import DashboardDateFilter from "../../../components/DateFilter/DashboardDateFilter";
import { useSelector } from "react-redux";
import { CHART_DAYS, CHART_DAYS_INITIAL_VALUES, CHART_MONTHS, CHART_MONTHS_INITIAL_VALUES } from "../../../utils/misc/values";

const options = {
  grid: { top: 8, right: 8, bottom: 44 },
  xAxis: {
    type: "category",
    data: CHART_MONTHS,
  },
  yAxis: {
    type: "value",
  },
  series: [
    {
      data: CHART_MONTHS_INITIAL_VALUES,
      type: "bar",
      smooth: true,
      lineStyle: { color: "#D7A81E" },
      barWidth: "10%",
      itemStyle: {
        color: "#000000",
        borderRadius: [50, 50, 0, 0],
      },
      showSymbol: false,
    },
  ],
  tooltip: {
    trigger: "axis",
  },
};

const Trends = ({ setDateRange, setGlobalTrendType }) => {
  const [openChartType, setOpenChartType] = useState(false);
  const [openRideCount, setOpenRideCount] = useState(false);
  const [openTrendType, setOpenTrendType] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const [chartType, setChartType] = useState(2);
  const [chartTypeName, setChartTypeName] = useState("");

  const [rideCount, setRideCount] = useState(1);
  const [rideCountName, setRideCountName] = useState("");

  const [trendType, setTrendType] = useState(1);
  const [trendTypeName, setTrendTypeName] = useState("");

  const [activeDateFilter, setActiveDateFilter] = useState(7);
  const [activeDateFilterName, setActiveDateFilterName] = useState("This Year");

  const [chartOptions, setChartOptions] = useState(options);

  const monthlyTrends = useSelector((state) => state?.rides?.monthlyTrends);
  const dailyTrends = useSelector((state) => state?.rides?.dailyTrends);

  const hideChartType = () => {
    setOpenChartType(false);
  };

  const handleOpenChartTypeChange = (newOpen) => {
    setOpenChartType(newOpen);
  };

  const hideRideCount = () => {
    setOpenRideCount(false);
  };

  const handleOpenRideCountChange = (newOpen) => {
    setOpenRideCount(newOpen);
  };

  const hideTrendType = () => {
    setOpenTrendType(false);
  };

  const handleOpenTrendTypeChange = (newOpen) => {
    setOpenTrendType(newOpen);
  };

  const hideFilter = () => {
    setOpenFilter(false);
  };

  const handleOpenFilterChange = (newOpen) => {
    setOpenFilter(newOpen);
  };

  const handleChartType = (id) => {
    setChartType(id);
    hideChartType();
  };

  const handleRideCount = (id) => {
    setRideCount(id);
    hideRideCount();
  };

  const handleTrendType = (id) => {
    setTrendType(id);
    setGlobalTrendType(id);
    hideTrendType();
  };

  const handleFilter = (id) => {
    setActiveDateFilter(id);
    hideFilter();
  };

  useEffect(() => {
    if (CHART_TYPES && CHART_TYPES?.length) {
      setChartTypeName(CHART_TYPES?.find((c) => c?.id === chartType)?.name);
    }
  }, [CHART_TYPES, chartType]);

  useEffect(() => {
    if (RIDE_COUNTS && RIDE_COUNTS?.length) {
      setRideCountName(RIDE_COUNTS?.find((r) => r?.id === rideCount)?.name);
    }
  }, [RIDE_COUNTS, rideCount]);

  useEffect(() => {
    if (TREND_TYPES && TREND_TYPES?.length) {
      setTrendTypeName(TREND_TYPES?.find((r) => r?.id === trendType)?.name);
    }
  }, [TREND_TYPES, trendType]);

  useEffect(() => {
    setChartOptions({
      ...chartOptions,
      series: chartOptions.series?.map((s) => {
        return { ...s, type: chartType === 1 ? "line" : "bar" };
      }),
    });
  }, [chartType]);

  useEffect(() => {
    if (trendType === 1) {
      setChartOptions({
        ...chartOptions,
        xAxis: {
          ...chartOptions.xAxis, data: CHART_MONTHS
        },
        series: chartOptions.series?.map((s) => {
          return { ...s, data: CHART_MONTHS_INITIAL_VALUES };
        }),
      });
      if (monthlyTrends?.content?.length) {
        const mt = {};
        monthlyTrends?.content.forEach(m => {
          mt[m.month.toString()] = m;
        });
        let trends = CHART_MONTHS_INITIAL_VALUES
        trends = trends?.map((t, index) => {
          return mt[index + 1] ? mt[index + 1]["rideCount"] : 0
        });
        setChartOptions({
          ...chartOptions,
          series: chartOptions.series?.map((s) => {
            return { ...s, data: trends };
          }),
        });
      }
    } else {
      setChartOptions({
        ...chartOptions,
        xAxis: {
          ...chartOptions.xAxis, data: CHART_DAYS
        },
        series: chartOptions.series?.map((s) => {
          return { ...s, data: CHART_DAYS_INITIAL_VALUES };
        }),
      });
      if (dailyTrends?.content?.length) {
        const dtDays = [];
        const dtData = [];
        dailyTrends?.content.forEach(d => {
          dtDays.push(d.date);
          dtData.push(d.rideCount);
        });
        setChartOptions({
          ...chartOptions,
          xAxis: {
            ...chartOptions.xAxis, data: dtDays
          },
          series: chartOptions.series?.map((s) => {
            return { ...s, data: dtData };
          }),
        });
      }
    }
  }, [monthlyTrends, dailyTrends, trendType])

  const chartTypeContent = (
    <div className="w-32">
      {CHART_TYPES?.map((c) => (
        <div
          key={c?.name}
          className="flex gap-x-4 items-center cursor-pointer py-2"
          onClick={() => handleChartType(c?.id)}
        >
          <Checkbox checked={c?.id === chartType} className="form-checkbox">
            <div>{c?.name}</div>
          </Checkbox>
        </div>
      ))}
    </div>
  );

  const rideCountContent = (
    <div className="w-32">
      {RIDE_COUNTS?.map((r) => (
        <div
          key={r?.name}
          className="flex gap-x-4 items-center cursor-pointer py-2"
          onClick={() => handleRideCount(r?.id)}
        >
          <Checkbox checked={r?.id === rideCount} className="form-checkbox">
            <div>{r?.name}</div>
          </Checkbox>
        </div>
      ))}
    </div>
  );

  const trendTypeContent = (
    <div className="w-32">
      {TREND_TYPES?.map((t) => (
        <div
          key={t?.name}
          className="flex gap-x-4 items-center cursor-pointer py-2"
          onClick={() => handleTrendType(t?.id)}
        >
          <Checkbox checked={t?.id === trendType} className="form-checkbox">
            <div>{t?.name}</div>
          </Checkbox>
        </div>
      ))}
    </div>
  );

  const filterContent = (
    <DashboardDateFilter
      activeDateFilter={activeDateFilter}
      setActiveDateFilter={handleFilter}
      setActiveDateFilterName={setActiveDateFilterName}
      setDateRange={setDateRange}
    />
  );

  return (
    <div className="w-full bg-white trend-shadow rounded-lg text-sm-regular p-6 text-secondary-100 mt-10">
      <div className="w-full flex justify-between items-center border-b border-b-[1px] border-b-light pb-4">
        <p className="font-semibold text-sm-headline">
          {chartType === 1 ? "Trends" : "Analytics"}
        </p>
        <div className="flex items-center gap-x-3">
          <Popover
            content={chartTypeContent}
            trigger="click"
            open={openChartType}
            placement="bottomLeft"
            onOpenChange={handleOpenChartTypeChange}
          >
            <div className="flex items-center gap-x-3 text-labels cursor-pointer border-[1px] border-gray-300 p-2 rounded-[4px]">
              <span>{chartTypeName}</span>
              <DVLAChevronDown className="trend-icon" />
            </div>
          </Popover>
          <Popover
            content={rideCountContent}
            trigger="click"
            open={openRideCount}
            placement="bottomLeft"
            onOpenChange={handleOpenRideCountChange}
          >
            <div className="flex items-center gap-x-3 text-labels cursor-pointer border-[1px] border-gray-300 p-2 rounded-[4px]">
              <span>{rideCountName}</span>
              <DVLAChevronDown className="trend-icon" />
            </div>
          </Popover>
          <Popover
            content={trendTypeContent}
            trigger="click"
            open={openTrendType}
            placement="bottomLeft"
            onOpenChange={handleOpenTrendTypeChange}
          >
            <div className="flex items-center gap-x-3 text-labels cursor-pointer border-[1px] border-gray-300 p-2 rounded-[4px]">
              <span>{trendTypeName}</span>
              <DVLAChevronDown className="trend-icon" />
            </div>
          </Popover>
          <div
            onClick={handleOpenFilterChange}
            className={"bg-dark-100 flex items-center gap-x-3 text-labels cursor-pointer border-[1px] border-gray-300 p-2 rounded-[4px]"}
          >
            <span>{activeDateFilterName}</span>
            <DVLAChevronDown className="trend-icon" />
          </div>
          <Modal
            open={openFilter}
            closable={false}
            footer={null}
            onCancel={() => handleOpenFilterChange(false)}
            className={"filter-modal"}
          >
            {filterContent}
          </Modal>
        </div>
      </div>
      <div className="w-full text-center mt-4">
        <ReactECharts option={chartOptions} />
        <div className="w-full flex justify-center items-center">
          <div className="mt-4 flex items-center gap-x-2">
            <div className="h-2 w-2 bg-black rounded-full"></div>
            <span className="text-labels font-medium">{rideCountName}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trends;
