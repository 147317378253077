/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import "../styles.scss";
import { Table } from "antd";
import UsersFilters from "./UsersFilters";
import { useSelector } from "react-redux";
import { formatDate } from "../../../utils/constants";

const STATUS_PENDING = "px-2 py-1 bg-[#fef6ee] text-[#FA8C2A] rounded-md";
const STATUS_ACTIVE = "px-2 py-1 bg-[#edfaf3] text-[#23BE65] rounded-md";

const Users = ({
  getUser,
  paginationData,
  setPaginationData,
  handleSearch,
  setPartnerId,
}) => {
  const loader = useSelector((state) => state?.users?.loading);
  const users = useSelector((state) => state?.users?.users);
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(10);

  const columns = [
    {
      title: "USER ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "NAME",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "DATE CREATED",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
    },
  ];

  const onShowSizeChange = (current, pageSize) => {
    if (pageSize || current) {
      setDefaultPageSize(pageSize);
      setDefaultCurrent(current - 1);
    } else {
      setDefaultPageSize(10);
      setDefaultCurrent(1);
    }
  };

  const paginationChangeHandler = (current, pageSize) => {
    setPaginationData({
      ...paginationData,
      page: current - 1,
      size: pageSize,
    });
  };

  const dataSource =
    users &&
    users?.content?.length > 0 &&
    users?.content?.map((row) => {
      return {
        key: row.id,
        id: row?.id ?? "-",
        fullname: row?.fullname ?? "-",
        email: row?.email ?? "-",
        createdAt: row?.createdAt ? formatDate(row?.createdAt) : "-",
        status: (
          <span
            className={
              row?.status === "ACTIVE" ? STATUS_ACTIVE : STATUS_PENDING
            }
          >
            {row?.status?.toString()?.toUpperCase() ?? "-"}
          </span>
        ),
      };
    });

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <a href={"#/"} className={"px-2 font-medium text-sm-regular"}>
          {"< Prev"}
        </a>
      );
    }
    if (type === "next") {
      return (
        <a href={"#/"} className={"px-2 font-medium text-sm-regular"}>
          {"Next >"}
        </a>
      );
    }
    return originalElement;
  };

  const setProfile = (id) => {
    const user = users?.content?.find(user => user?.id === id)
    localStorage.setItem("dvla_profile", JSON.stringify(user))
    getUser(id);
  }

  return (
    <div className="w-full">
      <UsersFilters handleSearch={handleSearch} setPartnerId={setPartnerId} />
      <div className="w-full mt-10" style={{ minHeight: "20rem" }}>
        <Table
          loading={loader}
          columns={columns}
          dataSource={dataSource}
          size={"small"}
          className={"vcd-table"}
          onRow={(user) => {
            return {
              onClick: () => {
                setProfile(user?.id);
              },
            };
          }}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40"],
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            defaultCurrent: defaultCurrent,
            total: users?.totalElements,
            pageSize: defaultPageSize,
            showTotal: (total) => `Total ${total} items`,
            defaultPageSize: defaultPageSize,
            onChange: paginationChangeHandler,
            itemRender,
          }}
        />
      </div>
    </div>
  );
};

export default Users;
