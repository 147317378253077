import { Types } from "../actions/invoices.action";

const INITIAL_STATE = {
  loading: false,
  invoices: {},
  invoice: {},
};

export const invoicesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.ALL_INVOICES_START:
    case Types.INVOICE_START:
    case Types.INVOICE_STATS_START:
    case Types.CREATE_INVOICE_START:
    case Types.UPDATE_INVOICE_STATUS_START:
      return { ...state, loading: true };
    case Types.ALL_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        invoices: action.invoices,
      };
    case Types.INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        invoice: action.invoice,
      };
    case Types.INVOICE_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        invoice_stats: action.invoice_stats,
      };
    case Types.CREATE_INVOICE_SUCCESS:
    case Types.UPDATE_INVOICE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case Types.ALL_INVOICES_END:
    case Types.INVOICE_END:
    case Types.INVOICE_STATS_END:
    case Types.CREATE_INVOICE_END:
    case Types.UPDATE_INVOICE_STATUS_END:
      return { ...state, loading: false, message: action.message };
    default:
      return state;
  }
};
