/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DVLAPictureAvatar from "../../../assets/icons/dvla-picture-avatar.svg";
import { USER_TABS } from "../../../utils/misc/database";
import "../styles.scss";
import UserProfile from "./UserProfile";
import { useSelector } from "react-redux";

const UserDetails = ({ id }) => {
  const [activeTab, setActiveTab] = useState(1);
  const { user } = useSelector((state) => state?.users);
  const [tempUser, setTempUser] = useState({});

  useEffect(() => {
    if (id) {
      if (!user) {
        setTempUser(JSON.parse(localStorage.getItem("dvla_profile")))
      }
    }
  }, [id])

  return (
    <div className="w-full info-card bg-white px-8 py-6 flex flex-col gap-y-2">
      <div className="flex gap-x-4 items-center">
        <img src={DVLAPictureAvatar} alt={"dvla-user"} className={"h-16 w-16"} />
        <div className="flex flex-col gap-y-3">
          <h3 className="font-bold text-[18px]">{user ? user?.fullname ?? "---" : tempUser?.fullname ?? "---"}</h3>
          <p className="text-labels text-dark-400">{user ? user?.email ?? "---" : tempUser?.email ?? "---"}</p>
        </div>
      </div>
      <div className="mt-6 border-b border-b-neutral-100 border-b-[0.5px] w-full flex text-sm-regular gap-x-10">
        {USER_TABS?.map((dt) => (
          <div
            key={dt?.name}
            className={
              dt?.id === activeTab
                ? "border-b border-b-secondary-300 border-b-2 pb-3 cursor-pointer font-medium transition ease-in-out duration-300"
                : "cursor-pointer text-neutral-300 transition ease-in-out duration-300"
            }
            onClick={() => setActiveTab(dt?.id)}
          >
            {dt?.name}
          </div>
        ))}
      </div>
      <div className="w-full mt-3">
        {activeTab === 1 ? (
          <UserProfile data={user ?? tempUser} />
        ) : <></>}
      </div>
    </div>
  );
};

export default UserDetails;
