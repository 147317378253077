import React, { useEffect, useState } from "react";
import DVLALogout from "../../assets/imgs/dvla-logout.png";
import DVLAResetPassword from "../../assets/imgs/dvla-reset-password.png";
import DVLAUber from "../../assets/imgs/dvla-uber.png";
import DVLAinDrive from "../../assets/imgs/dvla-indrive.png";
import DVLAYango from "../../assets/imgs/dvla-yango.png";
import DVLABolt from "../../assets/imgs/dvla-bolt.png";
import { ReactComponent as DVLADot } from "../../assets/icons/dvla-dot.svg";
import "../styles.scss";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as DVLATablerAdjustments } from "../../assets/icons/dvla-tabler-adjustments.svg";
import { ReactComponent as DVLATablerX } from "../../assets/icons/dvla-tabler-x.svg";
import { ReactComponent as DVLANotification } from "../../assets/icons/dvla-notification.svg";
import { ReactComponent as DVLAPictureAvatar } from "../../assets/icons/dvla-picture-avatar.svg";
import { ReactComponent as DVLAChevronDown } from "../../assets/icons/dvla-chevron-down.svg";
import { ReactComponent as DVLAChevronUp } from "../../assets/icons/dvla-chevron-up.svg";
import { Popover, Collapse } from "antd";
import NameBadge from "../../pages/Auth/Login/NameBadge";
import { getTenantLogoText } from "../../utils/constants";
const { Panel } = Collapse;

const ACTIVE_NAV_CLASS =
  "w-full text-gray-300 cursor-pointer items-center mt-2";
const INACTIVE_NAV_CLASS =
  "w-full text-gray-400 hover:text-gray-300 cursor-pointer items-center mt-2";
const ACTIVE_ANCHOR_CLASS =
  "flex items-center focus:outline-none focus:ring-2 focus:ring-white menu is-active";
const ACTIVE_MULTI_MENU =
  "flex items-center focus:outline-none focus:ring-2 focus:ring-white menu is-active transparent-bg";
const ANCHOR_CLASS =
  "flex items-center focus:outline-none focus:ring-2 focus:ring-white menu";

const routes = [
  {
    id: 1,
    path: "/dashboard",
    name: "Dashboard",
  },
  {
    id: 2,
    path: "/partners",
    name: "Partners",
  },
  {
    id: 3,
    path: "/rides",
    name: "Rides",
  },
  {
    id: 10,
    path: "/ride-batches",
    name: "Bulk Uploads",
  },
  {
    id: 4,
    name: "Invoices",
    path: "/invoices",
    children: [
      {
        id: 1,
        path: "/invoices/view",
        name: "View invoices",
      },
      {
        id: 2,
        path: "/invoices/new",
        name: "Create Invoice",
      },
    ],
  },
  {
    id: 5,
    path: "/analytics",
    name: "Analytics",
  },
  {
    id: 6,
    path: "/dvla-database",
    name: "Drivers & Vehicles",
  },
  {
    id: 7,
    path: "/drivers",
    name: "Drivers",
  },
  {
    id: 8,
    path: "/cyclops",
    name: "Cyclops",
  },
  {
    id: 9,
    path: "/accounts",
    name: "Accounts",
  }
];

const MainLayout = ({ children, title }) => {
  const [open, setOpen] = useState(false);
  const [sideBar, setSideBar] = useState(null);
  const [openSidebar, setOpenSidebar] = useState(null);
  const [closeSidebar, setCloseSidebar] = useState(null);
  const [menu, setMenu] = useState(null);
  const [flag, setFlag] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  const sidebarHandler = (flag) => {
    setFlag(flag);
    if (flag) {
      sideBar.style.transform = "translateX(0px)";
      openSidebar.classList.add("hidden");
      closeSidebar.classList.remove("hidden");
      menu.classList.add("hidden");
    } else {
      sideBar.style.transform = "translateX(-260px)";
      closeSidebar.classList.add("hidden");
      openSidebar.classList.remove("hidden");
    }
  };

  // const handleMenu = () => {
  //   menu.classList.toggle("hidden");
  //   sidebarHandler(false);
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
    setSideBar(document.getElementById("mobile-nav"));
    setOpenSidebar(document.getElementById("openSideBar"));
    setCloseSidebar(document.getElementById("closeSideBar"));
    setMenu(document.querySelector(".mobile-menu"));
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("dvla_user"));
    setUser(user);
  }, []);

  useEffect(() => {
    if (sideBar !== null && !flag) {
      sideBar.style.transform = "translateX(-260px)";
    }
  }, [sideBar, flag]);

  const hide = () => {
    setOpen(false);
  };

  const logout = () => {
    hide();
    localStorage.removeItem("user_type");
    localStorage.removeItem("dvla_token");
    localStorage.removeItem("dvla_user");
    navigate("/");
  }

  const goTo = (state) => {
    switch (state) {
      case "CHANGE_PASSWORD":
        path !== "/dashboard/change-password" && navigate("/dashboard/change-password")
        hide();
        break;
      default:
        break;
    }
  }

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const useFindPath = () => {
    const location = useLocation();
    const [currentPath, setCurrentPath] = useState("");

    useEffect(() => {
      setCurrentPath(location.pathname);
    }, [location]);
    return currentPath;
  };

  const path = useFindPath();

  const content = (
    <div className="w-[12rem]">
      <div className="flex gap-x-4 items-center cursor-pointer pb-4" onClick={() => goTo("CHANGE_PASSWORD")}>
        <img src={DVLAResetPassword} alt={"dvla-logout"} className={"h-4"} />
        <div className="text-labels">Change Password</div>
      </div>
      <div className="flex gap-x-4 items-center cursor-pointer" onClick={logout}>
        <img src={DVLALogout} alt={"dvla-logout"} className={"h-4"} />
        <div className="text-labels">Logout</div>
      </div>
    </div>
  );

  return (
    <div className="overflow-hidden relative">
      <nav className="bg-white border-b border-secondary-400 fixed w-full z-10">
        <div className="mx-auto px-6">
          <div className="flex justify-between">
            <div className="flex space-x-20">
              <div>
                <a href="#/" className="flex items-center py-4 px-2">
                  <img
                    src={getTenantLogoText()}
                    alt={"dvla-logo-text"}
                    className="h-10 mr-2"
                  />
                </a>
              </div>

              <div className="hidden md:flex items-center">
                <div className="px-2 text-secondary-300 font-semibold">
                  {title}
                </div>
              </div>
            </div>
            <div className="hidden md:flex items-center space-x-3 ">
              <a
                href="#/"
                className="p-1 rounded-full hover:bg-primary-500 transition duration-300"
              >
                <DVLANotification />
              </a>
              {user?.isSiteAdmin ? (
                <DVLAPictureAvatar />
              ) : (
                user?.business?.name?.toLowerCase() === "idrive"
                  ? <img src={DVLAinDrive} alt={user?.business?.name} className={"h-8"} /> : user?.business?.name?.toLowerCase() === "yango"
                    ? <img src={DVLAYango} alt={user?.business?.name} className={"h-8"} /> : user?.business?.name?.toLowerCase() === "uber"
                      ? <img src={DVLAUber} alt={user?.business?.name} className={"h-8"} /> : user?.business?.name?.toLowerCase() === "bolt rides"
                        ? <img src={DVLABolt} alt={user?.business?.name} className={"h-8"} />
                        : <NameBadge name={user?.business?.name} />
              )}
              <Popover
                content={content}
                trigger="click"
                open={open}
                placement="bottomLeft"
                onOpenChange={handleOpenChange}
              >
                <div className="flex items-center gap-x-4 text-sm-regular cursor-pointer">
                  <span>
                    {user?.fullname}
                  </span>
                  <DVLAChevronDown />
                </div>
              </Popover>
            </div>
            {/* <div className="md:hidden flex items-center">
              <button
                onClick={handleMenu}
                className="outline-none mobile-menu-button"
              >
                <svg
                  className=" w-6 h-6 text-gray-500 hover:text-green-500 "
                  x-show="!showMenu"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
              </button>
            </div> */}
          </div>
        </div>
        {/* <div className="hidden mobile-menu">
          <ul className="">
            <li className="active">
              <a
                href="index.html"
                className="block text-sm px-2 py-4 text-white bg-green-500 font-semibold"
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="#services"
                className="block text-sm px-2 py-4 hover:bg-green-500 transition duration-300"
              >
                Services
              </a>
            </li>
          </ul>
        </div> */}
      </nav>

      <div className="w-full main-nav">
        <div className={"h-full"}>
          <div className={"flex flex-no-wrap h-full bg-main"}>
            <div
              style={{ minHeight: "716px" }}
              className="w-64 absolute sm:relative bg-white border-r border-secondary-400 md:h-full flex-col justify-between hidden sm:flex"
            >
              <div className="px-4">
                <ul className={"mt-28 accordion invoice-menu"}>
                  {(user?.isSiteAdmin
                    ? routes?.filter((r) => r?.id !== 7 && r?.id !== 8)
                    : routes?.filter(
                      (r) => r?.id !== 2 && r?.id !== 5 && r?.id !== 6
                    )
                  )?.map((route) => (
                    <li
                      className={
                        path.includes(route.path)
                          ? ACTIVE_NAV_CLASS
                          : INACTIVE_NAV_CLASS
                      }
                      key={route?.id + "full screen"}
                    >
                      {route?.children ? (
                        <Collapse
                          defaultActiveKey={
                            path?.includes(route.path) || activeSubMenu
                              ? ["0"]
                              : []
                          }
                          accordion
                          bordered={false}
                          expandIconPosition={"end"}
                          expandIcon={({ isActive }) =>
                            isActive ? <DVLAChevronUp /> : <DVLAChevronDown />
                          }
                        >
                          <Panel
                            header={
                              <div
                                className={
                                  path?.includes(route.path)
                                    ? ACTIVE_MULTI_MENU
                                    : ANCHOR_CLASS
                                }
                              >
                                <DVLADot />
                                <span className={"text-labels ml-2"}>
                                  {route.name}
                                </span>
                              </div>
                            }
                          >
                            {(!user?.isSiteAdmin ? route?.children?.filter(c => c.id !== 2)
                              :
                              route?.children
                            ).map((im, index) => (
                              <NavLink
                                to={im.path}
                                key={im?.name}
                                onClick={() => setActiveSubMenu(im?.id)}
                              >
                                <div
                                  className={
                                    path.includes(im.path)
                                      ? "rounded-xl bg-dark-200 text-left px-4 py-4 w-full mb-2"
                                      : "rounded-xl text-left px-4 py-4 w-full mb-2"
                                  }
                                >
                                  <div className="w-1/2">
                                    <p className="text-labels">{im?.name}</p>
                                  </div>
                                </div>
                              </NavLink>
                            ))}
                          </Panel>
                        </Collapse>
                      ) : (
                        <NavLink
                          to={route.path}
                          className={
                            path.includes(route.path)
                              ? ACTIVE_ANCHOR_CLASS
                              : ANCHOR_CLASS
                          }
                          onClick={() => setActiveSubMenu(null)}
                        >
                          <DVLADot />
                          <span className={"text-labels ml-2"}>
                            {route.name}
                          </span>
                        </NavLink>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div
              className="w-64 z-40 absolute bg-white border-r border-secondary-400 md:h-full flex-col justify-between sm:hidden transition duration-150 ease-in-out"
              id="mobile-nav"
            >
              <button
                className={
                  "h-10 w-10 bg-gray-600 absolute right-0 mt-16 -mr-10 flex items-center shadow rounded-tr rounded-br justify-center cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 rounded focus:ring-gray-800"
                }
                onClick={() => sidebarHandler(true)}
                id="openSideBar"
              >
                <DVLATablerAdjustments />
              </button>
              <button
                className={
                  "h-10 w-10 bg-gray-600 absolute right-0 mt-16 -mr-10 flex items-center shadow rounded-tr rounded-br justify-center cursor-pointer text-white"
                }
                onClick={() => sidebarHandler(false)}
                id="closeSideBar"
              >
                <DVLATablerX />
              </button>
              <div className="px-4">
                <ul className={"mt-8 accordion"}>
                  {(user?.isSiteAdmin
                    ? routes?.filter((r) => r?.id !== 7 && r?.id !== 8)
                    : routes?.filter(
                      (r) => r?.id !== 2 && r?.id !== 5 && r?.id !== 6
                    )
                  )?.map((route) => (
                    <li
                      className={
                        path.includes(route.path)
                          ? ACTIVE_NAV_CLASS
                          : INACTIVE_NAV_CLASS
                      }
                      key={route?.id + "full screen"}
                    >
                      {route?.children ? (
                        <Collapse
                          defaultActiveKey={
                            path?.includes(route.path) || activeSubMenu
                              ? ["0"]
                              : []
                          }
                          accordion
                          bordered={false}
                          expandIconPosition={"end"}
                          expandIcon={({ isActive }) =>
                            isActive ? <DVLAChevronUp /> : <DVLAChevronDown />
                          }
                        >
                          <Panel
                            header={
                              <div
                                className={
                                  path?.includes(route.path)
                                    ? ACTIVE_MULTI_MENU
                                    : ANCHOR_CLASS
                                }
                              >
                                <DVLADot />
                                <span className={"text-labels ml-2"}>
                                  {route.name}
                                </span>
                              </div>
                            }
                          >
                            {(!user?.isSiteAdmin ? route?.children?.filter(c => c.id !== 2)
                              :
                              route?.children
                            ).map((im, index) => (
                              <NavLink
                                to={im.path}
                                key={im?.name}
                                onClick={() => setActiveSubMenu(im?.id)}
                              >
                                <div
                                  className={
                                    path.includes(im.path)
                                      ? "rounded-xl bg-dark-200 text-left px-4 py-4 w-full mb-2"
                                      : "rounded-xl text-left px-4 py-4 w-full mb-2"
                                  }
                                >
                                  <div className="w-1/2">
                                    <p className="text-labels">{im?.name}</p>
                                  </div>
                                </div>
                              </NavLink>
                            ))}
                          </Panel>
                        </Collapse>
                      ) : (
                        <NavLink
                          to={route.path}
                          className={
                            path.includes(route.path)
                              ? ACTIVE_ANCHOR_CLASS
                              : ANCHOR_CLASS
                          }
                          onClick={() => setActiveSubMenu(null)}
                        >
                          <DVLADot />
                          <span className={"text-labels ml-2"}>
                            {route.name}
                          </span>
                        </NavLink>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div
              className={
                "container mx-auto py-10 md:w-4/5 w-11/12 px-6 main-content"
              }
            >
              <div className={"w-full mt-16"}>{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
