/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MainLayout from "../../../layouts/MainLayout";
import "../styles.scss";
import { useDispatch } from "react-redux";
import Manage from "./Manage";
import RideBatchUpload from "./RideBatchUpload";
import { debounce } from "lodash";
import { FetchBusinesses } from "../../../redux/actions/businesses.action";
import { FetchRideBatches } from "../../../redux/actions/rides.action";

const RideBatches = () => {
    const user = JSON.parse(localStorage.getItem("dvla_user"));
    const dispatch = useDispatch();
    const [newBatch, setNewBatch] = useState(false);
    const [query, setQuery] = useState({ name: "" });
    const [partnerId, setPartnerId] = useState(0);
    const [paginationData, setPaginationData] = useState({
        page: 0,
        size: 10,
    });

    const uploadNewRideBatch = () => {
        setNewBatch(!newBatch);
    };

    useEffect(() => {
        dispatch(FetchRideBatches({ ...paginationData, ...query, partnerId }))
    }, [dispatch, paginationData, query, partnerId]);

    useEffect(() => {
        user?.isSiteAdmin && dispatch(FetchBusinesses({}));
    }, [dispatch]);

    const handleSearch = (value) => {
        performSearch(value);
    };

    const performSearch = debounce((value) => {
        if (value.length > 0) {
            setQuery({ name: value });
        } else {
            setQuery({ name: "" });
        }
    }, 700);

    const reset = () => {
        setNewBatch(false);
        setQuery({ name: "" })
        setPartnerId(0)
        setPaginationData({
            page: 0,
            size: 10,
        })
    }

    return (
        <MainLayout title={"Bulk Uploads"}>
            {newBatch ? (
                <RideBatchUpload
                    newBatch={newBatch}
                    goBack={() => {
                        reset()
                    }}
                />
            ) : (
                <Manage
                    uploadNewRideBatch={() => uploadNewRideBatch()}
                    handleSearch={handleSearch}
                    paginationData={paginationData}
                    setPaginationData={setPaginationData}
                    setPartnerId={setPartnerId}
                />
            )}
        </MainLayout>
    );
};

export default RideBatches;
