import React from "react";
import { ReactComponent as DVLAClose } from "../../../assets/icons/dvla-close.svg";

const STATUS_PENDING = "px-2 py-1 bg-[#fef6ee] text-[#FA8C2A] rounded-md";
const STATUS_ACTIVE = "px-2 py-1 bg-[#edfaf3] text-[#23BE65] rounded-md";

const CompanyDetails = ({ handleClose, business }) => {
  return (
    <div className="w-full">
      <div className="flex justify-end">
        <div
          className="p-1 flex items-center justify-center rounded-full dvla-close cursor-pointer"
          onClick={handleClose}
        >
          <DVLAClose />
        </div>
      </div>
      <div className="flex gap-x-4 items-center cursor-pointer py-2 text-sm-regular font-semibold">
        Company Details
      </div>
      <div className="text-labels border rounded-lg border-[1px] border-gray-300">
        <div className="flex justify-between px-3 border-b border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Company Name</div>
          <div className="text-secondary-100 font-medium">{business?.name}</div>
        </div>
        <div className="flex justify-between px-3 border-b border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Address</div>
          <div className="text-secondary-100 font-medium">{business?.address}</div>
        </div>
        <div className="flex justify-between px-3 border-b border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Corporate Email</div>
          <div className="text-secondary-100 font-medium">{business?.email}</div>
        </div>
        <div className="flex justify-between px-3 border-b border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Date Created</div>
          <div className="text-secondary-100 font-medium">{business?.dateCreated}</div>
        </div>
        <div className="flex justify-between px-3 border-b border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">License Number</div>
          <div className="text-secondary-100 font-medium">{business?.licenseNumber}</div>
        </div>
        <div className="flex justify-between px-3 border-b border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Registration Date</div>
          <div className="text-secondary-100 font-medium">{business?.registrationDate}</div>
        </div>
        <div className="flex justify-between px-3 border-b border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Verification Status</div>
          <div className="text-secondary-100 font-medium"><span
            className={
              business?.status === "VERIFIED" ? STATUS_ACTIVE : STATUS_PENDING
            }
          >
            {business?.status?.toString()?.toUpperCase() ?? "-"}
          </span></div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
