/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Popover, Table } from "antd";
import React, { useState } from "react";
import { ReactComponent as DVLAMore } from "../../../assets/icons/dvla-more.svg";
import DVLAView from "../../../assets/icons/dvla-view.svg";
import "../styles.scss";
import { FLAGS_COLUMNS } from "../../../utils/misc/drivers";
import Button from "../../../components/Buttons/Button";
import DVLAAdd from "../../../assets/icons/dvla-plus.svg";
import DVLAEdit from "../../../assets/imgs/dvla-edit.png";
import DVLABin from "../../../assets/imgs/dvla-bin.png";
import { dateFormat } from "../../../utils/constants";
import Flag from "./Flag";
import RemoveFlag from "./RemoveFlag";
import EditAndAddFlag from "./EditAndAddFlag";

const Flags = ({ data, refresh, loading, paginationData, setPaginationData, driverId }) => {
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(5);
  const [open, setOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [openInfo, setOpenInfo] = useState(false);
  const [openDeleteFlag, setOpenDeleteFlag] = useState(false);
  const [openAddFlag, setOpenAddFlag] = useState(false);
  const [flag, setFlag] = useState(null);
  const user = JSON.parse(localStorage.getItem("dvla_user"));

  const columns = [
    ...FLAGS_COLUMNS,
    {
      title: "M",
      key: "operation",
      render: (record) => (
        <Popover
          content={content}
          trigger="click"
          open={open && currentRow === record?.key}
          placement="bottomLeft"
          onOpenChange={(e) => {
            handleOpenChange(e, record);
          }}
        >
          <div className="cursor-pointer">
            <DVLAMore />
          </div>
        </Popover>
      ),
    },
  ];

  const handleView = () => {
    handleOpenInfoChange(true);
    hide();
  };

  const handleDelete = () => {
    handleOpenDeleteFlagChange(true);
    hide();
  };

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen, record) => {
    setOpen(newOpen);
    setCurrentRow(record?.key);
    const x = data?.content?.find(ff => ff.id === record?.id);
    setFlag(x)
  };

  const handleOpenInfoChange = (newOpen) => {
    setOpenInfo(newOpen);
  };

  const handleOpenAddFlagChange = (newOpen) => {
    setOpenAddFlag(newOpen);
  };

  const handleOpenDeleteFlagChange = (newOpen) => {
    setOpenDeleteFlag(newOpen);
  };

  const handleUpdateAndRefresh = () => {
    handleOpenAddFlagChange(false);
    refresh()
  };

  const handleConfirm = () => {
    handleOpenDeleteFlagChange(false);
    refresh()
  };

  const addFlag = (mode) => {
    if (mode !== "EDIT")
      setFlag(null);
    setOpenAddFlag(true);
    hide();
  }

  const table = data?.content?.map((flag, index) => {
    return {
      key: index,
      id: flag?.id,
      name: flag?.flagType,
      // comment: flag?.comment ?? "---",
      dateAdded: flag?.dateAdded ? dateFormat(flag?.dateAdded) : "---",
    };
  });

  const content = (
    <div>
      <div
        className="flex gap-x-4 items-center cursor-pointer text-labels"
        onClick={handleView}
      >
        <img src={DVLAView} alt={"dvla-view"} className={"h-4"} />
        <div>View</div>
      </div>
      {user?.isSiteAdmin && <div
        className="flex gap-x-4 items-center cursor-pointer text-labels mt-3"
        onClick={() => addFlag("EDIT")}
      >
        <img src={DVLAEdit} alt={"dvla-view"} className={"h-4"} />
        <div>Edit</div>
      </div>}
      {user?.isSiteAdmin && <div
        className="flex gap-x-4 items-center cursor-pointer text-labels mt-3"
        onClick={handleDelete}
      >
        <img src={DVLABin} alt={"dvla-view"} className={"h-4"} />
        <div>Delete</div>
      </div>}
    </div>
  );

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <a href={"#/"} className={"px-2 font-medium text-sm-regular"}>
          {"Prev"}
        </a>
      );
    }
    if (type === "next") {
      return (
        <a href={"#/"} className={"px-2 font-medium text-sm-regular"}>
          {"Next"}
        </a>
      );
    }
    return originalElement;
  };

  const infoContent = (
    <Flag flag={flag}
      handleClose={() => handleOpenInfoChange(false)}
    />
  );

  const deleteContent = (
    <RemoveFlag
      handleClose={() => handleOpenDeleteFlagChange(false)}
      handleConfirm={handleConfirm}
      id={flag?.id}
    />
  );

  const addFlagContent = (
    <EditAndAddFlag
      handleClose={() => handleOpenAddFlagChange(false)}
      handleUpdateAndRefresh={handleUpdateAndRefresh}
      id={driverId}
      flag={flag}
    />
  );

  const onShowSizeChange = (current, pageSize) => {
    if (pageSize || current) {
      setDefaultPageSize(pageSize);
      setDefaultCurrent(current - 1);
    } else {
      setDefaultPageSize(10);
      setDefaultCurrent(1);
    }
  };

  const paginationChangeHandler = (current, pageSize) => {
    setPaginationData({
      ...paginationData,
      page: current - 1,
      size: pageSize,
    });
  };

  return (
    <div className="w-full bg-white">
      {user?.isSiteAdmin &&
        <div className="mb-4 flex justify-end">
          <Button
            css={
              "py-[9px] rounded-md lg:text-[10px] text-[10px] xl:px-[16px] px-2"
            }
            state={"PRIMARY"}
            text={"Add Flag"}
            type={"button"}
            icon={DVLAAdd}
            onClick={() => addFlag("ADD")}
          />
        </div>}
      <Table
        loading={loading}
        columns={columns}
        dataSource={table}
        size={"small"}
        className={"custom-table"}
        pagination={{
          pageSizeOptions: ["5", "10", "20", "30", "40"],
          showSizeChanger: true,
          onShowSizeChange: onShowSizeChange,
          defaultCurrent: defaultCurrent,
          total: data?.totalElements,
          pageSize: defaultPageSize,
          showTotal: (total) => `Total ${total} items`,
          defaultPageSize: defaultPageSize,
          onChange: paginationChangeHandler,
          itemRender,
        }}
      />
      <Modal
        open={openInfo}
        closable={false}
        footer={null}
        onCancel={() => handleOpenInfoChange(false)}
        className={"invoice-modal"}
        width={550}
      >
        {infoContent}
      </Modal>

      <Modal
        open={openDeleteFlag}
        closable={false}
        footer={null}
        onCancel={() => handleOpenDeleteFlagChange(false)}
        className={"invoice-modal"}
        width={450}
      >
        {deleteContent}
      </Modal>

      <Modal
        open={openAddFlag}
        closable={false}
        footer={null}
        onCancel={() => handleOpenAddFlagChange(false)}
        className={"invoice-modal"}
        width={550}
      >
        {addFlagContent}
      </Modal>
    </div>
  );
};

export default Flags;
