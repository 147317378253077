import { Types } from "../actions/drivers.action";

const INITIAL_STATE = {
  loading: false,
  all_drivers: {},
  driver: {},
};

export const driversReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.ALL_DRIVERS_START:
    case Types.DRIVER_START:
    case Types.UPDATE_DRIVER_START:
    case Types.UPLOAD_DRIVER_DOCUMENT_START:
      return { ...state, loading: true };
    case Types.ALL_DRIVERS_SUCCESS:
      return {
        ...state,
        loading: false,
        all_drivers: action.all_drivers,
      };
    case Types.DRIVER_SUCCESS:
      return {
        ...state,
        loading: false,
        driver: action.driver,
      };
    case Types.UPDATE_DRIVER_SUCCESS:
    case Types.UPLOAD_DRIVER_DOCUMENT_SUCCESS:
      return { ...state, loading: false };
    case Types.ALL_DRIVERS_END:
    case Types.DRIVER_END:
    case Types.UPDATE_DRIVER_END:
    case Types.UPLOAD_DRIVER_DOCUMENT_END:
      return { ...state, loading: false, message: action.message };
    default:
      return state;
  }
};
