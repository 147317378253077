/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, Input, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { ReactComponent as DVLASearch } from "../../../assets/icons/dvla-search.svg";
import { ReactComponent as DVLAChevronDown } from "../../../assets/icons/dvla-chevron-down.svg";
import { ReactComponent as DVLAFilter } from "../../../assets/icons/dvla-filter.svg";
import "../styles.scss";
import { useSelector } from "react-redux";

const RideBatchesFilters = ({ handleSearch, setPartnerId }) => {
  const user = JSON.parse(localStorage.getItem("dvla_user"));
  const { businesses } = useSelector((state) => state?.businesses);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleCompnay = (id) => {
    setCompany(id);
    setPartnerId(id)
    hide();
  };

  useEffect(() => {
    if (businesses?.content && businesses?.content?.length) {
      setCompanyName(businesses?.content?.find((c) => c?.id === company)?.name);
    }
  }, [companies, company]);

  useEffect(() => {
    const all = { id: 0, name: "All" };
    let content = businesses?.content;
    if (content?.length) {
      content.unshift(all);
      setCompanies(content);
    } else {
      content = [all];
      setCompanies(content);
    }
  }, [businesses]);

  const content = (
    <div className="w-32">
      {companies?.map((c) => (
        <div
          key={c?.name}
          className="flex gap-x-4 items-center cursor-pointer py-2"
          onClick={() => handleCompnay(c?.id)}
        >
          <Checkbox checked={c?.id === company} className="form-checkbox">
            <div>{c?.name}</div>
          </Checkbox>
        </div>
      ))}
    </div>
  );

  const handleChange = (e) => {
    handleSearch(e?.target?.value);
  };

  return (
    <div className="w-full flex">
      <div className="w-3/4">
        <div className="w-full flex gap-x-4 justify-between">
          <div className="w-5/12">
            <Input
              onChange={handleChange}
              className={"form-field_input_0"}
              placeholder="Search"
              prefix={<DVLASearch />}
              allowClear
            />
          </div>
        </div>
      </div>
      {user?.isSiteAdmin && <div className="flex justify-end w-1/4">
        <div className="w-auto">
          <Popover
            content={content}
            trigger="click"
            open={open}
            placement="bottomLeft"
            onOpenChange={handleOpenChange}
          >
            <div className="flex items-center gap-x-3 text-labels cursor-pointer border-[1px] border-gray-300 p-[10px] rounded-[4px] bg-dark-100">
              <DVLAFilter className="h-4 w-4" />
              <span> Company ({companyName})</span>
              <DVLAChevronDown className="trend-icon" />
            </div>
          </Popover>
        </div>
      </div>}
    </div>
  );
};

export default RideBatchesFilters;
