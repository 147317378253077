import { Types } from "../actions/vehicles.action";

const INITIAL_STATE = {
  loading: false,
  all_vehicles: {},
  vehicle: {},
};

export const vehicleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.ALL_VEHICLES_START:
    case Types.VEHICLE_START:
    case Types.UPDATE_VEHICLE_START:
      return { ...state, loading: true };
    case Types.ALL_VEHICLES_SUCCESS:
      return {
        ...state,
        loading: false,
        all_vehicles: action.all_vehicles,
      };
    case Types.VEHICLE_SUCCESS:
      return {
        ...state,
        loading: false,
        vehicle: action.vehicle,
      };
    case Types.UPDATE_VEHICLE_SUCCESS:
      return { ...state, loading: false };
    case Types.ALL_VEHICLES_END:
    case Types.VEHICLE_END:
    case Types.UPDATE_VEHICLE_END:
      return { ...state, loading: false, message: action.message };
    default:
      return state;
  }
};
