/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import "../styles.scss";
import { Table } from "antd";
import RideBatchesFilters from "./RideBatchesFilters";
import { useSelector } from "react-redux";
import { formatDate } from "../../../utils/constants";

const STATUS_PENDING = "px-2 py-1 bg-[#fef6ee] text-[#FA8C2A] rounded-md";
const STATUS_ACTIVE = "px-2 py-1 bg-[#edfaf3] text-[#23BE65] rounded-md";

const AllRideBatches = ({
  paginationData,
  setPaginationData,
  handleSearch,
  setPartnerId,
}) => {
  const loader = useSelector((state) => state?.users?.loading);
  const rideBatches = useSelector((state) => state?.rides?.rideBatches);
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(10);

  const columns = [
    {
      title: "FILE NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "DATE UPLOADED",
      dataIndex: "uploadedAt",
      key: "uploadedAt",
    },
    {
      title: "DESCRIPTION",
      dataIndex: "note",
      key: "note",
    },
    {
      title: "TOTAL RECORDS",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
    },
  ];

  const onShowSizeChange = (current, pageSize) => {
    if (pageSize || current) {
      setDefaultPageSize(pageSize);
      setDefaultCurrent(current - 1);
    } else {
      setDefaultPageSize(10);
      setDefaultCurrent(1);
    }
  };

  const paginationChangeHandler = (current, pageSize) => {
    setPaginationData({
      ...paginationData,
      page: current - 1,
      size: pageSize,
    });
  };

  const dataSource =
    rideBatches &&
    rideBatches?.content?.length > 0 &&
    rideBatches?.content?.map((row) => {
      return {
        key: row.id,
        name: row?.name ?? "-",
        uploadedAt: row?.uploadedAt ? formatDate(row?.uploadedAt) : "-",
        note: row?.note ?? "-",
        total: row?.total ?? "-",
        status: (
          <span
            className={
              row?.status === "ACTIVE" ? STATUS_ACTIVE : STATUS_PENDING
            }
          >
            {row?.status?.toString()?.toUpperCase() ?? "-"}
          </span>
        ),
      };
    });

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <a href={"#/"} className={"px-2 font-medium text-sm-regular"}>
          {"< Prev"}
        </a>
      );
    }
    if (type === "next") {
      return (
        <a href={"#/"} className={"px-2 font-medium text-sm-regular"}>
          {"Next >"}
        </a>
      );
    }
    return originalElement;
  };

  return (
    <div className="w-full">
      <RideBatchesFilters handleSearch={handleSearch} setPartnerId={setPartnerId} />
      <div className="w-full mt-10" style={{ minHeight: "20rem" }}>
        <Table
          loading={loader}
          columns={columns}
          dataSource={dataSource}
          size={"small"}
          className={"vcd-table"}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40"],
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            defaultCurrent: defaultCurrent,
            total: rideBatches?.totalElements,
            pageSize: defaultPageSize,
            showTotal: (total) => `Total ${total} items`,
            defaultPageSize: defaultPageSize,
            onChange: paginationChangeHandler,
            itemRender,
          }}
        />
      </div>
    </div>
  );
};

export default AllRideBatches;
