/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DVLAPictureAvatar from "../../../assets/icons/dvla-picture-avatar.svg";
import { ReactComponent as DVLACornerUpLeft } from "../../../assets/icons/corner-up-left.svg";
import "../styles.scss";
import { PROFILE_TABS } from "../../../utils/misc/database";
import Documents from "../Shared/Documents";
import Flags from "../Shared/Flags";
import Profile from "../Shared/Profile";
import { goBack } from "../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { FetchDriver } from "../../../redux/actions/drivers.action";
import MainLayout from "../../../layouts/MainLayout";
import { useParams } from "react-router-dom";
import { FetchFlags } from "../../../redux/actions/flags.action";

const DriverInfo = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(1);
  const { driver } = useSelector((state) => state?.drivers);
  const { flags } = useSelector((state) => state?.flags);
  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 5,
  });

  useEffect(() => {
    if (params?.id) {
      dispatch(FetchDriver(params?.id));
    }
  }, [params]);

  useEffect(() => {
    if (params?.id) {
      dispatch(FetchFlags({ driverId: params?.id, ...paginationData }))
    } else {
      goBack();
    }
  }, [dispatch, paginationData])

  return (
    <MainLayout title={"Driver"}>
      <div>
        <div className="text-left mb-6 lg:mb-8 w-full">
          <div
            className="flex gap-x-2 bg-primary-100 text-primary-500 font-semibold rounded-lg p-2 text-labels hover:shadow-md cursor-pointer transition ease-in-out duration-300"
            style={{ width: "fit-content" }}
            onClick={goBack}
          >
            <DVLACornerUpLeft />
            Back to search results
          </div>
        </div>
        <div className="w-full info-card bg-white px-8 py-6 flex flex-col gap-y-2">
          <div className="flex gap-x-4 items-center">
            <img src={DVLAPictureAvatar} alt={"dvla-user"} className={"h-16 w-16"} />
            <div className="flex flex-col gap-y-3">
              <h3 className="font-bold text-[18px]">{driver?.name ?? "---"}</h3>
              <p className="text-labels text-dark-400">{driver?.email ?? "---"}</p>
            </div>
          </div>
          <div className="mt-6 border-b border-b-neutral-100 border-b-[0.5px] w-full flex text-labels gap-x-10">
            {PROFILE_TABS?.map((dt) => (
              <div
                key={dt?.name}
                className={
                  dt?.id === activeTab
                    ? "border-b border-b-secondary-300 border-b-2 pb-3 cursor-pointer font-medium transition ease-in-out duration-300"
                    : "cursor-pointer text-neutral-300 transition ease-in-out duration-300"
                }
                onClick={() => setActiveTab(dt?.id)}
              >
                {dt?.name}
              </div>
            ))}
          </div>
          <div className="w-full mt-3">
            {activeTab === 1 ? (
              <Profile data={driver} />
            ) : activeTab === 2 ? (
              <Documents data={driver} />
            ) : (
              <Flags data={flags}
                paginationData={paginationData}
                setPaginationData={setPaginationData}
              />
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default DriverInfo;
