/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DVLAUser from "../../../assets/imgs/dvla-user.png";
import DVLAEdit from "../../../assets/imgs/dvla-edit.png";
import { VEHICLE_TABS } from "../../../utils/misc/database";
import "../styles.scss";
import Vehicle from "./Vehicle";
import { FetchVehicle } from "../../../redux/actions/vehicles.action";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";
import UpdateVehicle from "./UpdateVehicle";

const VehicleDetails = ({ id }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(1);
  const [openInfo, setOpenInfo] = useState(false);
  const { vehicle } = useSelector((state) => state?.vehicles);
  const user = JSON.parse(localStorage.getItem("dvla_user"));

  useEffect(() => {
    if (id) {
      dispatch(FetchVehicle(id));
    }
  }, [id])

  const handleMore = () => {
    handleOpenInfoChange(true);
  };

  const handleOpenInfoChange = (newOpen) => {
    setOpenInfo(newOpen);
  };

  const handleUpdate = () => {
    handleOpenInfoChange(false)
    dispatch(FetchVehicle(id));
  };

  const infoContent = (
    <UpdateVehicle
      handleClose={() => handleOpenInfoChange(false)}
      handleUpdate={handleUpdate}
      vehicle={vehicle}
      id={id}
    />
  );

  return (
    <div className="w-full info-card bg-white px-8 py-6 flex flex-col gap-y-2">
      <div className="flex gap-x-4 items-center">
        <img src={DVLAUser} alt={"dvla-user"} className={"h-16 w-16"} />
        <div className="flex flex-col gap-y-3">
          <div className="flex items-center gap-x-4">
            <h3 className="font-bold text-[18px]">{vehicle?.registrationNumber}</h3>
            {user?.isSiteAdmin && <img onClick={handleMore} src={DVLAEdit} alt={"dvla-user"} className={"h-5 w-5 cursor-pointer"} />}
          </div>
          <p className="text-labels text-dark-400">{vehicle?.brand} {vehicle?.model}</p>
        </div>
      </div>
      <div className="mt-6 border-b border-b-neutral-100 border-b-[0.5px] w-full flex text-labels gap-x-10">
        {VEHICLE_TABS?.map((dt) => (
          <div
            key={dt?.name}
            className={
              dt?.id === activeTab
                ? "border-b border-b-secondary-300 border-b-2 pb-3 cursor-pointer font-medium transition ease-in-out duration-300"
                : "cursor-pointer text-neutral-300 transition ease-in-out duration-300"
            }
            onClick={() => setActiveTab(dt?.id)}
          >
            {dt?.name}
          </div>
        ))}
      </div>
      <div className="w-full mt-3">
        {activeTab === 1 ? (
          <Vehicle data={vehicle} />
        ) : (
          <></>
        )}
      </div>
      <Modal
        open={openInfo}
        closable={false}
        footer={null}
        onCancel={() => handleOpenInfoChange(false)}
        className={"invoice-modal"}
        width={550}
      >
        {infoContent}
      </Modal>
    </div>
  );
};

export default VehicleDetails;
