/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MainLayout from "../../../layouts/MainLayout";
import "../styles.scss";
import DriversFilters from "./DriversFilters";
import DriversTable from "./DriversTable";
import { useDispatch } from "react-redux";
import { FetchDrivers } from "../../../redux/actions/drivers.action";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";

const Drivers = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState({ searchQuery: "" });
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" })
  const [status, setStatus] = useState("");
  const dispatch = useDispatch()
  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 10,
  });
  const handleSearch = (value) => {
    performSearch(value);
  };

  const performSearch = debounce((value) => {
    if (value.length > 0) {
      setQuery({ searchQuery: value });
    } else {
      setQuery({ searchQuery: "" });
    }
  }, 700);

  useEffect(() => {
    dispatch(FetchDrivers({ ...paginationData, ...query, ...dateRange, status }))
  }, [dispatch, status, paginationData, query, dateRange])

  const handleDriver = (id) => {
    navigate(`/drivers/details/${id}`);
  };

  return (
    <MainLayout title={"Drivers"}>
      <>
        <DriversFilters
          handleSearch={handleSearch}
          setDateRange={setDateRange}
          handleStatusChange={setStatus}
        />
        <DriversTable
          setId={handleDriver}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
        />
      </>
    </MainLayout>
  );
};

export default Drivers;
