/* eslint-disable react-hooks/exhaustive-deps */
import { Table } from "antd";
import React, { useState } from "react";
import "../styles.scss";
import { useSelector } from "react-redux";
import { formatDate } from "../../../utils/constants";

const DriversTable = ({
  setId,
  paginationData,
  setPaginationData,
}) => {
  const all_drivers = useSelector((state) => state?.drivers?.all_drivers);
  const loading = useSelector((state) => state?.drivers?.loading);
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(10);

  const columns = [
    {
      title: "FIRST NAME",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "LAST NAME",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "DRIVER'S LICENSE",
      dataIndex: "ndl",
      key: "ndl",
    },
    {
      title: "LIECENSE EXIPIRY DATE",
      dataIndex: "licenseNumber",
      key: "licenseNumber",
    },
  ];

  const data = all_drivers &&
    all_drivers?.content?.length > 0 &&
    all_drivers?.content?.map((driver, index) => {
      return {
        key: index,
        licenseNumber: driver?.licenseNumber ?? "---",
        firstName: driver?.firstName ?? "---",
        lastName: driver?.lastName ?? "---",
        phoneNumber: driver?.phoneNumber ?? "---",
        ndl: driver?.ndl ?? "---",
        licenseExpiryDate: driver?.licenseExpiryDate ? formatDate(driver?.licenseExpiryDate) : "---",
        id: driver?.id,
      };
    });

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <a href={"#/"} className={"px-2 font-medium text-sm-regular"}>
          {"Prev"}
        </a>
      );
    }
    if (type === "next") {
      return (
        <a href={"#/"} className={"px-2 font-medium text-sm-regular"}>
          {"Next"}
        </a>
      );
    }
    return originalElement;
  };

  const onShowSizeChange = (current, pageSize) => {
    if (pageSize || current) {
      setDefaultPageSize(pageSize);
      setDefaultCurrent(current - 1);
    } else {
      setDefaultPageSize(10);
      setDefaultCurrent(1);
    }
  };

  const paginationChangeHandler = (current, pageSize) => {
    setPaginationData({
      ...paginationData,
      page: current - 1,
      size: pageSize,
    });
  };

  return (
    <div className="w-full px-10 bg-white mt-6 ride-table-shadow pb-4">
      <div style={{ marginBottom: 16 }}>
        <span style={{ marginLeft: 8 }}></span>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        size={"small"}
        className={"d-table"}
        onRow={(driver) => {
          return {
            onClick: () => {
              setId(driver?.id);
            },
          };
        }}
        loading={loading}
        pagination={{
          pageSizeOptions: ["10", "20", "30", "40"],
          showSizeChanger: true,
          onShowSizeChange: onShowSizeChange,
          defaultCurrent: defaultCurrent,
          total: all_drivers?.totalElements,
          pageSize: defaultPageSize,
          showTotal: (total) => `Total ${total} items`,
          defaultPageSize: defaultPageSize,
          onChange: paginationChangeHandler,
          itemRender,
        }}
      />
    </div>
  );
};

export default DriversTable;
