/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../styles.scss";
import { Field, Formik } from "formik";
import FloatLabel from "../../../components/Inputs/FloatLabel";
import { Form, Input, notification, Select } from "antd";
import Button from "../../../components/Buttons/Button";
import * as yup from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import FieldContainer from "../../../components/Inputs/FIeldContainer";
import { FetchPartnerUsers, FetchUsers, InvitePartnerUser } from "../../../redux/actions/users.action";
import { FetchBusinesses } from "../../../redux/actions/businesses.action";

const values = {
    partner: "",
    fullname: "",
    email: "",
    phoneNumber: "",
};

const NewPartner = () => {
    const user = JSON.parse(localStorage.getItem("dvla_user"));
    const [form] = Form.useForm();
    const loader = useSelector((state) => state?.users?.loading);
    const businesses = useSelector((state) => state?.businesses?.businesses);
    const [options, setOptions] = useState([]);
    const dispatch = useDispatch();

    const createUser = (values, { resetForm }) => {
        dispatch(
            InvitePartnerUser(values, values?.partner)
        )
            .then(async (res) => {
                notification.success({
                    message: "Success",
                    description: "Member Added Successfully!",
                });
                resetForm();
                form.resetFields();
                user?.isSiteAdmin ? dispatch(FetchUsers({ page: 0, size: 10, name: "", businessId: "" }))
                    : dispatch(FetchPartnerUsers({ page: 0, size: 10, name: "" }));
            })
            .catch((err) => {
                notification.error({
                    message: "Error",
                    description: err.response.data.errorMsg
                        ? err.response.data.errorMsg
                        : "An error has ocurred, please try again later",
                });
            });
    };

    useEffect(() => {
        dispatch(FetchBusinesses({}));
    }, [dispatch]);

    useEffect(() => {
        if (businesses && businesses?.content && businesses?.content?.length) {
            const content = businesses?.content?.map((c) => {
                return { value: c?.id, label: c?.name };
            });
            setOptions(content);
        }
    }, [businesses]);

    return (
        <div className="w-full info-card bg-white px-8 py-6 flex flex-col gap-y-2">
            <h3 className="text-secondary-300 font-semibold text-[18px] border-b pb-4 border-b-neutral-100 border-b-[0.5px]">
                Create Partner User
            </h3>
            <div className="w-full flex items-center justify-center">
                <div className="w-1/2 my-10">
                    <Formik
                        className="w-full"
                        initialValues={values}
                        onSubmit={createUser}
                        enableReinitialize={true}
                        validationSchema={validator}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => (
                            <Form onFinish={handleSubmit} form={form}>
                                <div className={"mb-4"}>
                                    <FieldContainer iconPlacementCss={"iconCss"}>
                                        <FloatLabel label="Partner" value={values?.partner || ""}>
                                            <Form.Item
                                                name={"partner"}
                                                help={
                                                    touched.partner && errors.partner
                                                        ? errors.partner
                                                        : ""
                                                }
                                                validateStatus={
                                                    touched.partner && errors.partner
                                                        ? "error"
                                                        : "success"
                                                }
                                            >
                                                <Field>
                                                    {({ form: { setFieldValue } }) => (
                                                        <Select
                                                            value={values?.partner || ""}
                                                            onBlur={handleBlur}
                                                            className={"form-field_input_2"}
                                                            onChange={(e) => {
                                                                setFieldValue("partner", e);
                                                            }}
                                                            options={options}
                                                        />
                                                    )}
                                                </Field>
                                            </Form.Item>
                                        </FloatLabel>
                                    </FieldContainer>
                                </div>
                                <div className={"mb-4"}>
                                    <FieldContainer iconPlacementCss={"iconCss"}>
                                        <FloatLabel
                                            label="Full Name"
                                            value={values?.fullname || ""}
                                        >
                                            <Form.Item
                                                name={"fullname"}
                                                help={
                                                    touched.fullname && errors.fullname
                                                        ? errors.fullname
                                                        : ""
                                                }
                                                validateStatus={
                                                    touched.fullname && errors.fullname
                                                        ? "error"
                                                        : "success"
                                                }
                                            >
                                                <Input
                                                    type={"text"}
                                                    className={"form-field_input_2"}
                                                    value={values?.fullname || ""}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Form.Item>
                                        </FloatLabel>
                                    </FieldContainer>
                                </div>
                                <div className={"mb-4"}>
                                    <FieldContainer iconPlacementCss={"iconCss"}>
                                        <FloatLabel label="Email" value={values?.email || ""}>
                                            <Form.Item
                                                name={"email"}
                                                help={touched.email && errors.email ? errors.email : ""}
                                                validateStatus={
                                                    touched.email && errors.email ? "error" : "success"
                                                }
                                            >
                                                <Input
                                                    type={"email"}
                                                    className={"form-field_input_2"}
                                                    value={values?.email || ""}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Form.Item>
                                        </FloatLabel>
                                    </FieldContainer>
                                </div>
                                <div className={"mb-4"}>
                                    <FieldContainer iconPlacementCss={"iconCss"}>
                                        <FloatLabel
                                            label="Phone Number (E.g +23412341234)"
                                            value={values?.phoneNumber || ""}
                                        >
                                            <Form.Item
                                                name={"phoneNumber"}
                                                help={
                                                    touched.phoneNumber && errors.phoneNumber
                                                        ? errors.phoneNumber
                                                        : ""
                                                }
                                                validateStatus={
                                                    touched.phoneNumber && errors.phoneNumber
                                                        ? "error"
                                                        : "success"
                                                }
                                            >
                                                <Input
                                                    type={"tel"}
                                                    className={"form-field_input_2"}
                                                    value={values?.phoneNumber || ""}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Form.Item>
                                        </FloatLabel>
                                    </FieldContainer>
                                </div>
                                <div className={"flex justify-center"}>
                                    <Button
                                        css={"w-full"}
                                        state={"PRIMARY"}
                                        text={"Create"}
                                        type={"submit"}
                                        loading={loader}
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

const validator = yup.object().shape({
    partner: yup.string().required("Partner is required"),
    fullname: yup
        .string()
        .min(6, "Name should be minimum of 6 characters")
        .required("Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    phoneNumber: yup
        .string()
        .required("Phone number is required")
        .test("is-valid-phone", "Phone number is invalid", (value) => {
            return isValidPhoneNumber(value || "");
        }),
});

export default NewPartner;
