/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { ReactComponent as DVLAChevronDown } from "../../../assets/icons/dvla-chevron-down.svg";
import { ReactComponent as DVLACalendar } from "../../../assets/icons/dvla-calendar.svg";
import "../styles.scss";
import { useSelector } from "react-redux";
import { numberWithCommas } from "../../../utils/constants";

const Overview = ({ getCompanyId }) => {
  const user = JSON.parse(localStorage.getItem("dvla_user"));
  const [company, setCompany] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [open, setOpen] = useState(false);
  const { businesses } = useSelector((state) => state?.businesses);
  const all_time_rides = useSelector((state) => state?.stats?.all_time_rides);
  const business_ride_stats = useSelector((state) => state?.stats?.business_ride_stats);
  const business_total_rides = useSelector((state) => state?.stats?.business_total_rides);
  const [totalRides, setTotalRides] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [companies, setCompanies] = useState([]);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleCompnay = (id) => {
    setCompany(id);
    getCompanyId(id);
    hide();
  };

  useEffect(() => {
    if (businesses?.content && businesses?.content?.length) {
      setCompanyName(businesses?.content?.find((c) => c?.id === company)?.name);
    }
  }, [companies, company]);

  useEffect(() => {
    const all = { id: 0, name: "All" };
    let content = businesses?.content;
    if (content?.length) {
      content.unshift(all);
      setCompanies(content);
    } else {
      content = [all];
      setCompanies(content);
    }
  }, [businesses]);

  useEffect(() => {
    if (user?.isSiteAdmin) {
      if (all_time_rides) {
        setTotalRides(all_time_rides?.totalRides ?? 0)
        setTotalRevenue(all_time_rides?.totalFare ?? 0)
        // setTotalRides(all_time_rides?.reduce((a, b) => a + b?.total, 0))
        // setTotalRevenue(all_time_rides?.reduce((a, b) => a + b?.totalFare, 0))
      }
    } else {
      if (business_ride_stats && business_ride_stats?.length) {
        setTotalRides(business_ride_stats?.reduce((a, b) => a + b?.total, 0))
        setTotalRevenue(business_ride_stats?.reduce((a, b) => a + b?.totalFare, 0))
      } else {
        setTotalRides(0)
        setTotalRevenue(0)
      }
    }
  }, [all_time_rides, business_ride_stats]);

  const content = (
    <div className="w-32">
      {companies?.map((c) => (
        <div
          key={c?.name}
          className="flex gap-x-4 items-center cursor-pointer py-2"
          onClick={() => handleCompnay(c?.id)}
        >
          <Checkbox checked={c?.id === company} className="form-checkbox">
            <div>{c?.name}</div>
          </Checkbox>
        </div>
      ))}
    </div>
  );

  return (
    <div className="w-full bg-primary-400 rounded-lg text-sm-regular p-6 text-white">
      <div className="w-full flex justify-between items-center border-b border-b-[1px] border-b-light pb-4">
        <p className="font-semibold text-sm-headline">Overview</p>
        {user?.isSiteAdmin && (
          <Popover
            content={content}
            trigger="click"
            open={open}
            placement="bottomLeft"
            onOpenChange={handleOpenChange}
          >
            <div className="flex items-center gap-x-3 text-labels cursor-pointer border-[1px] border-white p-2 rounded-[4px] bg-primary-300">
              <DVLACalendar className="h-4 w-4" />
              <span> Company ({companyName})</span>
              <DVLAChevronDown className="overview-icon" />
            </div>
          </Popover>
        )}
      </div>
      <div className="w-full flex justify-between mt-4">
        <div className="w-[49%] p-4 bg-primary-300 rounded-lg">
          <p>
            Total Rides {company === 0 ? "" : "("}
            <span className="font-semibold">
              {company === 0 ? "" : companyName}
            </span>
            {company === 0 ? "" : "}"}
          </p>
          <h3 className="text-headline mt-4 font-bold">{numberWithCommas(!totalRides && !user?.isSiteAdmin ? business_total_rides : totalRides)}</h3>
        </div>
        <div className="w-[49%] p-4 bg-primary-300 rounded-lg">
          <p>
            Total Fare {company === 0 ? "" : "("}
            <span className="font-semibold">
              {company === 0 ? "" : companyName}
            </span>
            {company === 0 ? "" : "}"}
          </p>
          <h3 className="text-headline mt-4 font-bold">
            NGN {numberWithCommas(totalRevenue)}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Overview;
