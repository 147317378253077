export const DRIVERS_COLUMNS = [
  {
    title: "DRIVER’S NAME",
    dataIndex: "driverName",
  },
  {
    title: "LICENSE NUMBER",
    dataIndex: "licenseNumber",
  },
  {
    title: "VEHICLE REG.",
    dataIndex: "vehicleReg",
  },
  {
    title: "REG. DATE",
    dataIndex: "regDate",
  },
  {
    title: "VERIFICATION STATUS",
    dataIndex: "verificationStatus",
  },
];

export const DOCUMENTS_COLUMNS = [
  {
    title: "DOCUMENT NAME",
    dataIndex: "documentName",
  },
  {
    title: "DOCUMENT NUMBER",
    dataIndex: "documentNumber",
  },
  {
    title: "EXPIRY DATE",
    dataIndex: "expiryDate",
  },
  {
    title: "STATUS",
    dataIndex: "status",
  },
];

export const FLAGS_COLUMNS = [
  {
    title: "FLAG",
    dataIndex: "name",
  },
  // {
  //   title: "COMMENT",
  //   dataIndex: "comment",
  // },
  {
    title: "DATE ADDED",
    dataIndex: "dateAdded",
  },
];
