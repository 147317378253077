/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { ReactComponent as DVLACornerUpLeft } from "../../../assets/icons/corner-up-left.svg";
import "../styles.scss";
import UploadNewRideBatch from "./UploadNewRideBatch";

const RideBatchUpload = ({ newBatch, goBack }) => {
    return (
        <div>
            <div className="text-left mb-6 lg:mb-8 w-full">
                <div
                    className="flex gap-x-2 bg-primary-100 text-primary-500 font-semibold rounded-lg p-2 text-labels hover:shadow-md cursor-pointer transition ease-in-out duration-300"
                    style={{ width: "fit-content" }}
                    onClick={goBack}
                >
                    <DVLACornerUpLeft />
                    Back
                </div>
            </div>
            {newBatch && <UploadNewRideBatch />}
        </div>
    );
};

export default RideBatchUpload;
