/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { ReactComponent as DVLACornerUpLeft } from "../../assets/icons/corner-up-left.svg";
import DVLAUber from "../../assets/imgs/dvla-uber.png";
import DVLAinDrive from "../../assets/imgs/dvla-indrive.png";
import DVLAYango from "../../assets/imgs/dvla-yango.png";
import DVLABolt from "../../assets/imgs/dvla-bolt.png";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import NameBadge from "./Login/NameBadge";
import { getTenantLogoText } from "../../utils/constants";

const AuthHeading = ({ backButtonName, location, partner, subHeading }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="text-left px-4 sm:px-24 md:px-48 lg:px-12 xl:px-24 xl:max-w-2xl w-full">
        <div className="cursor-pointer flex items-center gap-x-6">
          <div>
            <img
              className={"lg:h-16 h-12"}
              src={getTenantLogoText()}
              alt={"dvla-logo-text"}
            />
          </div>
          {partner?.name ? (
            <>
              <div className="border-l border-secondary-500 lg:h-16 h-12"></div>
              <div>
                {
                  partner?.name?.toLowerCase() === "idrive" ? <img
                    className={"lg:h-16 h-12"}
                    src={DVLAinDrive}
                    alt={"dvla-logo-text"}
                  /> : partner?.name?.toLowerCase() === "yango" ? <img
                    className={"lg:h-16 h-12"}
                    src={DVLAYango}
                    alt={"dvla-logo-text"}
                  /> : partner?.name?.toLowerCase() === "uber" ? <img
                    className={"lg:h-16 h-12"}
                    src={DVLAUber}
                    alt={"dvla-logo-text"}
                  /> : partner?.name?.toLowerCase() === "bolt rides" ? <img
                    className={"lg:h-16 h-12"}
                    src={DVLABolt}
                    alt={"dvla-logo-text"}
                  /> : <NameBadge customClass={"lg:h-16 h-12 lg:w-16 w-12 lg:text-headline text-sm-headline"} name={partner?.name} />
                }
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="mt-4 text-left px-4 sm:px-24 md:px-48 lg:px-12 mb-6 lg:mb-8 xl:px-24 xl:max-w-2xl w-full">
        <div className="lg:text-sm-headline text-sm-regular font-bold tracking-wide">
          Revolutionising Traffic Regulation
        </div>
        <div className="text-sm-regular tracking-wide font-light mt-2">
          {partner?.name && !subHeading ? "Login to your account on DriveIQ" : subHeading ? subHeading : "Start Today."}
        </div>
      </div>
      {backButtonName && (
        <div className="text-left px-4 sm:px-24 md:px-48 lg:px-12 mb-6 lg:mb-8 xl:px-24 xl:max-w-2xl w-full">
          <div
            className="flex gap-x-2 bg-primary-100 text-primary-500 font-semibold rounded-lg p-2 text-labels hover:shadow-md cursor-pointer transition ease-in-out duration-300"
            style={{ width: "fit-content" }}
            onClick={() =>
              location === "PREV" ? window.history.back() : navigate("/")
            }
          >
            <DVLACornerUpLeft />
            {backButtonName}
          </div>
        </div>
      )}
    </>
  );
};

export default AuthHeading;
