/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { DATABASE_TABS } from "../../../utils/misc/database";
import "../styles.scss";
import VerifyDriver from "./VerifyDriver";
import VerifyVehicle from "./VerifyVehicle";

const VerifyDriverAndVehicle = ({ getDriver, getVehicle, handleSearch, handleActiveTab, paginationData, setPaginationData }) => {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <div className="w-full info-card bg-white px-8 py-6 flex flex-col gap-y-2">
      <h3 className="text-secondary-300 font-semibold text-[18px] border-b pb-4 border-b-neutral-100 border-b-[0.5px]">
        Drivers & Vehicles
      </h3>
      <div className="mt-6 border-b border-b-neutral-100 border-b-[0.5px] w-full flex text-sm-regular gap-x-10">
        {DATABASE_TABS?.map((dt) => (
          <div
            key={dt?.name}
            className={
              dt?.id === activeTab
                ? "border-b border-b-secondary-300 border-b-2 pb-3 cursor-pointer font-medium transition ease-in-out duration-300"
                : "cursor-pointer text-neutral-300 transition ease-in-out duration-300"
            }
            onClick={() => { setActiveTab(dt?.id); handleActiveTab(dt?.id) }}
          >
            {dt?.name}
          </div>
        ))}
      </div>
      <div className="w-full mt-3">
        {activeTab === 1 ? (
          <VerifyDriver
            getDriver={getDriver}
            handleSearch={(value) => handleSearch(value, activeTab)}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        ) : (
          <VerifyVehicle
            getVehicle={getVehicle}
            handleSearch={(value) => handleSearch(value, activeTab)}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        )}
      </div>
    </div>
  );
};

export default VerifyDriverAndVehicle;
