/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import "../styles.scss";
import { Table } from "antd";
import { useSelector } from "react-redux";
import { formatDate } from "../../../utils/constants";
import RideSummaryFilters from "./RideSummaryFilters";

const RideSummary = ({
    setDateRange,
    paginationData,
    setPaginationData,
    setPartnerId,
}) => {
    const loader = useSelector((state) => state?.rides?.loading);
    const rideSummary = useSelector((state) => state?.rides?.rideSummary);
    const [defaultCurrent, setDefaultCurrent] = useState(1);
    const [defaultPageSize, setDefaultPageSize] = useState(10);

    const columns = [
        {
            title: "COUNT",
            dataIndex: "total",
            key: "total",
        },
        {
            title: "RIDE DATE",
            dataIndex: "rideDate",
            key: "rideDate",
        },
        {
            title: "BUSINESS",
            dataIndex: "name",
            key: "name",
        },
    ];

    const onShowSizeChange = (current, pageSize) => {
        if (pageSize || current) {
            setDefaultPageSize(pageSize);
            setDefaultCurrent(current - 1);
        } else {
            setDefaultPageSize(10);
            setDefaultCurrent(1);
        }
    };

    const paginationChangeHandler = (current, pageSize) => {
        setPaginationData({
            ...paginationData,
            page: current - 1,
            size: pageSize,
        });
    };

    const dataSource =
        rideSummary &&
        rideSummary?.length > 0 &&
        rideSummary?.map((row, index) => {
            return {
                key: index + "summary" + row?.rideDate,
                total: row?.total ?? "-",
                rideDate: row?.rideDate ? formatDate(row?.rideDate).split(" ")[0] : "-",
                name: row?.business?.name ?? "-",
            };
        });

    const itemRender = (_, type, originalElement) => {
        if (type === "prev") {
            return (
                <a href={"#/"} className={"px-2 font-medium text-sm-regular"}>
                    {"< Prev"}
                </a>
            );
        }
        if (type === "next") {
            return (
                <a href={"#/"} className={"px-2 font-medium text-sm-regular"}>
                    {"Next >"}
                </a>
            );
        }
        return originalElement;
    };

    return (
        <div className="w-full info-card bg-white px-5 py-6 flex flex-col gap-y-2">
            <div className="w-full">
                <RideSummaryFilters setDateRange={setDateRange} setPartnerId={setPartnerId} />
                <div className="w-full mt-10" style={{ minHeight: "20rem" }}>
                    <Table
                        loading={loader}
                        columns={columns}
                        dataSource={dataSource}
                        size={"small"}
                        className={"vcd-table"}
                        pagination={{
                            pageSizeOptions: ["10", "20", "30", "40"],
                            showSizeChanger: true,
                            onShowSizeChange: onShowSizeChange,
                            defaultCurrent: defaultCurrent,
                            total: rideSummary?.totalElements,
                            pageSize: defaultPageSize,
                            showTotal: (total) => `Total ${total} items`,
                            defaultPageSize: defaultPageSize,
                            onChange: paginationChangeHandler,
                            itemRender,
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default RideSummary;
