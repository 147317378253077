import { Types } from "../actions/users.action";

const INITIAL_STATE = {
  loading: false,
  currentUser: {},
  users: {},
};

export const usersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.FETCH_CURRENT_USER_START:
    case Types.FETCH_USERS_START:
    case Types.INVITE_USER_START:
    case Types.INVITE_PARTNER_USER_START:
      return { ...state, loading: true };
    case Types.INVITE_USER_SUCCESS:
    case Types.INVITE_PARTNER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case Types.FETCH_CURRENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.currentUser,
      };
    case Types.FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.users,
      };
    case Types.FETCH_CURRENT_USER_END:
    case Types.FETCH_USERS_END:
    case Types.INVITE_USER_END:
    case Types.INVITE_PARTNER_USER_END:
      return { ...state, loading: false, message: action.message };
    default:
      return state;
  }
};
