/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MainLayout from "../../../layouts/MainLayout";
import "../styles.scss";
import { useDispatch } from "react-redux";
import CompaniesFilters from "./CompaniesFilters";
import CompaniesTable from "./CompaniesTable";
import { FetchBusinesses } from "../../../redux/actions/businesses.action";
import { debounce } from "lodash";

const Companies = () => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState({ searchQuery: "" });
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" })
  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 10,
  });
  const handleSearch = (value) => {
    performSearch(value);
  };

  const performSearch = debounce((value) => {
    if (value.length > 0) {
      setQuery({ searchQuery: value });
    } else {
      setQuery({ searchQuery: "" });
    }
  }, 700);

  useEffect(() => {
    dispatch(FetchBusinesses({ ...paginationData, ...query, ...dateRange }))
  }, [dispatch, paginationData, query, dateRange]);

  return (
    <MainLayout title={"Partners"}>
      <CompaniesFilters handleSearch={handleSearch} setDateRange={setDateRange} />
      <CompaniesTable
        paginationData={paginationData}
        setPaginationData={setPaginationData}
      />
    </MainLayout>
  );
};

export default Companies;
