export const STATUSES = [
  {
    id: 1,
    name: "All",
  },
  {
    id: 2,
    name: "PAID",
  },
  {
    id: 3,
    name: "PENDING",
  },
  {
    id: 4,
    name: "OVERDUE",
  },
];

export const VERIFICATION_STATUS = [
  {
    id: 1,
    name: "All",
  },
  {
    id: 2,
    name: "PENDING",
  },
  {
    id: 3,
    name: "APPROVED",
  },
  {
    id: 4,
    name: "FAILED",
  },
];
