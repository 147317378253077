/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Popover, Table } from "antd";
import React, { useState } from "react";
import { ReactComponent as DVLAMore } from "../../../assets/icons/dvla-more.svg";
import DVLAView from "../../../assets/icons/dvla-view.svg";
import "../styles.scss";
import RideDetails from "./RideDetails";
import { useSelector } from "react-redux";
import { formatDate } from "../../../utils/constants";

const RidesTable = ({
  paginationData,
  setPaginationData,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [currentRow, setCurrentRow] = useState(null);
  const [openInfo, setOpenInfo] = useState(false);
  const { rides, loading } = useSelector((state) => state?.rides);
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [ride, setRide] = useState(null);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const columns = [
    {
      title: "COMPANY",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "DRIVER's NAME",
      dataIndex: "driverName",
      key: "driverName",
    },
    // {
    //   title: "RIDE IDENTIFIER",
    //   dataIndex: "rideIdentifier",
    //   key: "rideIdentifier",
    // },
    {
      title: "VEHICLE REG.",
      dataIndex: "vehicleReg",
      key: "vehicleReg",
    },
    // {
    //   title: "START POINT",
    //   dataIndex: "startPoint",
    //   key: "startPoint",
    // },
    // {
    //   title: "END POINT",
    //   dataIndex: "endPoint",
    //   key: "endPoint",
    // },
    // {
    //   title: "PSGR. NAME",
    //   dataIndex: "psgrName",
    //   key: "psgrName",
    // },
    {
      title: "RIDE FARE (NGN)",
      dataIndex: "rideFare",
      key: "rideFare",
    },
    {
      title: "RIDE STARTS AT",
      dataIndex: "rideStartTimestamp",
      key: "rideStartTimestamp",
    },
    {
      title: "RIDE ENDS AT",
      dataIndex: "rideEndTimestamp",
      key: "rideEndTimestamp",
    },
    {
      title: "M",
      key: "operation",
      render: (record) => (
        <Popover
          content={content}
          trigger="click"
          open={open && currentRow === record?.key}
          placement="bottomLeft"
          onOpenChange={(e) => {
            handleOpenChange(e, record);
          }}
        >
          <div className="cursor-pointer">
            <DVLAMore />
          </div>
        </Popover>
      ),
    },
  ];

  const handleMore = () => {
    handleOpenInfoChange(true);
    hide();
  };

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen, record) => {
    setOpen(newOpen);
    setCurrentRow(record?.key);
    setRide(rides?.content[record.key])
  };

  const handleOpenInfoChange = (newOpen) => {
    setOpenInfo(newOpen);
  };

  const data = rides &&
    rides?.content?.length > 0 &&
    rides?.content?.map((row, index) => {
      return {
        key: index,
        id: row?.id,
        name: row?.business?.name,
        driverName: `${row?.driver?.firstName ?? "---"} ${row?.driver?.lastName ?? "---"}`,
        // rideIdentifier: row?.rideIdentifier,
        vehicleReg: row?.vehicle?.licensePlateNumber ?? "---",
        // startPoint: <div>
        //   <span>{row?.departureCoordinates?.longitude},</span>
        //   <br />
        //   <span>{row?.departureCoordinates?.latitude}</span>
        // </div>,
        // endPoint: <div>
        //   <span>{row?.arrivalCoordinates?.longitude},</span>
        //   <br />
        //   <span>{row?.arrivalCoordinates?.latitude}</span>
        // </div>,
        // psgrName: row?.passgName,
        rideFare: row?.fare?.toLocaleString() ?? "---",
        rideStartTimestamp: row?.rideStartTimestamp ? formatDate(row?.rideStartTimestamp) : "-",
        rideEndTimestamp: row?.rideEndTimestamp ? formatDate(row?.rideEndTimestamp) : "-",
      };
    });

  const content = (
    <div>
      <div
        className="flex gap-x-4 items-center cursor-pointer text-labels"
        onClick={handleMore}
      >
        <img src={DVLAView} alt={"dvla-view"} className={"h-4"} />
        <div>View ride details</div>
      </div>
    </div>
  );

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <a href={"#/"} className={"px-2 font-medium text-sm-regular"}>
          {"Prev"}
        </a>
      );
    }
    if (type === "next") {
      return (
        <a href={"#/"} className={"px-2 font-medium text-sm-regular"}>
          {"Next"}
        </a>
      );
    }
    return originalElement;
  };

  const infoContent = (
    <RideDetails handleClose={() => handleOpenInfoChange(false)} ride={ride} />
  );

  const onShowSizeChange = (current, pageSize) => {
    if (pageSize || current) {
      setDefaultPageSize(pageSize);
      setDefaultCurrent(current - 1);
    } else {
      setDefaultPageSize(10);
      setDefaultCurrent(1);
    }
  };

  const paginationChangeHandler = (current, pageSize) => {
    setPaginationData({
      ...paginationData,
      page: current - 1,
      size: pageSize,
    });
  };

  return (
    <div className="w-full px-10 bg-white mt-6 ride-table-shadow pb-4">
      <div style={{ marginBottom: 16 }}>
        <span style={{ marginLeft: 8 }}>
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
        </span>
      </div>
      <Table
        loading={loading}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        size={"small"}
        className={"custom-table"}
        pagination={{
          pageSizeOptions: ["10", "20", "30", "40"],
          showSizeChanger: true,
          onShowSizeChange: onShowSizeChange,
          defaultCurrent: defaultCurrent,
          total: rides?.totalElements,
          pageSize: defaultPageSize,
          showTotal: (total) => `Total ${total} items`,
          defaultPageSize: defaultPageSize,
          onChange: paginationChangeHandler,
          itemRender,
        }}
      />
      <Modal
        open={openInfo}
        closable={false}
        footer={null}
        onCancel={() => handleOpenInfoChange(false)}
        className={"ride-modal"}
        width={400}
      >
        {infoContent}
      </Modal>
    </div>
  );
};

export default RidesTable;
