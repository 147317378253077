/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Popover, Table } from "antd";
import React, { useState } from "react";
import { ReactComponent as DVLAMore } from "../../../assets/icons/dvla-more.svg";
import DVLAView from "../../../assets/icons/dvla-view.svg";
import DVLADownload from "../../../assets/icons/dvla-download.svg";
import "../styles.scss";
import InvoiceDetails from "./InvoiceDetails";
import InvoiceStatusUpdate from "./InvoiceStatusUpdate";
import { useSelector } from "react-redux";
import { download, formatDate, numberWithCommas } from "../../../utils/constants";

const STATUS_DUE = "px-2 py-1 bg-[#fcefee] text-[#E53838] rounded-md";
const STATUS_PAID = "px-2 py-1 bg-[#edfaf3] text-[#23BE65] rounded-md";
const STATUS_SENT = "px-2 py-1 bg-[#fef6ee] text-[#FA8C2A] rounded-md";

const InvoicesTable = ({ paginationData, setPaginationData, updateInvoive }) => {
  const { invoices, loading } = useSelector((state) => state?.invoices);
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(7);
  const [open, setOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [openInfo, setOpenInfo] = useState(false);
  const [openStatusUpdate, setOpenStatusUpdate] = useState(false);
  const [updateType, setUpdateType] = useState("");
  const [invoiveId, setInvoiceId] = useState("");
  const [invoice, setInvoice] = useState({});

  const columns = [
    {
      title: "INVOICE NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "DATE SENT",
      dataIndex: "createDate",
      key: "createDate",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "DATE DUE",
      dataIndex: "dueByDate",
      key: "dueByDate",
    },
    {
      title: "AMOUNT",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "M",
      key: "operation",
      render: (record) => (
        <Popover
          content={content}
          trigger="click"
          open={open && currentRow === record?.key}
          placement="bottomLeft"
          onOpenChange={(e) => {
            handleOpenChange(e, record);
          }}
        >
          <div className="cursor-pointer">
            <DVLAMore />
          </div>
        </Popover>
      ),
    },
  ];

  const handleMore = () => {
    handleOpenInfoChange(true);
    hide();
  };

  const handleDownload = () => {
    download(invoice?.downloadUrl);
    hide();
  };

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen, record) => {
    setOpen(newOpen);
    setCurrentRow(record?.key);
    setInvoice(invoices?.content?.find(inv => inv?.id === record?.id))
  };

  const handleOpenInfoChange = (newOpen) => {
    setOpenInfo(newOpen);
  };

  const handleOpenStatusUpdateChange = (newOpen) => {
    setOpenStatusUpdate(newOpen);
  };

  const handleUpdate = (id, update) => {
    handleOpenInfoChange(false);
    setInvoiceId(id);
    setUpdateType(update);
    handleOpenStatusUpdateChange(true);
  };

  const handleConfirm = () => {
    handleOpenStatusUpdateChange(false);
    updateInvoive(invoiveId, updateType)
  };

  const data = invoices &&
    invoices?.content?.length > 0 &&
    invoices?.content?.map((invoice, index) => {
      return {
        key: index,
        id: invoice?.id,
        name: (
          <div>
            <div>{invoice?.name}</div>
            <div className="mt-2 text-[#878682]">sent to {invoice?.business?.name}</div>
          </div>
        ),
        createDate: formatDate(invoice?.createDate),
        status: (
          <span
            className={
              invoice?.status === "OVERDUE"
                ? STATUS_DUE
                : invoice?.status === "PAID"
                  ? STATUS_PAID
                  : STATUS_SENT
            }
          >
            {invoice?.status}
          </span>
        ),
        dueByDate: formatDate(invoice?.dueByDate),
        amount: <span>NGN {numberWithCommas(invoice?.amount)}</span>,
      };
    });

  const content = (
    <div>
      <div
        className="flex gap-x-4 items-center cursor-pointer text-labels"
        onClick={handleMore}
      >
        <img src={DVLAView} alt={"dvla-view"} className={"h-4"} />
        <div>View details</div>
      </div>
      <div
        className="flex gap-x-4 items-center cursor-pointer text-labels mt-4"
        onClick={handleDownload}
      >
        <img src={DVLADownload} alt={"dvla-download"} className={"h-4"} />
        <div>Download invoice</div>
      </div>
    </div>
  );

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <a href={"#/"} className={"px-2 font-medium text-sm-regular"}>
          {"Prev"}
        </a>
      );
    }
    if (type === "next") {
      return (
        <a href={"#/"} className={"px-2 font-medium text-sm-regular"}>
          {"Next"}
        </a>
      );
    }
    return originalElement;
  };

  const infoContent = (
    <InvoiceDetails
      handleClose={() => handleOpenInfoChange(false)}
      handleUpdate={handleUpdate}
      invoice={invoice}
    />
  );

  const statusContent = (
    <InvoiceStatusUpdate
      handleClose={() => handleOpenStatusUpdateChange(false)}
      updateType={updateType}
      handleConfirm={handleConfirm}
    />
  );

  const onShowSizeChange = (current, pageSize) => {
    if (pageSize || current) {
      setDefaultPageSize(pageSize);
      setDefaultCurrent(current - 1);
    } else {
      setDefaultPageSize(10);
      setDefaultCurrent(1);
    }
  };

  const paginationChangeHandler = (current, pageSize) => {
    setPaginationData({
      ...paginationData,
      page: current - 1,
      size: pageSize,
    });
  };

  return (
    <div className="w-full px-8 bg-white mt-6 ride-table-shadow pb-4 pt-8">
      <Table
        loading={loading}
        columns={columns}
        dataSource={data}
        size={"small"}
        className={"custom-table"}
        pagination={{
          pageSizeOptions: ["7", "14", "21", "28", "35"],
          showSizeChanger: true,
          onShowSizeChange: onShowSizeChange,
          defaultCurrent: defaultCurrent,
          total: invoices?.totalElements,
          pageSize: defaultPageSize,
          showTotal: (total) => `Total ${total} items`,
          defaultPageSize: defaultPageSize,
          onChange: paginationChangeHandler,
          itemRender,
        }}
      />
      <Modal
        open={openInfo}
        closable={false}
        footer={null}
        onCancel={() => handleOpenInfoChange(false)}
        className={"invoice-modal"}
        width={450}
      >
        {infoContent}
      </Modal>

      <Modal
        open={openStatusUpdate}
        closable={false}
        footer={null}
        onCancel={() => handleOpenStatusUpdateChange(false)}
        className={"invoice-modal"}
        width={450}
      >
        {statusContent}
      </Modal>
    </div>
  );
};

export default InvoicesTable;
