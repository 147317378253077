/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ReactComponent as DVLACornerUpLeft } from "../../../assets/icons/corner-up-left.svg";
import "../styles.scss";
import MainLayout from "../../../layouts/MainLayout";
import { goBack } from "../../../utils/constants";
import UserDetails from "./UserDetails";
import { useParams } from "react-router-dom";

const AccountInfo = () => {
  const params = useParams();
  const [id, setId] = useState(null);

  useEffect(() => {
    if (params?.id) {
      setId(params?.id);
    } else {
      goBack();
    }
  }, [params]);
  return (
    <MainLayout title={"User"}>
      <div>
        <div className="text-left mb-6 lg:mb-8 w-full">
          <div
            className="flex gap-x-2 bg-primary-100 text-primary-500 font-semibold rounded-lg p-2 text-labels hover:shadow-md cursor-pointer transition ease-in-out duration-300"
            style={{ width: "fit-content" }}
            onClick={goBack}
          >
            <DVLACornerUpLeft />
            Back to search results
          </div>
        </div>
        <UserDetails id={id} />
      </div>
    </MainLayout>
  );
};

export default AccountInfo;
