import React from "react";
import { ReactComponent as DVLAClose } from "../../../assets/icons/dvla-close.svg";
import { ReactComponent as DVLADocument } from "../../../assets/icons/dvla-document.svg";
import Button from "../../../components/Buttons/Button";
import { UploadOutlined } from "@ant-design/icons";
import * as yup from "yup";
import { Field, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Form, Select, Upload, Button as Btn, notification, Input, } from "antd";
import FieldContainer from "../../../components/Inputs/FIeldContainer";
import FloatLabel from "../../../components/Inputs/FloatLabel";
import { localISOTime } from "../../../utils/constants";
import { UploadDriverDocument } from "../../../redux/actions/drivers.action";

const values = {
    file: null,
    documentName: "",
    documentNumber: "",
    expiryDate: "",
    status: "",
};

const UploadDocument = ({ handleClose, handleUpdateAndRefresh, id }) => {
    const [form] = Form.useForm();
    const loader = useSelector((state) => state.drivers.loading);
    const dispatch = useDispatch();

    const performUpload = (values, { resetForm }) => {
        const formData = new FormData();
        const data = {
            documentName: values?.documentName,
            documentNumber: values?.documentNumber,
            expiryDate: localISOTime(new Date(values?.expiryDate)),
            status: values?.status
        }
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        formData.append("file", values?.file);
        formData.append("document", JSON.stringify(data));
        dispatch(UploadDriverDocument(formData, config, id))
            .then(async (res) => {
                notification.success({
                    message: "Success",
                    description: "Document Uploaded Successfully!",
                });
                resetForm();
                form.resetFields();
                handleUpdateAndRefresh();
            })
            .catch((err) => {
                notification.error({
                    message: "Error",
                    description: err.response.data.errorMsg
                        ? err.response.data.errorMsg
                        : "An error has ocurred, please try again later",
                });
            });
    };

    return (
        <div className="w-full">
            <div className="flex justify-between w-full items-center mb-4">
                <div className="flex items-center gap-x-3">
                    <DVLADocument />
                    <div className="flex gap-x-4 items-center cursor-pointer py-2 text-sm-regular font-semibold">
                        Upload Document
                    </div>
                </div>
                <div className="flex items-center gap-x-3">
                    <div
                        className="p-1 w-6 h-6 flex items-center justify-center rounded-full dvla-close cursor-pointer"
                        onClick={handleClose}
                    >
                        <DVLAClose />
                    </div>
                </div>
            </div>
            <div
                className="text-labels"
            >
                <Formik
                    className="w-full"
                    initialValues={values}
                    onSubmit={performUpload}
                    enableReinitialize={true}
                    validationSchema={validator}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    }) => (
                        <Form onFinish={handleSubmit} form={form}>
                            <div className={"mb-4 text-left"}>
                                <div
                                    className={
                                        "text-left font-normal text-sm-regular mb-2"
                                    }
                                >
                                    <p>Document</p>
                                </div>
                                <FieldContainer>
                                    <Form.Item
                                        name={"file"}
                                        help={touched.file && errors.file ? errors.file : ""}
                                        validateStatus={
                                            touched.file && errors.file ? "error" : "success"
                                        }
                                        valuePropName={"file"}
                                    >
                                        <Field>
                                            {({ form: { setFieldValue } }) => (
                                                <Upload
                                                    beforeUpload={(file) => {
                                                        setFieldValue("file", file)
                                                        return false;
                                                    }}
                                                    onChange={(e) => {
                                                        if (e?.fileList?.length === 0) {
                                                            setFieldValue("file", null);
                                                        }
                                                    }}
                                                    multiple={false}
                                                    listType={"picture"}
                                                    className={"w-full upload"}
                                                    maxCount={1}
                                                    accept={"image/*"}
                                                >
                                                    <Btn
                                                        className={
                                                            "w-full h-[3rem] flex items-center justify-center"
                                                        }
                                                        icon={<UploadOutlined />}
                                                    >
                                                        Browse
                                                    </Btn>
                                                </Upload>
                                            )}
                                        </Field>
                                    </Form.Item>
                                </FieldContainer>
                            </div>
                            <div className={"mb-4"}>
                                <FieldContainer iconPlacementCss={"iconCss"}>
                                    <FloatLabel
                                        label="Document Name"
                                        value={values?.documentName || ""}
                                    >
                                        <Form.Item
                                            name={"documentName"}
                                            help={
                                                touched.documentName && errors.documentName
                                                    ? errors.documentName
                                                    : ""
                                            }
                                            validateStatus={
                                                touched.documentName && errors.documentName
                                                    ? "error"
                                                    : "success"
                                            }
                                        >
                                            <Field>
                                                {({ form: { setFieldValue } }) => (
                                                    <Select
                                                        value={values?.documentName || ""}
                                                        onBlur={handleBlur}
                                                        className={"form-field_input_2"}
                                                        onChange={(e) => {
                                                            setFieldValue("documentName", e);
                                                        }}
                                                        options={[
                                                            { value: "International Passport", label: "International Passport" },
                                                            { value: "Drivers License", label: "Drivers License" },
                                                            { value: "Insurance", label: "Insurance" },
                                                            { value: "Registration Certificate", label: "Registration Certificate" },
                                                        ]}
                                                    />
                                                )}
                                            </Field>
                                        </Form.Item>
                                    </FloatLabel>
                                </FieldContainer>
                            </div>
                            <div className={"mb-4"}>
                                <FieldContainer iconPlacementCss={"iconCss"}>
                                    <FloatLabel
                                        label="Document Number"
                                        value={values?.documentNumber || ""}
                                    >
                                        <Form.Item
                                            name={"documentNumber"}
                                            help={
                                                touched.documentNumber && errors.documentNumber
                                                    ? errors.documentNumber
                                                    : ""
                                            }
                                            validateStatus={
                                                touched.documentNumber && errors.documentNumber
                                                    ? "error"
                                                    : "success"
                                            }
                                        >
                                            <Field>
                                                {({ form: { setFieldValue } }) => (
                                                    <Input
                                                        type={"text"}
                                                        className={"form-field_input_2"}
                                                        value={values?.documentNumber || ""}
                                                        onChange={(e) => {
                                                            setFieldValue("documentNumber", e.target.value);
                                                        }}
                                                        onBlur={(e) => {
                                                            setFieldValue("documentNumber", e.target.value);
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </Form.Item>
                                    </FloatLabel>
                                </FieldContainer>
                            </div>
                            <div className={"mb-4"}>
                                <FieldContainer iconPlacementCss={"iconCss"}>
                                    <FloatLabel
                                        label="Expiry Date"
                                        value={values?.expiryDate || ""}
                                    >
                                        <Form.Item
                                            name={"expiryDate"}
                                            help={
                                                touched.expiryDate && errors.expiryDate
                                                    ? errors.expiryDate
                                                    : ""
                                            }
                                            validateStatus={
                                                touched.expiryDate && errors.expiryDate
                                                    ? "error"
                                                    : "success"
                                            }
                                        >
                                            <Field>
                                                {({ form: { setFieldValue } }) => (
                                                    <DatePicker
                                                        showTime
                                                        className={"form-field_input_2"}
                                                        placeholder={""}
                                                        value={values?.expiryDate || ""}
                                                        onChange={(e) => {
                                                            setFieldValue("expiryDate", e);
                                                        }}
                                                        onOk={(e) => {
                                                            setFieldValue("expiryDate", e)
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </Form.Item>
                                    </FloatLabel>
                                </FieldContainer>
                            </div>
                            <div className={"mb-4"}>
                                <FieldContainer iconPlacementCss={"iconCss"}>
                                    <FloatLabel
                                        label="Status"
                                        value={values?.status || ""}
                                    >
                                        <Form.Item
                                            name={"status"}
                                            help={
                                                touched.status && errors.status
                                                    ? errors.status
                                                    : ""
                                            }
                                            validateStatus={
                                                touched.status && errors.status
                                                    ? "error"
                                                    : "success"
                                            }
                                        >
                                            <Field>
                                                {({ form: { setFieldValue } }) => (
                                                    <Select
                                                        value={values?.status || ""}
                                                        onBlur={handleBlur}
                                                        className={"form-field_input_2"}
                                                        onChange={(e) => {
                                                            setFieldValue("status", e);
                                                        }}
                                                        options={[
                                                            { value: "PENDING", label: "Pending" },
                                                            { value: "VERIFIED", label: "Verified" },
                                                            { value: "FAILED", label: "Failed" },
                                                        ]}
                                                    />
                                                )}
                                            </Field>
                                        </Form.Item>
                                    </FloatLabel>
                                </FieldContainer>
                            </div>
                            <div className={"flex justify-center"}>
                                <Button
                                    css={"w-full"}
                                    state={"PRIMARY"}
                                    text={"Upload"}
                                    type={"submit"}
                                    loading={loader}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

const validator = yup.object().shape({
    file: yup.mixed().required("Document is required"),
    documentName: yup
        .string()
        .required("Document name is required"),
    documentNumber: yup
        .string()
        .min(3, "Document number should be minimum of 3 characters")
        .required("Document number is required"),
    expiryDate: yup
        .string()
        .required("Expiry date is required"),
    status: yup
        .string()
        .required("Status is required"),
});

export default UploadDocument;
