import {
  exportRides,
  getAdminRides,
  getBusinessRides,
  getBusinessRidesDailyTrends,
  getBusinessRidesMonthlyTrends,
  getRide,
  getRideBatches,
  getRideSummary,
  uploadRideBatch,
} from "../_api/index";

export const Types = {
  ALL_ADMIN_RIDES_START: "ALL_ADMIN_RIDES_START",
  ALL_ADMIN_RIDES_SUCCESS: "ALL_ADMIN_RIDES_SUCCESS",
  ALL_ADMIN_RIDES_END: "ALL_ADMIN_RIDES_END",

  BUSINESS_RIDES_START: "BUSINESS_RIDES_START",
  BUSINESS_RIDES_SUCCESS: "BUSINESS_RIDES_SUCCESS",
  BUSINESS_RIDES_END: "BUSINESS_RIDES_END",

  RIDE_START: "RIDE_START",
  RIDE_SUCCESS: "RIDE_SUCCESS",
  RIDE_END: "RIDE_END",

  EXPORT_RIDE_START: "EXPORT_RIDE_START",
  EXPORT_RIDE_SUCCESS: "EXPORT_RIDE_SUCCESS",
  EXPORT_RIDE_END: "EXPORT_RIDE_END",

  BUSINESS_RIDES_MONTHLY_TRENDS_START: "BUSINESS_RIDES_MONTHLY_TRENDS_START",
  BUSINESS_RIDES_MONTHLY_TRENDS_SUCCESS:
    "BUSINESS_RIDES_MONTHLY_TRENDS_SUCCESS",
  BUSINESS_RIDES_MONTHLY_TRENDS_END: "BUSINESS_RIDES_MONTHLY_TRENDS_END",

  BUSINESS_RIDES_DAILY_TRENDS_START: "BUSINESS_RIDES_DAILY_TRENDS_START",
  BUSINESS_RIDES_DAILY_TRENDS_SUCCESS: "BUSINESS_RIDES_DAILY_TRENDS_SUCCESS",
  BUSINESS_RIDES_DAILY_TRENDS_END: "BUSINESS_RIDES_DAILY_TRENDS_END",

  UPLOAD_RIDE_BATCH_START: "UPLOAD_RIDE_BATCH_START",
  UPLOAD_RIDE_BATCH_SUCCESS: "UPLOAD_RIDE_BATCH_SUCCESS",
  UPLOAD_RIDE_BATCH_END: "UPLOAD_RIDE_BATCH_END",

  RIDE_BATCHES_START: "RIDE_BATCHES_START",
  RIDE_BATCHES_SUCCESS: "RIDE_BATCHES_SUCCESS",
  RIDE_BATCHES_END: "RIDE_BATCHES_END",

  RIDE_SUMMARY_START: "RIDE_SUMMARY_START",
  RIDE_SUMMARY_SUCCESS: "RIDE_SUMMARY_SUCCESS",
  RIDE_SUMMARY_END: "RIDE_SUMMARY_END",
};

export const FetchAdminRides = (param) => (dispatch) => {
  dispatch({ type: Types.ALL_ADMIN_RIDES_START });
  return new Promise((resolve, reject) => {
    getAdminRides(param)
      .then((res) => {
        dispatch({
          type: Types.ALL_ADMIN_RIDES_SUCCESS,
          rides: res?.data?.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.ALL_ADMIN_RIDES_END });
        reject(err);
      });
  });
};

export const FetchBusinessRides = (id, param) => (dispatch) => {
  dispatch({ type: Types.BUSINESS_RIDES_START });
  return new Promise((resolve, reject) => {
    getBusinessRides(id, param)
      .then((res) => {
        dispatch({
          type: Types.BUSINESS_RIDES_SUCCESS,
          rides: res?.data?.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.BUSINESS_RIDES_END });
        reject(err);
      });
  });
};

export const FetchRide = (id) => (dispatch) => {
  dispatch({ type: Types.RIDE_START });
  return new Promise((resolve, reject) => {
    getRide(id)
      .then((res) => {
        dispatch({
          type: Types.RIDE_SUCCESS,
          ride: res?.data?.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.RIDE_END });
        reject(err);
      });
  });
};

export const ExportRides = (param) => (dispatch) => {
  dispatch({ type: Types.EXPORT_RIDE_START });
  return new Promise((resolve, reject) => {
    exportRides(param)
      .then((res) => {
        dispatch({
          type: Types.EXPORT_RIDE_SUCCESS,
          export_ride: res?.data,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.EXPORT_RIDE_END });
        reject(err);
      });
  });
};

export const FetchRidesMonthlyTrends = (id, param) => (dispatch) => {
  dispatch({ type: Types.BUSINESS_RIDES_MONTHLY_TRENDS_START });
  return new Promise((resolve, reject) => {
    getBusinessRidesMonthlyTrends(id, param)
      .then((res) => {
        dispatch({
          type: Types.BUSINESS_RIDES_MONTHLY_TRENDS_SUCCESS,
          monthlyTrends: res?.data?.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.BUSINESS_RIDES_MONTHLY_TRENDS_END });
        reject(err);
      });
  });
};

export const FetchRidesDailyTrends = (id, param) => (dispatch) => {
  dispatch({ type: Types.BUSINESS_RIDES_DAILY_TRENDS_START });
  return new Promise((resolve, reject) => {
    getBusinessRidesDailyTrends(id, param)
      .then((res) => {
        dispatch({
          type: Types.BUSINESS_RIDES_DAILY_TRENDS_SUCCESS,
          dailyTrends: res?.data?.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.BUSINESS_RIDES_DAILY_TRENDS_END });
        reject(err);
      });
  });
};

export const UploadRideBatch = (formData, config, note) => (dispatch) => {
  dispatch({ type: Types.UPLOAD_RIDE_BATCH_START });
  return new Promise((resolve, reject) => {
    uploadRideBatch(formData, config, note)
      .then((res) => {
        dispatch({
          type: Types.UPLOAD_RIDE_BATCH_SUCCESS,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.UPLOAD_RIDE_BATCH_END });
        reject(err);
      });
  });
};

export const FetchRideBatches = (filter) => (dispatch) => {
  dispatch({ type: Types.RIDE_BATCHES_START });
  return new Promise((resolve, reject) => {
    getRideBatches(filter)
      .then((res) => {
        dispatch({
          type: Types.RIDE_BATCHES_SUCCESS,
          rideBatches: res?.data?.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.RIDE_BATCHES_END });
        reject(err);
      });
  });
};

export const FetchRideSummary = (filter) => (dispatch) => {
  dispatch({ type: Types.RIDE_SUMMARY_START });
  return new Promise((resolve, reject) => {
    getRideSummary(filter)
      .then((res) => {
        dispatch({
          type: Types.RIDE_SUMMARY_SUCCESS,
          rideSummary: res.data.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.RIDE_SUMMARY_END });
        reject(err);
      });
  });
};
