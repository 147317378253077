/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Form, Input, notification } from "antd";
import MainLayout from "../../../layouts/MainLayout";
import "../styles.scss";
import FloatLabel from "../../../components/Inputs/FloatLabel";
import PasswordField from "../../../components/Inputs/PasswordField";
import Button from "../../../components/Buttons/Button";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { ChangeUserPassword } from "../../../redux/actions/auths.action";

const values = {
    password: "",
    newPassword: "",
    confirmPassword: "",
};

const ChangePassword = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const loader = useSelector((state) => state?.auths?.loading);

    const changePassword = (values, { resetForm }) => {
        const user = JSON.parse(localStorage.getItem("dvla_user"));
        const data = { ...values, email: user?.email };
        dispatch(ChangeUserPassword(data))
            .then(async (res) => {
                notification.success({
                    message: "Success",
                    description: "Password Changed Successfully!",
                });
                resetForm();
                form.resetFields();
            })
            .catch((err) => {
                notification.error({
                    message: "Error",
                    description: err.response.data.errorMsg
                        ? err.response.data.errorMsg
                        : "An error has ocurred, please try again later",
                });
            });
    };

    return (
        <MainLayout title={"Dashboard"}>
            <div className="w-full info-card bg-white px-8 py-6 flex flex-col gap-y-2">
                <h3 className="text-secondary-300 font-semibold text-[18px] border-b pb-4 border-b-neutral-100 border-b-[0.5px]">
                    Change Password
                </h3>
                <div className="w-full flex items-center justify-center">
                    <div className="w-1/2 my-10">
                        <Formik
                            className="w-full"
                            initialValues={values}
                            onSubmit={changePassword}
                            enableReinitialize={true}
                            validationSchema={validator}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                            }) => (
                                <Form onFinish={handleSubmit} form={form}>
                                    <div className={"mb-4"}>
                                        <PasswordField
                                            iconPlacementCss={"iconCss"}
                                        >
                                            <FloatLabel label="Password" value={values?.password || ""}>
                                                <Form.Item
                                                    name={"password"}
                                                    help={
                                                        touched.password && errors.password
                                                            ? errors.password
                                                            : ""
                                                    }
                                                    validateStatus={
                                                        touched.password && errors.password
                                                            ? "error"
                                                            : "success"
                                                    }
                                                >
                                                    <Input
                                                        type={"password"}
                                                        className={"form-field_input_2"}
                                                        value={values?.password || ""}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Form.Item>
                                            </FloatLabel>
                                        </PasswordField>
                                    </div>
                                    <div className={"mb-4"}>
                                        <PasswordField
                                            iconPlacementCss={"iconCss"}
                                        >
                                            <FloatLabel label="New Password" value={values?.newPassword || ""}>
                                                <Form.Item
                                                    name={"newPassword"}
                                                    help={
                                                        touched.newPassword && errors.newPassword
                                                            ? errors.newPassword
                                                            : ""
                                                    }
                                                    validateStatus={
                                                        touched.newPassword && errors.newPassword
                                                            ? "error"
                                                            : "success"
                                                    }
                                                >
                                                    <Input
                                                        type={"password"}
                                                        className={"form-field_input_2"}
                                                        value={values?.newPassword || ""}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Form.Item>
                                            </FloatLabel>
                                        </PasswordField>
                                    </div>
                                    <div className={"mb-4"}>
                                        <PasswordField
                                            iconPlacementCss={"iconCss"}
                                        >
                                            <FloatLabel label="Confirm Password" value={values?.confirmPassword || ""}>
                                                <Form.Item
                                                    name={"confirmPassword"}
                                                    help={
                                                        touched.confirmPassword && errors.confirmPassword
                                                            ? errors.confirmPassword
                                                            : ""
                                                    }
                                                    validateStatus={
                                                        touched.confirmPassword && errors.confirmPassword
                                                            ? "error"
                                                            : "success"
                                                    }
                                                >
                                                    <Input
                                                        type={"password"}
                                                        className={"form-field_input_2"}
                                                        value={values?.confirmPassword || ""}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Form.Item>
                                            </FloatLabel>
                                        </PasswordField>
                                    </div>
                                    <div className={"flex justify-center"}>
                                        <Button
                                            css={"w-full"}
                                            state={"PRIMARY"}
                                            text={"Change Password"}
                                            type={"submit"}
                                            loading={loader}
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

const validator = yup.object().shape({
    password: yup.string().required("Password is required"),
    newPassword: yup
        .string()
        .min(8, "Password should be a minimum of 8 characters")
        .required("New password is required"),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm password is required"),
});

export default ChangePassword;
