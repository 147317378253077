import {
  createFlag,
  deleteFlag,
  getFlag,
  getFlags,
  updateFlag,
} from "../_api/index";

export const Types = {
  FLAGS_START: "FLAGS_START",
  FLAGS_SUCCESS: "FLAGS_SUCCESS",
  FLAGS_END: "FLAGS_END",

  NEW_FLAG_START: "NEW_FLAG_START",
  NEW_FLAG_SUCCESS: "NEW_FLAG_SUCCESS",
  NEW_FLAG_END: "NEW_FLAG_END",

  UPDATE_FLAG_START: "UPDATE_FLAG_START",
  UPDATE_FLAG_SUCCESS: "UPDATE_FLAG_SUCCESS",
  UPDATE_FLAG_END: "UPDATE_FLAG_END",

  FLAG_START: "FLAG_START",
  FLAG_SUCCESS: "FLAG_SUCCESS",
  FLAG_END: "FLAG_END",

  DELETE_FLAG_START: "DELETE_FLAG_START",
  DELETE_FLAG_SUCCESS: "DELETE_FLAG_SUCCESS",
  DELETE_FLAG_END: "DELETE_FLAG_END",
};

export const FetchFlags = (param) => (dispatch) => {
  dispatch({ type: Types.FLAGS_START });
  return new Promise((resolve, reject) => {
    getFlags(param)
      .then((res) => {
        dispatch({
          type: Types.FLAGS_SUCCESS,
          flags: res?.data?.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.FLAGS_END });
        reject(err);
      });
  });
};

export const CreateFlag = (req, driverId) => (dispatch) => {
  dispatch({ type: Types.NEW_FLAG_START });
  return new Promise((resolve, reject) => {
    createFlag(req, driverId)
      .then((res) => {
        dispatch({
          type: Types.NEW_FLAG_SUCCESS,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.NEW_FLAG_END });
        reject(err);
      });
  });
};

export const UpdateFlag = (req) => (dispatch) => {
  dispatch({ type: Types.UPDATE_FLAG_START });
  return new Promise((resolve, reject) => {
    updateFlag(req)
      .then((res) => {
        dispatch({
          type: Types.UPDATE_FLAG_SUCCESS,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.UPDATE_FLAG_END });
        reject(err);
      });
  });
};

export const FetchFlag = (flagId) => (dispatch) => {
  dispatch({ type: Types.FLAG_START });
  return new Promise((resolve, reject) => {
    getFlag(flagId)
      .then((res) => {
        dispatch({
          type: Types.FLAG_SUCCESS,
          flag: res?.data?.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.FLAG_END });
        reject(err);
      });
  });
};

export const DeleteFlag = (flagId) => (dispatch) => {
  dispatch({ type: Types.DELETE_FLAG_START });
  return new Promise((resolve, reject) => {
    deleteFlag(flagId)
      .then((res) => {
        dispatch({
          type: Types.DELETE_FLAG_SUCCESS,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.DELETE_FLAG_END });
        reject(err);
      });
  });
};
