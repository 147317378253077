import React, { useEffect, useState } from "react";
import { ReactComponent as DVLAClose } from "../../../assets/icons/dvla-close.svg";
import { ReactComponent as DVLADocument } from "../../../assets/icons/dvla-document.svg";
import Button from "../../../components/Buttons/Button";
import * as yup from "yup";
import { Field, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Form, Select, notification, Input, } from "antd";
import FieldContainer from "../../../components/Inputs/FIeldContainer";
import FloatLabel from "../../../components/Inputs/FloatLabel";
import { localISOTime } from "../../../utils/constants";
import { CreateFlag, UpdateFlag } from "../../../redux/actions/flags.action";
import { FLAG_TYPES } from "../../../utils/misc/database";

const { TextArea } = Input;

const EditAndAddFlag = ({ handleClose, handleUpdateAndRefresh, id, flag }) => {
    const [form] = Form.useForm();
    const loader = useSelector((state) => state.drivers.loading);
    const [values, setValues] = useState({
        flagType: "",
        comment: "",
    })
    const dispatch = useDispatch();

    useEffect(() => {
        if (flag) {
            setValues({
                id: flag?.id,
                flagType: flag?.flagType,
                comment: flag.comment,
                dateAdded: flag.dateAdded
            })
        }
    }, [flag])

    const performUpload = (values, { resetForm }) => {
        if (flag) {
            const data = {
                ...values,
                dateAdded: localISOTime(new Date(flag?.dateAdded)),
            }
            dispatch(UpdateFlag(data))
                .then(async (res) => {
                    notification.success({
                        message: "Success",
                        description: "Flag Updated Successfully!",
                    });
                    resetForm();
                    form.resetFields();
                    handleUpdateAndRefresh();
                })
                .catch((err) => {
                    notification.error({
                        message: "Error",
                        description: err.response.data.errorMsg
                            ? err.response.data.errorMsg
                            : "An error has ocurred, please try again later",
                    });
                });
        } else {
            const data = {
                ...values,
                dateAdded: localISOTime(new Date(Date.now())),
            }
            dispatch(CreateFlag(data, id))
                .then(async (res) => {
                    notification.success({
                        message: "Success",
                        description: "Flag Added Successfully!",
                    });
                    resetForm();
                    form.resetFields();
                    handleUpdateAndRefresh();
                })
                .catch((err) => {
                    notification.error({
                        message: "Error",
                        description: err.response.data.errorMsg
                            ? err.response.data.errorMsg
                            : "An error has ocurred, please try again later",
                    });
                });
        }
    };

    return (
        <div className="w-full">
            <div className="flex justify-between w-full items-center mb-4">
                <div className="flex items-center gap-x-3">
                    <DVLADocument />
                    <div className="flex gap-x-4 items-center cursor-pointer py-2 text-sm-regular font-semibold">
                        Add Flag
                    </div>
                </div>
                <div className="flex items-center gap-x-3">
                    <div
                        className="p-1 w-6 h-6 flex items-center justify-center rounded-full dvla-close cursor-pointer"
                        onClick={handleClose}
                    >
                        <DVLAClose />
                    </div>
                </div>
            </div>
            <div
                className="text-labels"
            >
                <Formik
                    className="w-full"
                    initialValues={values}
                    onSubmit={performUpload}
                    enableReinitialize={true}
                    validationSchema={validator}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    }) => (
                        <Form onFinish={handleSubmit} form={form}>
                            <div className={"mb-4"}>
                                <FieldContainer iconPlacementCss={"iconCss"}>
                                    <FloatLabel
                                        label="Flag Name"
                                        value={values?.flagType || ""}
                                    >
                                        <Form.Item
                                            name={"flagType"}
                                            help={
                                                touched.flagType && errors.flagType
                                                    ? errors.flagType
                                                    : ""
                                            }
                                            validateStatus={
                                                touched.flagType && errors.flagType
                                                    ? "error"
                                                    : "success"
                                            }
                                        >
                                            <Field>
                                                {({ form: { setFieldValue } }) => (
                                                    <Select
                                                        value={values?.flagType || ""}
                                                        onBlur={handleBlur}
                                                        className={"form-field_input_2"}
                                                        onChange={(e) => {
                                                            setFieldValue("flagType", e);
                                                        }}
                                                        options={FLAG_TYPES}
                                                    />
                                                )}
                                            </Field>
                                        </Form.Item>
                                    </FloatLabel>
                                </FieldContainer>
                            </div>
                            <div className={"mb-4"}>
                                <FieldContainer iconPlacementCss={"iconCss"}>
                                    <FloatLabel
                                        label="Comment"
                                        value={values?.comment || ""}
                                    >
                                        <Form.Item
                                            name={"comment"}
                                            help={
                                                touched.comment && errors.comment
                                                    ? errors.comment
                                                    : ""
                                            }
                                            validateStatus={
                                                touched.comment && errors.comment
                                                    ? "error"
                                                    : "success"
                                            }
                                        >
                                            <Field>
                                                {({ form: { setFieldValue } }) => (
                                                    <TextArea
                                                        type={"text"}
                                                        className={"form-field_input_2"}
                                                        value={values?.comment || ""}
                                                        onChange={(e) => {
                                                            setFieldValue("comment", e.target.value);
                                                        }}
                                                        onBlur={(e) => {
                                                            setFieldValue("comment", e.target.value);
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </Form.Item>
                                    </FloatLabel>
                                </FieldContainer>
                            </div>
                            <div className={"flex justify-center"}>
                                <Button
                                    css={"w-full"}
                                    state={"PRIMARY"}
                                    text={flag ? "Update Flag" : "Add Flag"}
                                    type={"submit"}
                                    loading={loader}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

const validator = yup.object().shape({
    flagType: yup
        .string()
        .required("Flag name is required"),
    comment: yup
        .string()
        .min(3, "Comment should be minimum of 3 characters")
        .required("Comment is required"),
});

export default EditAndAddFlag;
