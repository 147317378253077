/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "../styles.scss";
import { Formik } from "formik";
import FloatLabel from "../../../components/Inputs/FloatLabel";
import { Form, Input, notification } from "antd";
import Button from "../../../components/Buttons/Button";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import FieldContainer from "../../../components/Inputs/FIeldContainer";
import { ReactComponent as DVLACornerUpLeft } from "../../../assets/icons/corner-up-left.svg";
import MainLayout from "../../../layouts/MainLayout";
import { CreateBusiness, FetchBusinesses } from "../../../redux/actions/businesses.action";
import { localISOTime } from "../../../utils/constants";

const values = {
    name: "",
    corporateEmail: "",
    licenseNumber: "",
    address: "",
};

const NewCompany = () => {
    const [form] = Form.useForm();
    const loader = useSelector((state) => state.users.loading);
    const dispatch = useDispatch();

    const createUser = (values, { resetForm }) => {
        const data = { ...values, registrationDate: localISOTime(new Date(Date.now())), verificationStatus: "VERIFIED" }
        dispatch(CreateBusiness(data))
            .then(async (res) => {
                notification.success({
                    message: "Success",
                    description: "Partner Created Successfully!",
                });
                resetForm();
                form.resetFields();
                FetchBusinesses({})
            })
            .catch((err) => {
                notification.error({
                    message: "Error",
                    description: err.response.data.errorMsg
                        ? err.response.data.errorMsg
                        : "An error has ocurred, please try again later",
                });
            });
    };

    const goBack = () => {
        window.history.back();
    }

    return (
        <MainLayout title={"Partners"}>

            <div>
                <div className="text-left mb-6 lg:mb-8 w-full">
                    <div
                        className="flex gap-x-2 bg-primary-100 text-primary-500 font-semibold rounded-lg p-2 text-labels hover:shadow-md cursor-pointer transition ease-in-out duration-300"
                        style={{ width: "fit-content" }}
                        onClick={goBack}
                    >
                        <DVLACornerUpLeft />
                        Back
                    </div>
                </div>
                <div className="w-full info-card bg-white px-8 py-6 flex flex-col gap-y-2">
                    <h3 className="text-secondary-300 font-semibold text-[18px] border-b pb-4 border-b-neutral-100 border-b-[0.5px]">
                        Create New Partner
                    </h3>
                    <div className="w-full flex items-center justify-center">
                        <div className="w-1/2 my-10">
                            <Formik
                                className="w-full"
                                initialValues={values}
                                onSubmit={createUser}
                                enableReinitialize={true}
                                validationSchema={validator}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                }) => (
                                    <Form onFinish={handleSubmit} form={form}>
                                        <div className={"mb-4"}>
                                            <FieldContainer iconPlacementCss={"iconCss"}>
                                                <FloatLabel
                                                    label="Partner Name"
                                                    value={values?.name || ""}
                                                >
                                                    <Form.Item
                                                        name={"name"}
                                                        help={
                                                            touched.name && errors.name
                                                                ? errors.name
                                                                : ""
                                                        }
                                                        validateStatus={
                                                            touched.name && errors.name
                                                                ? "error"
                                                                : "success"
                                                        }
                                                    >
                                                        <Input
                                                            type={"text"}
                                                            className={"form-field_input_2"}
                                                            value={values?.name || ""}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </Form.Item>
                                                </FloatLabel>
                                            </FieldContainer>
                                        </div>
                                        <div className={"mb-4"}>
                                            <FieldContainer iconPlacementCss={"iconCss"}>
                                                <FloatLabel
                                                    label="Corporate Email"
                                                    value={values?.corporateEmail || ""}
                                                >
                                                    <Form.Item
                                                        name={"corporateEmail"}
                                                        help={
                                                            touched.corporateEmail && errors.corporateEmail
                                                                ? errors.corporateEmail
                                                                : ""
                                                        }
                                                        validateStatus={
                                                            touched.corporateEmail && errors.corporateEmail
                                                                ? "error"
                                                                : "success"
                                                        }
                                                    >
                                                        <Input
                                                            type={"email"}
                                                            className={"form-field_input_2"}
                                                            value={values?.corporateEmail || ""}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </Form.Item>
                                                </FloatLabel>
                                            </FieldContainer>
                                        </div>
                                        <div className={"mb-4"}>
                                            <FieldContainer iconPlacementCss={"iconCss"}>
                                                <FloatLabel
                                                    label="License Number"
                                                    value={values?.licenseNumber || ""}
                                                >
                                                    <Form.Item
                                                        name={"licenseNumber"}
                                                        help={
                                                            touched.licenseNumber && errors.licenseNumber
                                                                ? errors.licenseNumber
                                                                : ""
                                                        }
                                                        validateStatus={
                                                            touched.licenseNumber && errors.licenseNumber
                                                                ? "error"
                                                                : "success"
                                                        }
                                                    >
                                                        <Input
                                                            type={"text"}
                                                            className={"form-field_input_2"}
                                                            value={values?.licenseNumber || ""}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </Form.Item>
                                                </FloatLabel>
                                            </FieldContainer>
                                        </div>
                                        <div className={"mb-4"}>
                                            <FieldContainer iconPlacementCss={"iconCss"}>
                                                <FloatLabel
                                                    label="Address"
                                                    value={values?.address || ""}
                                                >
                                                    <Form.Item
                                                        name={"address"}
                                                        help={
                                                            touched.address && errors.address
                                                                ? errors.address
                                                                : ""
                                                        }
                                                        validateStatus={
                                                            touched.address && errors.address
                                                                ? "error"
                                                                : "success"
                                                        }
                                                    >
                                                        <Input
                                                            type={"text"}
                                                            className={"form-field_input_2"}
                                                            value={values?.address || ""}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </Form.Item>
                                                </FloatLabel>
                                            </FieldContainer>
                                        </div>
                                        <div className={"flex justify-center"}>
                                            <Button
                                                css={"w-full"}
                                                state={"PRIMARY"}
                                                text={"Create"}
                                                type={"submit"}
                                                loading={loader}
                                            />
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
};

const validator = yup.object().shape({
    name: yup
        .string()
        .min(3, "Partner name should be minimum of 3 characters")
        .required("Partner name is required"),
    corporateEmail: yup.string().email("Invalid email").required("Corporate email is required"),
    licenseNumber: yup
        .string()
        .min(3, "License number should be minimum of 3 characters")
        .required("License number is required"),
    address: yup
        .string()
        .min(10, "Address should be minimum of 10 characters")
        .required("Address is required"),
});

export default NewCompany;
