/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MainLayout from "../../../layouts/MainLayout";
import "../styles.scss";
import OptionDetails from "../Shared/OptionDetails";
import VerifyDriverAndVehicle from "../Shared/VerifyDriverAndVehicle";
import { useDispatch } from "react-redux";
import { FetchVehicles } from "../../../redux/actions/vehicles.action";
import { FetchDrivers } from "../../../redux/actions/drivers.action";
import { debounce } from "lodash";

const Cyclops = () => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState({ searchQuery: "" });
  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 5,
  });
  const [activeTab, setActiveTab] = useState(1)
  const [mode, setMode] = useState("");
  const [id, setId] = useState(null);
  const handleDriver = (id) => {
    setMode("DRIVER");
    setId(id);
  };

  const handleVehicle = (id) => {
    setMode("VEHICLE");
    setId(id);
  };

  useEffect(() => {
    activeTab === 1 && dispatch(FetchDrivers({ ...paginationData, ...query, }));
    activeTab === 2 && dispatch(FetchVehicles({ ...paginationData, ...query, }));
  }, [dispatch, query, activeTab, paginationData])

  const handleSearch = (value, tab) => {
    setActiveTab(tab);
    performSearch(value);
  };

  const performSearch = debounce((value) => {
    if (value.length > 0) {
      setQuery({ searchQuery: value });
    } else {
      setQuery({ searchQuery: "" });
    }
  }, 700);

  const reset = () => {
    setId(null);
    setMode("");
    setQuery({ searchQuery: "" })
    setPaginationData({
      page: 0,
      size: 5,
    })
  }
  return (
    <MainLayout title={"Cyclops"}>
      {mode && id ? (
        <OptionDetails
          mode={mode}
          id={id}
          goBack={() => {
            reset()
          }}
        />
      ) : (
        <VerifyDriverAndVehicle
          getDriver={handleDriver}
          getVehicle={handleVehicle}
          handleSearch={handleSearch}
          handleActiveTab={setActiveTab}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
        />
      )}
    </MainLayout>
  );
};

export default Cyclops;
