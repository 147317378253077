/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Popover, Table } from "antd";
import React, { useState } from "react";
import { ReactComponent as DVLAMore } from "../../../assets/icons/dvla-more.svg";
import DVLAView from "../../../assets/icons/dvla-view.svg";
import "../styles.scss";
import CompanyDetails from "./CompanyDetails";
import { useSelector } from "react-redux";
import { formatDate } from "../../../utils/constants";

const STATUS_PENDING = "px-2 py-1 bg-[#fef6ee] text-[#FA8C2A] rounded-md";
const STATUS_ACTIVE = "px-2 py-1 bg-[#edfaf3] text-[#23BE65] rounded-md";

const CompaniesTable = ({
  paginationData,
  setPaginationData,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [currentRow, setCurrentRow] = useState(null);
  const [openInfo, setOpenInfo] = useState(false);
  const {businesses, loading} = useSelector((state) => state?.businesses);
  const [business, setBusiness] = useState(null);
  const [defaultCurrent, setDefaultCurrent] = useState(1);
  const [defaultPageSize, setDefaultPageSize] = useState(10);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "ADDRESS",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "DATE CREATED",
      dataIndex: "dateCreated",
      key: "dateCreated",
    },
    {
      title: "LICENSE NO.",
      dataIndex: "licenseNumber",
      key: "licenseNumber",
    },
    {
      title: "REG. DATE",
      dataIndex: "registrationDate",
      key: "registrationDate",
    },
    {
      title: "STATUS",
      dataIndex: "verificationStatus",
      key: "verificationStatus",
    },
    {
      title: "M",
      key: "operation",
      render: (record) => (
        <Popover
          content={content}
          trigger="click"
          open={open && currentRow === record?.key}
          placement="bottomLeft"
          onOpenChange={(e) => {
            handleOpenChange(e, record);
          }}
        >
          <div className="cursor-pointer">
            <DVLAMore />
          </div>
        </Popover>
      ),
    },
  ];

  const handleMore = () => {
    handleOpenInfoChange(true);
    hide();
  };

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen, record) => {
    setBusiness(record);
    setOpen(newOpen);
    setCurrentRow(record?.key);
  };

  const handleOpenInfoChange = (newOpen) => {
    setOpenInfo(newOpen);
  };

  const data = businesses &&
    businesses?.content?.length > 0 &&
    businesses?.content?.map((row, index) => {
      return {
        key: index,
        id: row?.id,
        name: row?.name,
        address: row?.address,
        email: row?.corporateEmail,
        dateCreated: formatDate(row?.dateCreated),
        licenseNumber: row?.licenseNumber,
        registrationDate: formatDate(row?.registrationDate),
        status: row?.verificationStatus,
        verificationStatus: (
          <span
            className={
              row?.verificationStatus === "VERIFIED" ? STATUS_ACTIVE : STATUS_PENDING
            }
          >
            {row?.verificationStatus?.toString()?.toUpperCase() ?? "-"}
          </span>
        ),
      };
    });

  const content = (
    <div>
      <div
        className="flex gap-x-4 items-center cursor-pointer text-labels"
        onClick={handleMore}
      >
        <img src={DVLAView} alt={"dvla-view"} className={"h-4"} />
        <div>View details</div>
      </div>
    </div>
  );

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <a href={"#/"} className={"px-2 font-medium text-sm-regular"}>
          {"Prev"}
        </a>
      );
    }
    if (type === "next") {
      return (
        <a href={"#/"} className={"px-2 font-medium text-sm-regular"}>
          {"Next"}
        </a>
      );
    }
    return originalElement;
  };

  const infoContent = (
    <CompanyDetails handleClose={() => handleOpenInfoChange(false)} business={business} />
  );

  const onShowSizeChange = (current, pageSize) => {
    if (pageSize || current) {
      setDefaultPageSize(pageSize);
      setDefaultCurrent(current - 1);
    } else {
      setDefaultPageSize(10);
      setDefaultCurrent(1);
    }
  };

  const paginationChangeHandler = (current, pageSize) => {
    setPaginationData({
      ...paginationData,
      page: current - 1,
      size: pageSize,
    });
  };

  return (
    <div className="w-full px-10 bg-white mt-6 ride-table-shadow pb-4">
      <div style={{ marginBottom: 16 }}>
        <span style={{ marginLeft: 8 }}>
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
        </span>
      </div>
      <Table
      loading={loading}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        size={"small"}
        className={"custom-table"}
        pagination={{
          pageSizeOptions: ["10", "20", "30", "40"],
          showSizeChanger: true,
          onShowSizeChange: onShowSizeChange,
          defaultCurrent: defaultCurrent,
          total: businesses?.totalElements,
          pageSize: defaultPageSize,
          showTotal: (total) => `Total ${total} items`,
          defaultPageSize: defaultPageSize,
          onChange: paginationChangeHandler,
          itemRender,
        }}
      />
      <Modal
        open={openInfo}
        closable={false}
        footer={null}
        onCancel={() => handleOpenInfoChange(false)}
        className={"ride-modal"}
        width={400}
      >
        {infoContent}
      </Modal>
    </div>
  );
};

export default CompaniesTable;
