/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import AuthLayout from "../../../layouts/AuthLayout";
import AuthHeading from "../AuthHeading";
import DVLAPoweredBy from "../../../assets/imgs/dvla-powered-by.png";
import DVLAUber from "../../../assets/imgs/dvla-uber.png";
import DVLAinDrive from "../../../assets/imgs/dvla-indrive.png";
import DVLAYango from "../../../assets/imgs/dvla-yango.png";
import DVLABolt from "../../../assets/imgs/dvla-bolt.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FetchPartners } from "../../../redux/actions/auths.action";
import NameBadge from "./NameBadge";

const Partners = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const all_partners = useSelector((state) => state?.auths?.all_partners)

  const handleNavigation = (id) => {
    navigate(`/login/partner-login/${id}`);
  };

  useEffect(() => {
    dispatch(FetchPartners());
  }, [dispatch])

  return (
    <AuthLayout>
      <AuthHeading backButtonName={"Back to Home"} location={"PREV"} />
      <div className="px-4 sm:px-24 md:px-48 lg:px-12 xl:px-24 xl:max-w-2xl w-full flex flex-wrap justify-between gap-y-6">
        {all_partners?.map((p) => (
          <div
            key={p?.name}
            className="w-[48%] text-left card-shadow hover:shadow-xl transition ease-in-out duration-300 p-4 gap-y-6 flex text-labels items-center justify-between cursor-pointer"
            onClick={() => handleNavigation(p?.id)}
          >
            <span>{p?.name}</span>
            {p?.name?.toLowerCase() === "idrive"
              ? <img src={DVLAinDrive} alt={p?.name} className={"h-8"} /> : p?.name?.toLowerCase() === "yango"
                ? <img src={DVLAYango} alt={p?.name} className={"h-8"} /> : p?.name?.toLowerCase() === "uber"
                  ? <img src={DVLAUber} alt={p?.name} className={"h-8"} /> : p?.name?.toLowerCase() === "bolt rides"
                    ? <img src={DVLABolt} alt={p?.name} className={"h-8"} />
                    : <NameBadge name={p?.name} />}
          </div>
        ))}
        <img
          className={"h-4 mt-4"}
          src={DVLAPoweredBy}
          alt={"dvla-powered-by"}
        />
      </div>
    </AuthLayout>
  );
};

export default Partners;
