/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MainLayout from "../../../layouts/MainLayout";
import "../styles.scss";
import RidesFilters from "./RidesFilters";
import RidesTable from "./RidesTable";
import { useDispatch } from "react-redux";
import { ExportRides, FetchAdminRides, FetchBusinessRides } from "../../../redux/actions/rides.action";
import { debounce } from "lodash";
import { FetchBusinesses } from "../../../redux/actions/businesses.action";
import { download } from "../../../utils/constants";

const Rides = () => {
  const [query, setQuery] = useState({ searchQuery: "" });
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" })
  const user = JSON.parse(localStorage.getItem("dvla_user"));
  const [company, setCompany] = useState();
  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 10,
  });
  const handleSearch = (value) => {
    performSearch(value);
  };

  const performSearch = debounce((value) => {
    if (value.length > 0) {
      setQuery({ searchQuery: value });
    } else {
      setQuery({ searchQuery: "" });
    }
  }, 700);

  const dispatch = useDispatch();
  useEffect(() => {
    user?.isSiteAdmin ? dispatch(FetchAdminRides({ ...paginationData, ...query, ...dateRange, company: company ? company : "" }))
      : dispatch(FetchBusinessRides(user?.business?.id, { ...paginationData, ...query, ...dateRange }))
  }, [dispatch, paginationData, query, dateRange, company]);

  useEffect(() => {
    dispatch(FetchBusinesses({}));
  }, [dispatch]);

  const exportData = () => {
    dispatch(ExportRides({ ...paginationData, ...dateRange, })).then(async (res) => {
      download("data:text/csv;charset=utf-8," + res?.data, "rides.csv")
    })
  }

  return (
    <MainLayout title={"Rides"}>
      <RidesFilters
        handleSearch={handleSearch}
        setDateRange={setDateRange}
        getCompanyId={setCompany}
        exportData={exportData} />
      <RidesTable
        paginationData={paginationData}
        setPaginationData={setPaginationData}
      />
    </MainLayout>
  );
};

export default Rides;
