/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "../styles.scss";
import { dateFormat } from "../../../utils/constants";

const Vehicle = ({ data }) => {
  return (
    <div className="w-full">
      <div className="w-1/2">
        <div className="w-full bg-dark-500 px-6 py-3 rounded-md flex justify-between text-labels mt-3">
          <p className="text-dark-600">Status</p>
          <p className={`${data?.verificationStatus ? "text-[#23BE65]" : "text-[#E53838]"} font-semibold`}>{data?.verificationStatus ?? "No Status"}</p>
        </div>
        <div className="w-full bg-dark-500 px-6 py-3 rounded-md flex justify-between text-labels mt-3">
          <p className="text-dark-600">Vehicle brand</p>
          <p className="text-dark-700 font-semibold">{data?.brand ?? "---"}</p>
        </div>
        <div className="w-full bg-dark-500 px-6 py-3 rounded-md flex justify-between text-labels mt-3">
          <p className="text-dark-600">Vehicle type</p>
          <p className="text-dark-700 font-semibold">{data?.type ?? "---"}</p>
        </div>
        <div className="w-full bg-dark-500 px-6 py-3 rounded-md flex justify-between text-labels mt-3">
          <p className="text-dark-600">Vehicle model</p>
          <p className="text-dark-700 font-semibold">{data?.model ?? "---"}</p>
        </div>
        <div className="w-full bg-dark-500 px-6 py-3 rounded-md flex justify-between text-labels mt-3">
          <p className="text-dark-600">Vehicle year</p>
          <p className="text-dark-700 font-semibold">{data?.vehicleYear ?? "---"}</p>
        </div>
        <div className="w-full bg-dark-500 px-6 py-3 rounded-md flex justify-between text-labels mt-3">
          <p className="text-dark-600">Vehicle colour</p>
          <p className="text-dark-700 font-semibold">{data?.color ?? "---"}</p>
        </div>
        <div className="w-full bg-dark-500 px-6 py-3 rounded-md flex justify-between text-labels mt-3">
          <p className="text-dark-600">Vehicle registration no.</p>
          <p className="text-dark-700 font-semibold">{data?.registrationNumber ?? "---"}</p>
        </div>
        <div className="w-full bg-dark-500 px-6 py-3 rounded-md flex justify-between text-labels mt-3">
          <p className="text-dark-600">Plate number</p>
          <p className="text-dark-700 font-semibold">{data?.licensePlateNumber ?? "---"}</p>
        </div>
        <div className="w-full bg-dark-500 px-6 py-3 rounded-md flex justify-between text-labels mt-3">
          <p className="text-dark-600">VIN</p>
          <p className="text-dark-700 font-semibold">{data?.vin ?? "---"}</p>
        </div>
        <div className="w-full bg-dark-500 px-6 py-3 rounded-md flex justify-between text-labels mt-3">
          <p className="text-dark-600">Registration Date</p>
          <p className="text-dark-700 font-semibold">{data?.registrationDate ? dateFormat(data?.registrationDate) : "---"}</p>
        </div>
      </div>
    </div>
  );
};

export default Vehicle;
