/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MainLayout from "../../../layouts/MainLayout";
import "../styles.scss";
import { useDispatch } from "react-redux";
import Manage from "./Manage";
import { FetchPartnerUsers, FetchUsers } from "../../../redux/actions/users.action";
import AccountCreation from "./AccountCreation";
import { debounce } from "lodash";
import { FetchBusinesses } from "../../../redux/actions/businesses.action";
import { useNavigate } from "react-router-dom";

const Accounts = () => {
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem("dvla_user"));
    const dispatch = useDispatch();
    const [mode, setMode] = useState("");
    const [query, setQuery] = useState({ name: "" });
    const [partnerId, setPartnerId] = useState(0);
    const [paginationData, setPaginationData] = useState({
        page: 0,
        size: 10,
    });

    const handleUser = (id) => {
        navigate(`/accounts/details/${id}`);
    };

    const handleAccountCreation = (mode) => {
        setMode(mode);
    };

    useEffect(() => {
        user?.isSiteAdmin ? dispatch(FetchUsers({ ...paginationData, ...query, partnerId }))
            : dispatch(FetchPartnerUsers({ ...paginationData, ...query, partnerId }));
    }, [dispatch, paginationData, query, partnerId]);

    useEffect(() => {
        user?.isSiteAdmin && dispatch(FetchBusinesses({}));
    }, [dispatch]);

    const handleSearch = (value) => {
        performSearch(value);
    };

    const performSearch = debounce((value) => {
        if (value.length > 0) {
            setQuery({ name: value });
        } else {
            setQuery({ name: "" });
        }
    }, 700);

    const reset = () => {
        setMode("");
        setQuery({ name: "" })
        setPartnerId(0)
        setPaginationData({
            page: 0,
            size: 10,
        })
    }

    return (
        <MainLayout title={"Accounts"}>
            {mode === "ADD_USER" || mode === "ADD_PARTNER_USER" ? (
                <AccountCreation
                    mode={mode}
                    goBack={() => {
                        reset()
                    }}
                />
            ) : (
                <Manage
                    getUser={handleUser}
                    addUser={() => handleAccountCreation("ADD_USER")}
                    addPartner={() => handleAccountCreation("ADD_PARTNER_USER")}
                    handleSearch={handleSearch}
                    paginationData={paginationData}
                    setPaginationData={setPaginationData}
                    setPartnerId={setPartnerId}
                />
            )}
        </MainLayout>
    );
};

export default Accounts;
