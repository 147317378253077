/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "../styles.scss";
import { dateFormat } from "../../../utils/constants";

const UserProfile = ({ data }) => {
    return (
        <div className="w-full">
            <div className="w-1/2">
                <div className="w-full bg-dark-500 px-6 py-3 rounded-md flex justify-between text-labels mt-3">
                    <p className="text-dark-600">Status</p>
                    <p className="text-dark-700 font-semibold">{data?.status ?? "---"}</p>
                </div>
                <div className="w-full bg-dark-500 px-6 py-3 rounded-md flex justify-between text-labels mt-3">
                    <p className="text-dark-600">Date Created</p>
                    <p className="text-dark-700 font-semibold">{data?.createdAt ? dateFormat(data?.createdAt) : "---"}</p>
                </div>
                {data?.business && <>
                    <div className="w-full bg-dark-500 px-6 py-3 rounded-md flex justify-between text-labels mt-3">
                        <p className="text-dark-600">Business Name</p>
                        <p className="text-dark-700 font-semibold">{data?.business?.name ?? "---"}</p>
                    </div>
                    <div className="w-full bg-dark-500 px-6 py-3 rounded-md flex justify-between text-labels mt-3">
                        <p className="text-dark-600">Business Address</p>
                        <p className="text-dark-700 font-semibold">{data?.business?.address ?? "---"}</p>
                    </div>
                    <div className="w-full bg-dark-500 px-6 py-3 rounded-md flex justify-between text-labels mt-3">
                        <p className="text-dark-600">Business Email</p>
                        <p className="text-dark-700 font-semibold">{data?.business?.corporateEmail ?? "---"}</p>
                    </div>
                    <div className="w-full bg-dark-500 px-6 py-3 rounded-md flex justify-between text-labels mt-3">
                        <p className="text-dark-600">Business License</p>
                        <p className="text-dark-700 font-semibold">{data?.business?.licenseNumber ?? "---"}</p>
                    </div>
                    <div className="w-full bg-dark-500 px-6 py-3 rounded-md flex justify-between text-labels mt-3">
                        <p className="text-dark-600">Business Verification Status</p>
                        <p className="text-dark-700 font-semibold">{data?.business?.verificationStatus ?? "---"}</p>
                    </div>
                    <div className="w-full bg-dark-500 px-6 py-3 rounded-md flex justify-between text-labels mt-3">
                        <p className="text-dark-600">Business Registration Date</p>
                        <p className="text-dark-700 font-semibold">{data?.business?.registrationDate ? dateFormat(data?.business?.registrationDate) : "---"}</p>
                    </div>
                </>}
            </div>
        </div>
    );
};

export default UserProfile;
