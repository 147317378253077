/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Form, Input, notification } from "antd";
import AuthLayout from "../../../layouts/AuthLayout";
import AuthHeading from "../AuthHeading";
import DVLAPoweredBy from "../../../assets/imgs/dvla-powered-by.png";
import { Formik } from "formik";
import * as yup from "yup";
import FieldContainer from "../../../components/Inputs/FIeldContainer";
import Button from "../../../components/Buttons/Button";
import FloatLabel from "../../../components/Inputs/FloatLabel";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserForgotPassword } from "../../../redux/actions/auths.action";

const ForgotPassword = () => {
    const [form] = Form.useForm();
    const loader = useSelector((state) => state.auths.loading);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const values = { email: "", };

    const forgotPassword = (values) => {
        dispatch(UserForgotPassword(values?.email))
            .then(async (res) => {
                notification.success({
                    message: "Success",
                    description:
                        "A password reset OTP has been sent to your phone number.",
                });
                navigate(`/reset-password?email=${values?.email}`);
            })
            .catch((err) => {
                navigate(`/reset-password?email=${values?.email}`);
                notification.error({
                    message: "Error",
                    description: err.response.data.errorMsg
                        ? err.response.data.errorMsg
                        : "An error has ocurred, please try again later",
                });
            });
    };

    return (
        <AuthLayout>
            <AuthHeading backButtonName={"Back"} location={"PREV"} subHeading={"Please enter your email address"} />
            <div className="px-4 sm:px-24 md:px-48 lg:px-12 xl:px-24 xl:max-w-2xl w-full">
                <div className="text-left auth-shadow p-8 rounded-lg border-primary-500 gap-y-6 flex flex-col">
                    <Formik
                        initialValues={values}
                        onSubmit={forgotPassword}
                        enableReinitialize={true}
                        validationSchema={validator}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => (
                            <Form onFinish={handleSubmit} form={form}>
                                <div className={"mb-4"}>
                                    <FieldContainer iconPlacementCss={"iconCss"}>
                                        <FloatLabel
                                            label="Enter Email"
                                            value={values?.email || ""}
                                        >
                                            <Form.Item
                                                name={"email"}
                                                help={
                                                    touched.email && errors.email
                                                        ? errors.email
                                                        : ""
                                                }
                                                validateStatus={
                                                    touched.email && errors.email
                                                        ? "error"
                                                        : "success"
                                                }
                                            >
                                                <Input
                                                    type={"email"}
                                                    className={"form-field_input_2"}
                                                    value={values?.email || ""}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Form.Item>
                                        </FloatLabel>
                                    </FieldContainer>
                                </div>
                                <div className={"flex justify-center"}>
                                    <Button
                                        css={"w-full"}
                                        state={"PRIMARY"}
                                        text={"Submit"}
                                        type={"submit"}
                                        loading={loader}
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                <img
                    className={"h-4 mt-4"}
                    src={DVLAPoweredBy}
                    alt={"dvla-powered-by"}
                />
            </div>
        </AuthLayout>
    );
};

const validator = yup.object().shape({
    email: yup.string().required("Email is required"),
});

export default ForgotPassword;
