/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "../styles.scss";
import AllRideBatches from "./AllRideBatches";
import Upload from "../../../assets/imgs/upload.png";
import Button from "../../../components/Buttons/Button";

const Manage = ({
  uploadNewRideBatch,
  handleSearch,
  paginationData,
  setPaginationData,
  setPartnerId,
}) => {
  const uploadRideBatch = () => {
    uploadNewRideBatch();
  };

  return (
    <div className="w-full info-card bg-white px-8 py-6 flex flex-col gap-y-2">
      <div className=" border-b pb-4 border-b-neutral-100 border-b-[0.5px] w-full flex justify-between">
        <h3 className="text-secondary-300 font-semibold text-[18px]">
          Manage Bulk Uploads
        </h3>
        <div className="flex gap-x-2">
          <Button
            css={
              "py-[9px] rounded-md lg:text-[10px] text-[10px] xl:px-[16px] px-2"
            }
            state={"PRIMARY"}
            text={"Upload Batch"}
            type={"button"}
            icon={Upload}
            onClick={uploadRideBatch}
          />
        </div>
      </div>
      <div className="w-full mt-3">
        <AllRideBatches
          handleSearch={handleSearch}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          setPartnerId={setPartnerId}
        />
      </div>
    </div>
  );
};

export default Manage;
