/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import AuthLayout from "../../../layouts/AuthLayout";
import DVLAPoweredBy from "../../../assets/imgs/dvla-powered-by.png";
import "../styles.scss";
import AuthHeading from "../AuthHeading";
import { useNavigate } from "react-router-dom";

const Landing = () => {
  const navigate = useNavigate();
  return (
    <AuthLayout>
      <AuthHeading />
      <div className="px-4 sm:px-24 md:px-48 lg:px-12 xl:px-24 xl:max-w-2xl w-full">
        <div className="border shadow-xl p-8 rounded-lg border-primary-500 border-[1px] gap-y-6 flex flex-col">
          <div
            className="login-option px-3 py-4"
            onClick={() => navigate("/login")}
          >
            <div className="text-sm-regular text-left  text-gray-400 tracking-wide">
              Login
            </div>
          </div>
          <div
            className="login-option px-3 py-4"
            onClick={() => navigate("/login/partners")}
          >
            <div className="text-sm-regular text-left text-gray-400 tracking-wide">
              Partner login
            </div>
          </div>
        </div>
        <img
          className={"h-4 mt-4"}
          src={DVLAPoweredBy}
          alt={"dvla-powered-by"}
        />
      </div>
    </AuthLayout>
  );
};

export default Landing;
