/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import MainLayout from "../../../layouts/MainLayout";
import { FetchBusinesses } from "../../../redux/actions/businesses.action";
import "../styles.scss";
import Overview from "./Overview";
import Trends from "./Trends";
import { FetchAdminAllTimeRides, FetchBusinessRideStat, FetchBusinessTotalRides } from "../../../redux/actions/stats.action";
import { FetchRidesDailyTrends, FetchRidesMonthlyTrends } from "../../../redux/actions/rides.action";
import { thisYear } from "../../../utils/constants";

const Dashboard = () => {
  const user = JSON.parse(localStorage.getItem("dvla_user"));
  const [company, setCompany] = useState();
  const [dateRange, setDateRange] = useState(thisYear())
  const [globalTrendType, setGlobalTrendType] = useState(1);
  const dispatch = useDispatch();
  useEffect(() => {
    user?.isSiteAdmin && dispatch(FetchBusinesses({}));
  }, [dispatch]);

  useEffect(() => {
    if (user?.isSiteAdmin) {
      dispatch(FetchAdminAllTimeRides({ company: company ? company : "" }))
      // dispatch(FetchAdminAllTimeRides({ ...dateRange, company: company ? company : "" }))
    } else {
      dispatch(FetchBusinessRideStat(user?.business?.id, { ...dateRange }))
      dispatch(FetchBusinessTotalRides(user?.business?.id, { ...dateRange }))
    }
  }, [dispatch, company]);

  useEffect(() => {
    if (globalTrendType === 1) {
      dispatch(FetchRidesMonthlyTrends(company ? company : "", dateRange))
    } else {
      dispatch(FetchRidesDailyTrends(company ? company : "", dateRange))
    }
  }, [dispatch, dateRange, company, globalTrendType]);

  return (
    <MainLayout title={"Dashboard"}>
      <Overview getCompanyId={setCompany} />
      <Trends setDateRange={setDateRange} setGlobalTrendType={setGlobalTrendType} />
    </MainLayout>
  );
};

export default Dashboard;
