/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Input, notification } from "antd";
import AuthLayout from "../../../layouts/AuthLayout";
import AuthHeading from "../AuthHeading";
import DVLAPoweredBy from "../../../assets/imgs/dvla-powered-by.png";
import { Formik } from "formik";
import * as yup from "yup";
import Button from "../../../components/Buttons/Button";
import FloatLabel from "../../../components/Inputs/FloatLabel";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserForgotPassword, UserResetPassword } from "../../../redux/actions/auths.action";
import PasswordField from "../../../components/Inputs/PasswordField";
import OtpInput from "react18-input-otp";

const values = {
    newPassword: "",
    confirmPassword: "",
};

const ResetPassword = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get("email");
    const [form] = Form.useForm();
    const loader = useSelector((state) => state.auths.loading);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");

    const handleOtp = (enteredOtp) => {
        setOtp(enteredOtp);
        if (!enteredOtp) {
            setOtpError("OTP is required");
        } else if (enteredOtp?.length < 6) {
            setOtpError("Invalid OTP");
        } else {
            setOtpError("");
        }
    };

    const validateOTP = () => {
        if (!otp) {
            setOtpError("OTP is required");
        } else if (otp?.length < 6) {
            setOtpError("Invalid OTP");
        } else {
            setOtpError("");
        }
    };

    const resetPassword = (values) => {
        if (otpError) {
            return;
        }
        const data = { email, otp, password: values?.newPassword };
        dispatch(UserResetPassword(data))
            .then(async (res) => {
                notification.success({
                    message: "Success",
                    description:
                        "Your password has been reset successful. Please login to continue",
                });
                navigate("/", { replace: true });
            })
            .catch((err) => {
                notification.error({
                    message: "Error",
                    description: err.response.data.errorMsg
                        ? err.response.data.errorMsg
                        : "Invalid OTP. Please ensure you enter correct OTP",
                });
            });
    };

    const forgotPassword = () => {
        dispatch(UserForgotPassword(email))
            .then(async (res) => {
                notification.success({
                    message: "Success",
                    description:
                        "A password reset OTP has been sent to your phone number.",
                });
            })
            .catch((err) => {
                notification.error({
                    message: "Error",
                    description: err.response.data.errorMsg
                        ? err.response.data.errorMsg
                        : "An error has ocurred, please try again later",
                });
            });
    };

    useEffect(() => {
        if (!email) {
            navigate("/");
        }
    }, []);

    return (
        <AuthLayout>
            <AuthHeading backButtonName={"Back"} location={"PREV"} subHeading={"Please enter the OTP sent to your phone and new password"} />
            <div className="px-4 sm:px-24 md:px-48 lg:px-12 xl:px-24 xl:max-w-2xl w-full">
                <div className="text-left auth-shadow p-8 rounded-lg border-primary-500 gap-y-6 flex flex-col">
                    <Formik
                        initialValues={values}
                        onSubmit={resetPassword}
                        enableReinitialize={true}
                        validationSchema={validator}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => (
                            <Form onFinish={handleSubmit} form={form}>
                                <div className={"mb-6"}>
                                    <div
                                        className={
                                            "text-left font-normal text-md-regular mb-2 flex w-full justify-between"
                                        }
                                    >
                                        <div className="flex gap-x-2 items-center text-labels">
                                            <p>Did not receive OTP?</p>
                                            <p
                                                className={"text-primary-500 cursor-pointer"}
                                                onClick={forgotPassword}
                                            >
                                                Resend
                                            </p>
                                        </div>
                                    </div>
                                    <div className="otp-container">
                                        <OtpInput
                                            className={"otp-input"}
                                            value={otp}
                                            onChange={handleOtp}
                                            numInputs={6}
                                        />
                                        {otpError && (
                                            <div className={"opt-error text-labels"} style={{ color: "#ff4d4f" }}>
                                                {otpError}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className={"mb-4"}>
                                    <PasswordField iconPlacementCss={"iconCss"}>
                                        <FloatLabel label="New Password" value={values?.newPassword || ""}>
                                            <Form.Item
                                                name={"newPassword"}
                                                help={
                                                    touched.newPassword && errors.newPassword
                                                        ? errors.newPassword
                                                        : ""
                                                }
                                                validateStatus={
                                                    touched.newPassword && errors.newPassword
                                                        ? "error"
                                                        : "success"
                                                }
                                            >
                                                <Input
                                                    type={"password"}
                                                    className={"form-field_input_2"}
                                                    value={values?.newPassword || ""}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Form.Item>
                                        </FloatLabel>
                                    </PasswordField>
                                </div>
                                <div className={"mb-4"}>
                                    <PasswordField iconPlacementCss={"iconCss"}>
                                        <FloatLabel label="Confirm Password" value={values?.confirmPassword || ""}>
                                            <Form.Item
                                                name={"confirmPassword"}
                                                help={
                                                    touched.confirmPassword && errors.confirmPassword
                                                        ? errors.confirmPassword
                                                        : ""
                                                }
                                                validateStatus={
                                                    touched.confirmPassword && errors.confirmPassword
                                                        ? "error"
                                                        : "success"
                                                }
                                            >
                                                <Input
                                                    type={"password"}
                                                    className={"form-field_input_2"}
                                                    value={values?.confirmPassword || ""}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Form.Item>
                                        </FloatLabel>
                                    </PasswordField>
                                </div>
                                <div className={"flex justify-center"}>
                                    <Button
                                        css={"w-full"}
                                        state={"PRIMARY"}
                                        text={"Reset Password"}
                                        type={"submit"}
                                        loading={loader}
                                        onClick={validateOTP}
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                <img
                    className={"h-4 mt-4"}
                    src={DVLAPoweredBy}
                    alt={"dvla-powered-by"}
                />
            </div>
        </AuthLayout>
    );
};

const validator = yup.object().shape({
    newPassword: yup
        .string()
        .min(8, "Password should be a minimum of 8 characters")
        .required("New password is required"),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm password is required"),
});

export default ResetPassword;
