import { addBusiness, getBusiness, getBusinesses } from "../_api/index";

export const Types = {
  ALL_BUSINESSES_START: "ALL_BUSINESSES_START",
  ALL_BUSINESSES_SUCCESS: "ALL_BUSINESSES_SUCCESS",
  ALL_BUSINESSES_END: "ALL_BUSINESSES_END",

  BUSINESS_START: "BUSINESS_START",
  BUSINESS_SUCCESS: "BUSINESS_SUCCESS",
  BUSINESS_END: "BUSINESS_END",

  NEW_BUSINESS_START: "NEW_BUSINESS_START",
  NEW_BUSINESS_SUCCESS: "NEW_BUSINESS_SUCCESS",
  NEW_BUSINESS_END: "NEW_BUSINESS_END",
};

export const FetchBusinesses = (param) => (dispatch) => {
  dispatch({ type: Types.ALL_BUSINESSES_START });
  return new Promise((resolve, reject) => {
    getBusinesses(param)
      .then((res) => {
        dispatch({
          type: Types.ALL_BUSINESSES_SUCCESS,
          businesses: res?.data?.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.ALL_BUSINESSES_END });
        reject(err);
      });
  });
};

export const FetchBusiness = (id) => (dispatch) => {
  dispatch({ type: Types.BUSINESS_START });
  return new Promise((resolve, reject) => {
    getBusiness(id)
      .then((res) => {
        dispatch({
          type: Types.BUSINESS_SUCCESS,
          business: res?.data?.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.BUSINESS_END });
        reject(err);
      });
  });
};

export const CreateBusiness = (req) => (dispatch) => {
  dispatch({ type: Types.NEW_BUSINESS_START });
  return new Promise((resolve, reject) => {
    addBusiness(req)
      .then((res) => {
        dispatch({ type: Types.NEW_BUSINESS_SUCCESS });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.NEW_BUSINESS_END });
        reject(err);
      });
  });
};
