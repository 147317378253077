import {
  getAdminAllTimeRides,
  getBusinessRideAggregate,
  getBusinessRideStats,
  getBusinessSumTotalRides,
  getBusinessTotalRides,
  getForceAggregate,
} from "../_api/index";

export const Types = {
  ALL_ADMIN_ALL_TIME_RIDES_START: "ALL_ADMIN_ALL_TIME_RIDES_START",
  ALL_ADMIN_ALL_TIME_RIDES_SUCCESS: "ALL_ADMIN_ALL_TIME_RIDES_SUCCESS",
  ALL_ADMIN_ALL_TIME_RIDES_END: "ALL_ADMIN_ALL_TIME_RIDES_END",

  BUSINESS_SUM_TOTAL_RIDES_START: "BUSINESS_SUM_TOTAL_RIDES_START",
  BUSINESS_SUM_TOTAL_RIDES_SUCCESS: "BUSINESS_SUM_TOTAL_RIDES_SUCCESS",
  BUSINESS_SUM_TOTAL_RIDES_END: "BUSINESS_SUM_TOTAL_RIDES_END",

  FORCE_AGGREGATE_START: "FORCE_AGGREGATE_START",
  FORCE_AGGREGATE_SUCCESS: "FORCE_AGGREGATE_SUCCESS",
  FORCE_AGGREGATE_END: "FORCE_AGGREGATE_END",

  BUSINESS_TOTAL_RIDES_START: "BUSINESS_TOTAL_RIDES_START",
  BUSINESS_TOTAL_RIDES_SUCCESS: "BUSINESS_TOTAL_RIDES_SUCCESS",
  BUSINESS_TOTAL_RIDES_END: "BUSINESS_TOTAL_RIDES_END",

  BUSINESS_RIDE_STATS_START: "BUSINESS_RIDE_STATS_START",
  BUSINESS_RIDE_STATS_SUCCESS: "BUSINESS_RIDE_STATS_SUCCESS",
  BUSINESS_RIDE_STATS_END: "BUSINESS_RIDE_STATS_END",

  BUSINESS_RIDE_AGGREGATE_START: "BUSINESS_RIDE_AGGREGATE_START",
  BUSINESS_RIDE_AGGREGATE_SUCCESS: "BUSINESS_RIDE_AGGREGATE_SUCCESS",
  BUSINESS_RIDE_AGGREGATE_END: "BUSINESS_RIDE_AGGREGATE_END",
};

export const FetchAdminAllTimeRides = (param) => (dispatch) => {
  dispatch({ type: Types.ALL_ADMIN_ALL_TIME_RIDES_START });
  return new Promise((resolve, reject) => {
    getAdminAllTimeRides(param)
      .then((res) => {
        dispatch({
          type: Types.ALL_ADMIN_ALL_TIME_RIDES_SUCCESS,
          all_time_rides: res?.data?.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.ALL_ADMIN_ALL_TIME_RIDES_END });
        reject(err);
      });
  });
};

export const FetchBusinessSumTotalRides = (id, param) => (dispatch) => {
  dispatch({ type: Types.BUSINESS_SUM_TOTAL_RIDES_START });
  return new Promise((resolve, reject) => {
    getBusinessSumTotalRides(id, param)
      .then((res) => {
        dispatch({
          type: Types.BUSINESS_SUM_TOTAL_RIDES_SUCCESS,
          business_sum_total_rides: res?.data?.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.BUSINESS_SUM_TOTAL_RIDES_END });
        reject(err);
      });
  });
};

export const FetchForceAggregate = (param) => (dispatch) => {
  dispatch({ type: Types.FORCE_AGGREGATE_START });
  return new Promise((resolve, reject) => {
    getForceAggregate(param)
      .then((res) => {
        dispatch({
          type: Types.FORCE_AGGREGATE_SUCCESS,
          force_aggregate: res?.data?.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.FORCE_AGGREGATE_END });
        reject(err);
      });
  });
};

export const FetchBusinessTotalRides = (id, param) => (dispatch) => {
  dispatch({ type: Types.BUSINESS_TOTAL_RIDES_START });
  return new Promise((resolve, reject) => {
    getBusinessTotalRides(id, param)
      .then((res) => {
        dispatch({
          type: Types.BUSINESS_TOTAL_RIDES_SUCCESS,
          business_total_rides: res?.data?.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.BUSINESS_TOTAL_RIDES_END });
        reject(err);
      });
  });
};

export const FetchBusinessRideStat = (id, param) => (dispatch) => {
  dispatch({ type: Types.BUSINESS_RIDE_STATS_START });
  return new Promise((resolve, reject) => {
    getBusinessRideStats(id, param)
      .then((res) => {
        dispatch({
          type: Types.BUSINESS_RIDE_STATS_SUCCESS,
          business_ride_stats: res?.data?.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.BUSINESS_RIDE_STATS_END });
        reject(err);
      });
  });
};

export const FetchBusinessRideAggregate = (id, param) => (dispatch) => {
  dispatch({ type: Types.BUSINESS_RIDE_AGGREGATE_START });
  return new Promise((resolve, reject) => {
    getBusinessRideAggregate(id, param)
      .then((res) => {
        dispatch({
          type: Types.BUSINESS_RIDE_AGGREGATE_SUCCESS,
          business_ride_aggregate: res?.data?.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.BUSINESS_RIDE_AGGREGATE_END });
        reject(err);
      });
  });
};
