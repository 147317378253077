/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MainLayout from "../../../layouts/MainLayout";
import "../styles.scss";
import { ReactComponent as DVLACornerUpLeft } from "../../../assets/icons/corner-up-left.svg";
import { useParams } from "react-router-dom";
import { goBack } from "../../../utils/constants";
import VehicleDetails from "../Shared/VehicleDetails";

const DVLAVehicleDetails = () => {
    const params = useParams();
    const [id, setId] = useState(null);

    useEffect(() => {
        if (params?.id) {
            setId(params?.id);
        } else {
            goBack();
        }
    }, [params]);

    return (
        <MainLayout title={"Vehicle"}>
            <div>
                <div className="text-left mb-6 lg:mb-8 w-full">
                    <div
                        className="flex gap-x-2 bg-primary-100 text-primary-500 font-semibold rounded-lg p-2 text-labels hover:shadow-md cursor-pointer transition ease-in-out duration-300"
                        style={{ width: "fit-content" }}
                        onClick={goBack}
                    >
                        <DVLACornerUpLeft />
                        Back to search results
                    </div>
                </div>
                <VehicleDetails id={id} />
            </div>
        </MainLayout>
    );
};

export default DVLAVehicleDetails;
