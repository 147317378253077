import { Types } from "../actions/stats.action";

const INITIAL_STATE = {
  loading: false,
  all_time_rides: {},
  business_sum_total_rides: 0,
  force_aggregate: null,
  business_total_rides: 0,
  business_ride_stats: {},
  business_ride_aggregate: {},
};

export const statsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.ALL_ADMIN_ALL_TIME_RIDES_START:
    case Types.BUSINESS_SUM_TOTAL_RIDES_START:
    case Types.FORCE_AGGREGATE_START:
    case Types.BUSINESS_TOTAL_RIDES_START:
    case Types.BUSINESS_RIDE_STATS_START:
    case Types.BUSINESS_RIDE_AGGREGATE_START:
      return { ...state, loading: true };
    case Types.ALL_ADMIN_ALL_TIME_RIDES_SUCCESS:
      return {
        ...state,
        loading: false,
        all_time_rides: action.all_time_rides,
      };
    case Types.BUSINESS_SUM_TOTAL_RIDES_SUCCESS:
      return {
        ...state,
        loading: false,
        business_sum_total_rides: action.business_sum_total_rides,
      };
    case Types.FORCE_AGGREGATE_SUCCESS:
      return {
        ...state,
        loading: false,
        force_aggregate: action.force_aggregate,
      };
    case Types.BUSINESS_TOTAL_RIDES_SUCCESS:
      return {
        ...state,
        loading: false,
        business_total_rides: action.business_total_rides,
      };
    case Types.BUSINESS_RIDE_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        business_ride_stats: action.business_ride_stats,
      };
    case Types.BUSINESS_RIDE_AGGREGATE_SUCCESS:
      return {
        ...state,
        loading: false,
        business_ride_aggregate: action.business_ride_aggregate,
      };
    case Types.ALL_ADMIN_ALL_TIME_RIDES_END:
    case Types.BUSINESS_SUM_TOTAL_RIDES_END:
    case Types.FORCE_AGGREGATE_END:
    case Types.BUSINESS_TOTAL_RIDES_END:
    case Types.BUSINESS_RIDE_STATS_END:
    case Types.BUSINESS_RIDE_AGGREGATE_END:
      return { ...state, loading: false, message: action.message };
    default:
      return state;
  }
};
