import { Types } from "../actions/businesses.action";

const INITIAL_STATE = {
  loading: false,
  businesses: {},
  business: {},
};

export const businessesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.ALL_BUSINESSES_START:
    case Types.BUSINESS_START:
    case Types.NEW_BUSINESS_START:
      return { ...state, loading: true };
    case Types.ALL_BUSINESSES_SUCCESS:
      return {
        ...state,
        loading: false,
        businesses: action.businesses,
      };
    case Types.BUSINESS_SUCCESS:
      return {
        ...state,
        loading: false,
        business: action.business,
      };
    case Types.NEW_BUSINESS_SUCCESS:
      return { ...state, loading: false };
    case Types.ALL_BUSINESSES_END:
    case Types.BUSINESS_END:
    case Types.NEW_BUSINESS_END:
      return { ...state, loading: false, message: action.message };
    default:
      return state;
  }
};
