import React from "react";
import "./styles.scss";

const FieldContainer = ({ icon, children, iconPlacementCss }) => {
  return (
    <div className={`w-full relative ${icon ? "auth-input" : ""}`}>
      {icon ? (
        <img src={icon} alt={"Pre"} className={iconPlacementCss ?? ""} />
      ) : (
        <></>
      )}
      {children}
    </div>
  );
};

export default FieldContainer;
