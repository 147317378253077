import React from "react";
import { ReactComponent as DVLAClose } from "../../../assets/icons/dvla-close.svg";
import { ReactComponent as DVLARuby } from "../../../assets/icons/dvla-ruby.svg";
import Button from "../../../components/Buttons/Button";
import { useDispatch } from "react-redux";
import { DeleteFlag } from "../../../redux/actions/flags.action";
import { notification } from "antd";

const RemoveFlag = ({ id, handleClose, handleConfirm }) => {
    const dispatch = useDispatch();

    const deleteFlag = () => {
        dispatch(DeleteFlag(id)).then(res => {
            notification.success({
                message: "Success",
                description: "Flag Deleted Successfully!",
            });
            handleConfirm();
        }).catch(err => {
            notification.error({
                message: "Error",
                description: err.response.data.errorMsg
                    ? err.response.data.errorMsg
                    : "An error has ocurred, please try again later",
            });
        })
    }

    return (
        <div className="w-full">
            <div className="flex justify-between w-full items-center mb-4">
                <div className="flex items-center gap-x-3">
                    <DVLARuby />
                    <div className="flex gap-x-4 items-center cursor-pointer py-2 text-sm-regular font-semibold">
                        Delete Flag
                    </div>
                </div>
                <div
                    className="p-1 w-6 h-6 flex items-center justify-center rounded-full dvla-close cursor-pointer"
                    onClick={handleClose}
                >
                    <DVLAClose />
                </div>
            </div>
            <div className="text-secondary-100 text-labels mb-6">
                Are you sure you want to delete this flag? <br /> <br />
            </div>
            <div className="flex gap-x-4 mt-4">
                <Button
                    css={
                        "py-[9px] rounded-md lg:text-[11px] text-[10px] xl:px-[10px] px-2"
                    }
                    state={"PRIMARY"}
                    text={"Confirm"}
                    type={"button"}
                    onClick={deleteFlag}
                />

                <Button
                    css={
                        "py-[9px] rounded-md lg:text-[11px] text-[10px] xl:px-[10px] px-2"
                    }
                    text={"Cancel"}
                    type={"button"}
                    bordered={true}
                    onClick={handleClose}
                />
            </div>
        </div>
    );
};

export default RemoveFlag;
