import React from "react";
import { ReactComponent as DVLAClose } from "../../../assets/icons/dvla-close.svg";
import { formatDate, numberWithCommas } from "../../../utils/constants";
// import GoogleMapReact from 'google-map-react';

const RideDetails = ({ handleClose, ride }) => {
  // const defaultProps = {
  //   center: {
  //     lat: 10.99835602,
  //     lng: 77.01502627
  //   },
  //   zoom: 11
  // };
  return (
    <div className="w-full">
      <div className="flex justify-end">
        <div
          className="p-1 flex items-center justify-center rounded-full dvla-close cursor-pointer"
          onClick={handleClose}
        >
          <DVLAClose />
        </div>
      </div>
      <div className="flex gap-x-4 items-center cursor-pointer py-2 text-sm-regular font-semibold">
        Ride Details
      </div>
      <div className="text-labels border rounded-lg border-[1px] border-gray-300">
        <div className="flex justify-between px-3 border-b border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Company</div>
          <div className="text-secondary-100 font-medium">{ride?.business?.name}</div>
        </div>
        <div className="flex justify-between px-3 border-b border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Driver’s name</div>
          <div className="text-secondary-100 font-medium">{ride?.driver?.firstName ?? "---"} {ride?.driver?.lastName ?? "---"}</div>
        </div>
        <div className="flex justify-between px-3 border-b border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">License No.</div>
          <div className="text-secondary-100 font-medium">{ride?.business?.licenseNumber}</div>
        </div>
        <div className="flex justify-between px-3 border-b border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Vehicle Reg.</div>
          <div className="text-secondary-100 font-medium">{ride?.vehicle?.licensePlateNumber ?? "---"}</div>
        </div>
        <div className="flex justify-between px-3 border-b border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Start point</div>
          <div className="text-secondary-100 font-medium">{ride?.departureCoordinates?.longitude}, {ride?.departureCoordinates?.latitude}</div>
        </div>
        <div className="flex justify-between px-3 border-b border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">End point</div>
          <div className="text-secondary-100 font-medium">{ride?.arrivalCoordinates?.longitude}, {ride?.arrivalCoordinates?.latitude}</div>
        </div>
        <div className="flex justify-between px-3 border-b border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Trip Start Time</div>
          <div className="text-secondary-100 font-medium">{formatDate(ride?.rideStartTimestamp)}</div>
        </div>
        <div className="flex justify-between px-3 border-b border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Trip End Time</div>
          <div className="text-secondary-100 font-medium">{formatDate(ride?.rideEndTimestamp)}</div>
        </div>
        <div className="flex justify-between px-3 border-b border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Passenger’s name</div>
          <div className="text-secondary-100 font-medium">{ride?.passgName ?? "---"}</div>
        </div>
        <div className="flex justify-between px-3 border-b border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Passenger’s number</div>
          <div className="text-secondary-100 font-medium">{ride?.passPhoneNo ?? "---"}</div>
        </div>
        <div className="flex justify-between px-3 py-4">
          <div className="text-neutral-200">Ride fare (NGN)</div>
          <div className="text-secondary-100 font-medium">{ride?.rideFare ? numberWithCommas(ride?.rideFare) : ride?.fare ? numberWithCommas(ride?.fare) : "---"}</div>
        </div>
        {/* <div style={{ height: '100vh', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: "" }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          >
            <MapMarker />
          </GoogleMapReact>
        </div> */}
      </div>
    </div>
  );
};

// const MapMarker = () => {
//   return <img alt="marker" src="../../../assets/imgs/dvla-map-marker.png" style={{ position: "absolute", top: "50%", left: "50%", width: "20px", height: "20px", userSelect: "none", cursor: "pointer" }} />
// }

export default RideDetails;
