import React from "react";
import { ReactComponent as DVLAClose } from "../../../assets/icons/dvla-close.svg";
import { ReactComponent as DVLARuby } from "../../../assets/icons/dvla-ruby.svg";
import Button from "../../../components/Buttons/Button";

const InvoiceStatusUpdate = ({ handleClose, updateType, handleConfirm }) => {
  return (
    <div className="w-full">
      <div className="flex justify-between w-full items-center mb-4">
        <div className="flex items-center gap-x-3">
          <DVLARuby />
          <div className="flex gap-x-4 items-center cursor-pointer py-2 text-sm-regular font-semibold">
            Mark as {updateType?.toLowerCase()}
          </div>
        </div>
        <div
          className="p-1 w-6 h-6 flex items-center justify-center rounded-full dvla-close cursor-pointer"
          onClick={handleClose}
        >
          <DVLAClose />
        </div>
      </div>
      <div className="text-secondary-100 text-labels mb-6">
        Are you sure you want to mark this invoice as{" "}
        {updateType?.toLowerCase()}?
        {updateType === "PAID" && <p>
          <br />
          Confirm that the amount stated has been received before marking as paid.
        </p>}
      </div>
      <div className="flex gap-x-4 mt-4">
        <Button
          css={
            "py-[9px] rounded-md lg:text-[11px] text-[10px] xl:px-[10px] px-2"
          }
          state={"PRIMARY"}
          text={"Confirm"}
          type={"button"}
          onClick={handleConfirm}
        />

        <Button
          css={
            "py-[9px] rounded-md lg:text-[11px] text-[10px] xl:px-[10px] px-2"
          }
          text={"Cancel"}
          type={"button"}
          bordered={true}
          onClick={handleClose}
        />
      </div>
    </div>
  );
};

export default InvoiceStatusUpdate;
