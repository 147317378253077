/* eslint-disable react-hooks/exhaustive-deps */
import { Collapse } from "antd";
import { ReactComponent as DVLAChevronDown } from "../../../assets/icons/dvla-chevron-down.svg";
import { ReactComponent as DVLAChevronUp } from "../../../assets/icons/dvla-chevron-up.svg";
import React from "react";
import "../styles.scss";
import { useSelector } from "react-redux";
import { numberWithCommas } from "../../../utils/constants";

const { Panel } = Collapse;

const RidesByCompany = () => {
  const all_time_rides = useSelector((state) => state?.stats?.all_time_rides);

  return (
    <div className="w-full info-card bg-white px-5 py-6 flex flex-col gap-y-2 rides-by-company">
      <p className="text-sm-regular font-semibold">Rides by Company</p>
      <div>
        <Collapse
          accordion
          bordered={false}
          expandIconPosition={"end"}
          expandIcon={({ isActive }) =>
            isActive ? <DVLAChevronUp /> : <DVLAChevronDown />
          }
        >
          {all_time_rides?.length && all_time_rides?.map((rbc, index) => (
            <Panel
              header={
                <div className="flex w-full justify-between text-labels">
                  <div className="flex gap-x-2 items-center">
                    <div className="h-3 w-3 flex items-center justify-center bg-dark-200 p-2 rounded-full text-x-small">
                      {index + 1}
                    </div>
                    <div className="font-semibold">{rbc?.business?.name}</div>
                  </div>
                  <div className="text-x-small font-semibold text-neutral-200">
                    {rbc?.total} rides
                  </div>
                </div>
              }
              key={index + "index"}
            >
              <div className="rounded-xl bg-dark-200 flex items-center justify-between px-4 py-2 w-full">
                <div className="w-1/2">
                  <p className="text-x-small font-semibold mb-1">Rides</p>
                  <p className="text-labels">{numberWithCommas(rbc?.total)}</p>
                </div>
                <div className="w-1/2">
                  <div className="w-full flex flex-col items-end">
                    <p className="text-x-small font-semibold mb-1">Revenue</p>
                    <p className="text-labels">NGN {numberWithCommas(rbc?.totalFare)}</p>
                  </div>
                </div>
              </div>
            </Panel>
          ))}
        </Collapse>
      </div>
    </div>
  );
};

export default RidesByCompany;
