import { Types } from "../actions/flags.action";

const INITIAL_STATE = {
  loading: false,
  flags: {},
  flag: {},
};

export const flagsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.FLAGS_START:
    case Types.NEW_FLAG_START:
    case Types.UPDATE_FLAG_START:
    case Types.FLAG_START:
    case Types.DELETE_FLAG_START:
      return { ...state, loading: true };
    case Types.FLAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        flags: action.flags,
      };
    case Types.FLAG_SUCCESS:
      return {
        ...state,
        loading: false,
        flag: action.flag,
      };
    case Types.NEW_FLAG_SUCCESS:
    case Types.UPDATE_FLAG_SUCCESS:
    case Types.DELETE_FLAG_SUCCESS:
      return { ...state, loading: false };
    case Types.FLAGS_END:
    case Types.NEW_FLAG_END:
    case Types.UPDATE_FLAG_END:
    case Types.FLAG_END:
    case Types.DELETE_FLAG_END:
      return { ...state, loading: false, message: action.message };
    default:
      return state;
  }
};
