/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DVLAPictureAvatar from "../../../assets/icons/dvla-picture-avatar.svg";
import DVLAEdit from "../../../assets/imgs/dvla-edit.png";
import { PROFILE_TABS } from "../../../utils/misc/database";
import "../styles.scss";
import Documents from "./Documents";
import Flags from "./Flags";
import Profile from "./Profile";
import { useDispatch, useSelector } from "react-redux";
import { FetchDriver } from "../../../redux/actions/drivers.action";
import { Modal } from "antd";
import UpdateDriver from "./UpdateDriver";
import { FetchFlags } from "../../../redux/actions/flags.action";

const DriverDetails = ({ id }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(1);
  const [openInfo, setOpenInfo] = useState(false);
  const { driver, loading } = useSelector((state) => state?.drivers);
  const { flags } = useSelector((state) => state?.flags);
  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 5,
  });
  const user = JSON.parse(localStorage.getItem("dvla_user"));

  useEffect(() => {
    if (id) {
      dispatch(FetchDriver(id));
    }
  }, [id])

  const handleMore = () => {
    handleOpenInfoChange(true);
  };

  const handleOpenInfoChange = (newOpen) => {
    setOpenInfo(newOpen);
  };

  const handleUpdate = () => {
    handleOpenInfoChange(false)
    dispatch(FetchDriver(id));
    dispatch(FetchFlags({ driverId: id, ...paginationData }))
  };

  useEffect(() => {
    dispatch(FetchFlags({ driverId: id, ...paginationData }))
  }, [dispatch, paginationData])

  const infoContent = (
    <UpdateDriver
      handleClose={() => handleOpenInfoChange(false)}
      handleUpdate={handleUpdate}
      driver={driver}
      id={id}
    />
  );

  return (
    <div className="w-full info-card bg-white px-8 py-6 flex flex-col gap-y-2">
      <div className="flex gap-x-4 items-center">
        <img src={DVLAPictureAvatar} alt={"dvla-user"} className={"h-16 w-16"} />
        <div className="flex flex-col gap-y-3">
          <div className="flex items-center gap-x-4">
            <h3 className="font-bold text-[18px]">{driver?.name ?? "---"}</h3>
            {user?.isSiteAdmin && <img onClick={handleMore} src={DVLAEdit} alt={"dvla-user"} className={"h-5 w-5 cursor-pointer"} />}
          </div>
          <p className="text-labels text-dark-400">{driver?.email ?? "---"}</p>
        </div>
      </div>
      <div className="mt-6 border-b border-b-neutral-100 border-b-[0.5px] w-full flex text-labels gap-x-10">
        {PROFILE_TABS?.map((dt) => (
          <div
            key={dt?.name}
            className={
              dt?.id === activeTab
                ? "border-b border-b-secondary-300 border-b-2 pb-3 cursor-pointer font-medium transition ease-in-out duration-300"
                : "cursor-pointer text-neutral-300 transition ease-in-out duration-300"
            }
            onClick={() => setActiveTab(dt?.id)}
          >
            {dt?.name}
          </div>
        ))}
      </div>
      <div className="w-full mt-3">
        {activeTab === 1 ? (
          <Profile data={driver} />
        ) : activeTab === 2 ? (
          <Documents data={driver} refresh={handleUpdate} loading={loading} />
        ) : (
          <Flags
            data={flags}
            refresh={handleUpdate}
            loading={loading}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
            driverId={id}
          />
        )}
      </div>
      <Modal
        open={openInfo}
        closable={false}
        footer={null}
        onCancel={() => handleOpenInfoChange(false)}
        className={"invoice-modal"}
        width={550}
      >
        {infoContent}
      </Modal>
    </div>
  );
};

export default DriverDetails;
