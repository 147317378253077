import React from "react";
import { ReactComponent as DVLAClose } from "../../../assets/icons/dvla-close.svg";
import { dateFormat } from "../../../utils/constants";

const Flag = ({ handleClose, flag }) => {
  return (
    <div className="w-full">
      <div className="flex justify-end">
        <div
          className="p-1 flex items-center justify-center rounded-full dvla-close cursor-pointer"
          onClick={handleClose}
        >
          <DVLAClose />
        </div>
      </div>
      <div className="flex gap-x-4 items-center cursor-pointer py-2 text-sm-regular font-semibold">
        Flag Details
      </div>
      <div className="text-labels border rounded-lg border-[1px] border-gray-300">
        <div className="flex justify-between px-3 border-b border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Flag Name</div>
          <div className="text-secondary-100 font-medium">{flag?.flagType}</div>
        </div>
        <div className="flex justify-between px-3 border-b border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Comment</div>
          <div className="text-secondary-100 font-medium">{flag?.comment ?? "---"}</div>
        </div>
        <div className="flex justify-between px-3 border-b border-b-[1px] border-b-gray-300 py-4">
          <div className="text-neutral-200">Date Added</div>
          <div className="text-secondary-100 font-medium">{flag?.dateAdded ? dateFormat(flag?.dateAdded) : "---"}</div>
        </div>
      </div>
    </div>
  );
};

export default Flag;
