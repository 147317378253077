import {
  createInvoice,
  getInvoice,
  getInvoiceStats,
  getInvoices,
  updateInvoiceStatus,
} from "../_api/index";

export const Types = {
  ALL_INVOICES_START: "ALL_INVOICES_START",
  ALL_INVOICES_SUCCESS: "ALL_INVOICES_SUCCESS",
  ALL_INVOICES_END: "ALL_INVOICES_END",

  INVOICE_START: "INVOICE_START",
  INVOICE_SUCCESS: "INVOICE_SUCCESS",
  INVOICE_END: "INVOICE_END",

  INVOICE_STATS_START: "INVOICE_STATS_START",
  INVOICE_STATS_SUCCESS: "INVOICE_STATS_SUCCESS",
  INVOICE_STATS_END: "INVOICE_STATS_END",

  CREATE_INVOICE_START: "CREATE_INVOICE_START",
  CREATE_INVOICE_SUCCESS: "CREATE_INVOICE_SUCCESS",
  CREATE_INVOICE_END: "CREATE_INVOICE_END",

  UPDATE_INVOICE_STATUS_START: "UPDATE_INVOICE_STATUS_START",
  UPDATE_INVOICE_STATUS_SUCCESS: "UPDATE_INVOICE_STATUS_SUCCESS",
  UPDATE_INVOICE_STATUS_END: "UPDATE_INVOICE_STATUS_END",
};

export const FetchInvoices = (param) => (dispatch) => {
  dispatch({ type: Types.ALL_INVOICES_START });
  return new Promise((resolve, reject) => {
    getInvoices(param)
      .then((res) => {
        dispatch({
          type: Types.ALL_INVOICES_SUCCESS,
          invoices: res?.data?.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.ALL_INVOICES_END });
        reject(err);
      });
  });
};

export const FetchInvoice = (id) => (dispatch) => {
  dispatch({ type: Types.INVOICE_START });
  return new Promise((resolve, reject) => {
    getInvoice(id)
      .then((res) => {
        dispatch({
          type: Types.INVOICE_SUCCESS,
          invoice: res?.data?.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.INVOICE_END });
        reject(err);
      });
  });
};

export const FetchInvoiceStats = (param) => (dispatch) => {
  dispatch({ type: Types.INVOICE_STATS_START });
  return new Promise((resolve, reject) => {
    getInvoiceStats(param)
      .then((res) => {
        dispatch({
          type: Types.INVOICE_STATS_SUCCESS,
          invoice_stats: res?.data?.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.INVOICE_STATS_END });
        reject(err);
      });
  });
};

export const CreateInvoice = (req) => (dispatch) => {
  dispatch({ type: Types.CREATE_INVOICE_START });
  return new Promise((resolve, reject) => {
    createInvoice(req)
      .then((res) => {
        dispatch({
          type: Types.CREATE_INVOICE_SUCCESS,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.CREATE_INVOICE_END });
        reject(err);
      });
  });
};

export const UpdateInvoiceStatus = (id, status) => (dispatch) => {
  dispatch({ type: Types.UPDATE_INVOICE_STATUS_START });
  return new Promise((resolve, reject) => {
    updateInvoiceStatus(id, status)
      .then((res) => {
        dispatch({
          type: Types.UPDATE_INVOICE_STATUS_SUCCESS,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.UPDATE_INVOICE_STATUS_END });
        reject(err);
      });
  });
};
