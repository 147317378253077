import {
  getCurrentUser,
  getPartnerUsers,
  getUsers,
  invitePartnerUser,
  inviteUser,
} from "../_api/index";

export const Types = {
  FETCH_CURRENT_USER_START: "FETCH_CURRENT_USER_START",
  FETCH_CURRENT_USER_END: "FETCH_CURRENT_USER_END",
  FETCH_CURRENT_USER_SUCCESS: "FETCH_CURRENT_USER_SUCCESS",

  FETCH_USERS_START: "FETCH_USERS_START",
  FETCH_USERS_END: "FETCH_USERS_END",
  FETCH_USERS_SUCCESS: "FETCH_USERS_SUCCESS",

  INVITE_USER_START: "INVITE_USER_START",
  INVITE_USER_SUCCESS: "INVITE_USER_SUCCESS",
  INVITE_USER_END: "INVITE_USER_END",

  INVITE_PARTNER_USER_START: "INVITE_PARTNER_USER_START",
  INVITE_PARTNER_USER_SUCCESS: "INVITE_PARTNER_USER_SUCCESS",
  INVITE_PARTNER_USER_END: "INVITE_PARTNER_USER_END",
};

export const FetchCurrentUser = () => (dispatch) => {
  dispatch({ type: Types.FETCH_CURRENT_USER_START });
  return new Promise((resolve, reject) => {
    getCurrentUser()
      .then((res) => {
        dispatch({
          type: Types.FETCH_CURRENT_USER_SUCCESS,
          currentUser: res.data.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.FETCH_CURRENT_USER_END });
        reject(err);
      });
  });
};

export const FetchUsers = (filter) => (dispatch) => {
  dispatch({ type: Types.FETCH_USERS_START });
  return new Promise((resolve, reject) => {
    getUsers(filter)
      .then((res) => {
        dispatch({
          type: Types.FETCH_USERS_SUCCESS,
          users: res.data.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.FETCH_USERS_END });
        reject(err);
      });
  });
};

export const FetchPartnerUsers = (filter) => (dispatch) => {
  dispatch({ type: Types.FETCH_USERS_START });
  return new Promise((resolve, reject) => {
    getPartnerUsers(filter)
      .then((res) => {
        dispatch({
          type: Types.FETCH_USERS_SUCCESS,
          users: res.data.object,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.FETCH_USERS_END });
        reject(err);
      });
  });
};

export const InviteUser = (req) => (dispatch) => {
  dispatch({ type: Types.INVITE_USER_START });
  return new Promise((resolve, reject) => {
    inviteUser(req)
      .then((res) => {
        dispatch({ type: Types.INVITE_USER_SUCCESS });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.INVITE_USER_END });
        reject(err);
      });
  });
};

export const InvitePartnerUser = (req, id) => (dispatch) => {
  dispatch({ type: Types.INVITE_PARTNER_USER_START });
  return new Promise((resolve, reject) => {
    invitePartnerUser(req, id)
      .then((res) => {
        dispatch({ type: Types.INVITE_PARTNER_USER_SUCCESS });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: Types.INVITE_PARTNER_USER_END });
        reject(err);
      });
  });
};
