export const CHART_TYPES = [
  {
    id: 1,
    name: "Line Chart",
  },
  {
    id: 2,
    name: "Bar Chart",
  },
];

export const RIDE_COUNTS = [
  {
    id: 1,
    name: "Total Rides",
  },
  {
    id: 2,
    name: "Total Fare",
  },
  {
    id: 3,
    name: "Total Levy",
  },
];

export const TREND_TYPES = [
  {
    id: 1,
    name: "Monthly Trends",
  },
  {
    id: 2,
    name: "Daily Trends",
  },
];

export const DATE_FILTERS = [
  {
    id: 1,
    name: "All Time",
  },
  {
    id: 2,
    name: "Today",
  },
  {
    id: 3,
    name: "Yesterday",
  },
  {
    id: 4,
    name: "This Week",
  },
  {
    id: 5,
    name: "Last Week",
  },
  {
    id: 6,
    name: "This Month",
  },
  {
    id: 7,
    name: "Last Month",
  },
  {
    id: 8,
    name: "This Year",
  },
  {
    id: 9,
    name: "Last Year",
  },
  {
    id: 10,
    name: "Custom Range",
  },
];

export const DASHBOARD_DATE_FILTERS = [
  {
    id: 1,
    name: "Today",
  },
  {
    id: 2,
    name: "Yesterday",
  },
  {
    id: 3,
    name: "This Week",
  },
  {
    id: 4,
    name: "Last Week",
  },
  {
    id: 5,
    name: "This Month",
  },
  {
    id: 6,
    name: "Last Month",
  },
  {
    id: 7,
    name: "This Year",
  },
  {
    id: 8,
    name: "Last Year",
  },
  {
    id: 9,
    name: "Custom Range",
  },
];
