/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

const NameBadge = ({ name, customClass = "" }) => {
    const processName = () => {
        let newName = "";
        if (name) {
            const names = name?.split(" ");
            names?.forEach(n => {
                newName = newName + n[0];
            })
            return newName;
        }
        return "";
    }
    return (
        <div className={`bg-gray-400 h-8 w-8 rounded-full flex items-center justify-center font-semibold text-white ${customClass}`}>{processName()}</div>
    );
};

export default NameBadge;
